import React, { useRef, useEffect } from "react";
import { Animated, StyleSheet } from "react-native";
import { MAIN_COLORS } from "../Utility/Colors";

const Skeleton = ({ width, height, borderRadius, style }) => {
  const opacity = useRef(new Animated.Value(0.3)).current;

  useEffect(() => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(opacity, {
          toValue: 1,
          duration: 1000,
          useNativeDriver: true,
        }),
        Animated.timing(opacity, {
          toValue: 0.3,
          duration: 1000,
          useNativeDriver: true,
        }),
      ])
    ).start();
  }, [opacity]);

  return (
    <Animated.View
      style={[styles.skeleton, style, { width, height, borderRadius, opacity }]}
    />
  );
};

export default Skeleton;

const styles = StyleSheet.create({
  skeleton: {
    width: 200,
    height: 20,
    backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
    marginVertical: 8,
    borderRadius: 4,
  },
});
