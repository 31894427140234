import ReactPlayer from "react-player";
import Toast from "react-native-toast-message";
import { createElement } from "react-native-web";
import React, { useEffect, useRef, useState } from "react";
import { View, Text, Image, Linking, TouchableOpacity } from "react-native";
import { useSelector } from "react-redux";

//Functions
import { styles2 as styles } from "./style";
import { reports, assesments } from "../../Zoho/Tasks";
import {
  getProperty,
  fetchVideoLoom,
  getContactByEmail,
  updatePresentationViewed,
} from "../../Clickup/api";

import defaultImage from "../../assets/home/Darren&Mike.png";

//Components
import About from "./components/About";
import Details from "./components/Details";
import Associated from "./components/Associated";
import Comparative from "./components/Comparative";
import ViewGallery from "../../components/ViewGallery";
import LOADER from "../../Utility/ComponentCard/Loader";

const PropertyDetail = ({ property, video, booking, assignee, taskId }) => {
  const { teamTIAData } = useSelector((state) => state.TIA);
  const [playing, setPlaying] = useState(false);
  const [properties, setProperties] = useState({});
  const [videoLoomUrl, setVideoLoomUrl] = useState("");
  const [showLoomVideo, setShowLoomVideo] = useState(false);
  const [buyingTeamPhoto, setBuyingTeamPhoto] = useState(null);
  const [showComparative, setShowComparative] = useState(false);
  const [disableBtnClose, setDisableBtnClose] = useState(true);
  const bottomRef = useRef(null);

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const assigneeUsername = assignee?.username;
  const isInTeamTIA = teamTIAData?.some(
    (member) =>
      member.name === assigneeUsername && member.type === "Property Analyst"
  );

  const handleRefresh = () => {
    getProperty(property.id).then((result) => {
      if (result) {
        const object = result.custom_fields.reduce(
          (obj, item) => Object.assign(obj, { [item.name]: item.value }),
          {}
        );
        result["custom_fields"] = null;

        fetchVideoLoom(
          object["PROPERTY PRESO LOOM VIDEO "]?.match(/\/share\/([^?]+)/)[1]
        ).then((result) => setVideoLoomUrl(result.url));
        setProperties({ ...result, ...object });

        if (
          object["COMPARITIVE PROPERTY 1 (PRICE)"] &&
          object["COMPARITIVE PROPERTY 1 (IMAGE)"] &&
          object["COMPARITIVE PROPERTY 2 (PRICE)"] &&
          object["COMPARITIVE PROPERTY 2 (IMAGE)"] &&
          object["COMPARITIVE PROPERTY 3 (PRICE)"] &&
          object["COMPARITIVE PROPERTY 3 (IMAGE)"]
        ) {
          setShowComparative(true);
        } else {
          setShowComparative(false);
        }
      }
    });
  };

  const openUrl = (url) => {
    let reg =
      /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;

    if (!!reg.test(url)) {
      Linking.openURL(url);
    }
  };

  const web = (link) => {
    const Video = (props) => {
      const attrs = {
        src: props.source,
        width: "100%",
        height: "100%",
        alignitems: "center",
        frameBorder: 0,
        webkitallowfullscreen: "true",
        mozallowfullscreen: "true",
        allowFullScreen: true,
        controls: "controls",
      };

      return createElement("iframe", attrs);
    };

    return <Video source={link} width={100} />;
  };

  const handleCloseModalVideo = () => {
    if (disableBtnClose) {
      Toast.show({
        type: "info",
        text1: "Please finish watching the video before closing",
      });
    } else {
      updatePresentationViewed(taskId, true).then((result) => {
        if (!result.error) {
          setShowLoomVideo(false);
          setPlaying(false);
        }
      });
    }
  };

  useEffect(() => {
    handleRefresh();
    if (assignee) {
      const isAssigneeValid = teamTIAData?.some(
        (member) =>
          member.name === assignee.username &&
          member.type === "Property Analyst"
      );

      if (!isAssigneeValid) {
        setBuyingTeamPhoto(null);
      } else {
        getContactByEmail(assignee.email).then((result) => {
          const tasks = result?.tasks;
          if (tasks.length) {
            const custom_fields = tasks[0].custom_fields;
            const contactPhoto = custom_fields.find(
              (field) => field.name === "Buying Team Photo"
            )?.value[0].url;
            setBuyingTeamPhoto(contactPhoto);
          }
        });
      }
    }

    if (taskId) {
      getProperty(taskId).then((result) => {
        const _showLoomVideo =
          !result.custom_fields.find(
            (field) => field.name === "Presentation Viewed"
          )?.value === "true";
        setShowLoomVideo(_showLoomVideo);
      });
    }
  }, []);

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Property Selection</Text>
      <Details
        property={properties}
        openUrl={openUrl}
        onBookCallClick={scrollToBottom}
      />

      <View style={styles.sectionRow}>
        <View style={{ flex: 1, display: "flex", gap: 16 }}>
          <Text style={styles.sectionTitle}>Property Presentation Video</Text>
          <View style={{ flex: 1, borderRadius: 10, overflow: "hidden" }}>
            {web(
              properties["PROPERTY PRESO LOOM VIDEO "]?.replace(
                "share",
                "embed"
              )
            )}
          </View>
        </View>
        <View style={{ flex: 1, display: "flex", gap: 16 }}>
          <Text style={styles.sectionTitle}>Comparative Properties</Text>
          {showComparative && (
            <Comparative property={properties} openUrl={openUrl} />
          )}
        </View>
      </View>

      <View style={styles.sectionRow}>
        <View style={{ flex: 1, display: "flex", gap: 16 }}>
          <Text style={styles.sectionTitle}>About this Property</Text>
          <Associated properties={reports(properties)} />
        </View>
      </View>

      <View style={{ flex: 1, display: "flex", gap: 16 }}>
        <Text style={styles.sectionTitle}>About this Property</Text>
        <About property={assesments(properties)} />
      </View>

      <View ref={bottomRef} style={styles.footer}>
        <View
          style={{
            alignSelf: "center",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: 444,
          }}
        >
          <Text style={styles.footerTitle}>
            {`Discuss with ${isInTeamTIA ? `${assigneeUsername} ` : "Mike"}`}
          </Text>
          <Text style={styles.footerDescription}>
            Once you’ve reviewed the info above, find a time to chat with your
            property analyst.
          </Text>
          <TouchableOpacity
            style={styles.btnBooking}
            onPress={() => openUrl(booking)}
          >
            <Text style={styles.textBtnBooking}>Book a Quick Call</Text>
          </TouchableOpacity>
        </View>
        <Image
          style={styles.footerImage}
          source={buyingTeamPhoto ? buyingTeamPhoto : defaultImage}
        />
        <Image
          style={styles.calendarImage}
          source={require("../../assets/calendarBooking.png")}
        />
      </View>

      {!Object.keys(properties).length ? <LOADER /> : null}

      <ViewGallery
        open={!!videoLoomUrl && showLoomVideo}
        onClose={handleCloseModalVideo}
      >
        {!playing && (
          <TouchableOpacity
            style={styles.btnPlay}
            onPress={() => setPlaying(true)}
          >
            <Image
              resizeMode="contain"
              style={{ height: 84, width: 84 }}
              source={require("../../Image/button/play/play.png")}
            />
          </TouchableOpacity>
        )}
        <ReactPlayer
          url={videoLoomUrl}
          controls
          width="100%"
          height="100%"
          playing={playing}
          onPlay={() => setPlaying(true)}
          onPause={() => setPlaying(false)}
          onEnded={() => setDisableBtnClose(false)}
          style={{ alignSelf: "center", zIndex: 1 }}
          config={{ file: { attributes: { style: styles.modalVideo } } }}
        />
      </ViewGallery>
    </View>
  );
};

export default PropertyDetail;
