import {
  View,
  Text,
  Image,
  TextInput,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import React, { memo, useCallback, useEffect, useState } from "react";

//Fucntion
import { MAIN_COLOR, MAIN_COLORS } from "../../../Utility/Colors";
import { fetchPropertyData } from "../../../Utility/common";
import { MAIN_FONT } from "../../../Utility/Fonts/font";

//Component
import MainLayout from "../../../Layout/Main";
import Skeleton from "../../../components/Skeleton";
import OpportunityItem from "../../../components/OpportunityItem";
import CalendarCard from "../../../Utility/ComponentCard/CalendarCard";
import NotificationCard from "../../../Utility/ComponentCard/NotificationCard";
import {
  searchOpportunities,
  getBrandingByLeadSourceValue,
} from "../../../Clickup/api";

const HomeComp = ({
  leads,
  setDeal,
  markedDates,
  setStateScreen,
  getOpportunities,
  goToProjectDetails,
}) => {
  const { TIA, PORTFOLIO_ROADMAP } = useSelector((state) => state);

  const client = TIA.client;
  const opportunities = TIA.opportunities;
  const notifications = TIA.notifications;

  let currentPageRef
  const showCalendar = PORTFOLIO_ROADMAP.showCalendar;

  const [noti, setNoti] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [valueSearch, setValueSearch] = useState("");
  const [focusSearch, setFocusSearch] = useState(false);
  const [searchResult, setSearchResult] = useState({
    leads: [],
    checklists: [],
    opportunities: [],
    clickUpStatusList: [],
    searchFinal: "",
  });

  const itemOfPage = 6;
  const listOpportunity = searchResult.opportunities.length
    ? searchResult.opportunities
    : client?.custom_fields?.find((feild) => feild?.name === "Opportunities")
        ?.value;

  const handleSeeNotification = useCallback((day) => {
    window.history.replaceState({}, "", `?day=${day}`);
    setStateScreen("Notifications");
  }, []);

  const handleSearch = async () => {
    if (!valueSearch) return;
    try {
      setLoading(true);

      const opportunityValues = client.custom_fields.find(
        (feild) => feild.name === "Opportunities"
      ).value;

      const response = await searchOpportunities(
        opportunityValues,
        valueSearch
      );
      if (response.status === 200 && response.data.length) {
        const checklists = [];
        const opportunities = [];
        const clickUpStatusList = [];

        for (const lead of response.data) {
          checklists.push(...lead.checklists);
          clickUpStatusList.push({
            taskId: lead?.id,
            status: lead?.status?.status ?? "",
          });

          const opportunity = lead?.custom_fields?.reduce(
            (obj, item) => Object.assign(obj, { [item.name]: item.value }),
            {}
          );

          let purchase_connected_address;
          let purchase_connected_image;
          if (opportunity?.["Proposed Property"]?.length > 0) {
            const purchaseIdConnected =
              opportunity["Proposed Property"]?.[0]?.id;
            const purchase = await fetchPropertyData(purchaseIdConnected);

            const address = purchase?.["PROPERTY STREET ADDRESS"];
            const suburb = purchase?.["PROPERTY SUBURB"];
            const state = purchase?.["PROPERTY STATE"];
            const postcode = purchase?.["PROPERTY POSTCODE"];
            purchase_connected_address = `${address}, ${suburb} ${state} ${postcode}`;

            purchase_connected_image = purchase?.["Image IDs"]
              ? `https://insights.proptrack.com/imagery/350x265/${
                  purchase["Image IDs"].split(",")[0].split(".")[0]
                }/image.jpg`
              : purchase?.["PROPERTY IMAGE URL"];
          }

          let co_labeling_logo;
          if (!!opportunity?.["Lead Source"]) {
            const response = await getBrandingByLeadSourceValue(
              opportunity["Lead Source"]
            );
            co_labeling_logo = response?.tasks?.[0]?.custom_fields?.find(
              (one) => one?.name === "Co-labeling logo"
            )?.value?.[0].url;
          }

          lead["custom_fields"] = null;
          opportunities.push({
            ...lead,
            ...opportunity,
            co_labeling_logo,
            purchase_connected_image,
            purchase_connected_address,
          });
        }
        setSearchResult({
          opportunities,
          checklists,
          clickUpStatusList,
          searchFinal: valueSearch,
          leads: opportunities.slice(0, 6),
        });
      } else {
        setSearchResult({
          opportunities: [],
          checklists: [],
          clickUpStatusList: [],
          searchFinal: valueSearch,
          leads: [],
        });
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleClearSearch = () => {
    setValueSearch("");
    setSearchResult({
      leads: [],
      checklists: [],
      opportunities: [],
      clickUpStatusList: [],
      searchFinal: "",
    });
  };

  const handleChangePage = async ({ selected }) => {
    try {
      const opportunityIndex = selected * itemOfPage;
      setCurrentPage(selected + 1);

      if (searchResult.opportunities.length) {
        const opportunities = searchResult.opportunities.slice(
          opportunityIndex,
          opportunityIndex + itemOfPage
        );
        setSearchResult((prev) => ({ ...prev, leads: opportunities }));
      } else {
        if (!listOpportunity) return;

        const opportunityIds = listOpportunity.slice(
          opportunityIndex,
          opportunityIndex + itemOfPage
        );

        setLoading(true);
        await getOpportunities(opportunityIds);
        setLoading(false);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (notifications) {
      const noti = notifications.map((noti) => {
        const opportunityFound = opportunities?.find(
          (opportunity) => opportunity.id === noti.taskId
        );

        const image = opportunityFound?.purchase_connected_image;
        const isConnected = opportunityFound?.["Proposed Property"]?.[0];

        return { ...noti, image: image, isConnected };
      });
      setNoti(noti);
    }
  }, [leads, notifications]);

  useEffect(() => {
    if (!valueSearch.trim()) {
      handleClearSearch();
      return;
    }

    const delayDebounceFn = setTimeout(() => {
      handleSearch();
    }, 2000);

    return () => clearTimeout(delayDebounceFn);
  }, [valueSearch]);

  const dataList = searchResult.searchFinal ? searchResult.leads : leads;

  if(opportunities.length && dataList.length){
    currentPageRef = opportunities.findIndex((item) => item?.id === dataList?.[0]?.id) / itemOfPage
  }
  
  return (
    <MainLayout
      childrenHeader={
        <View style={stylesHome.header}>
          <View style={{ gap: 4 }}>
            <Text style={stylesHome.headerSubTitle}>Welcome back!</Text>
            <Text style={stylesHome.headerTitle}>
              {client?.first_name} {client?.last_name}
            </Text>
          </View>
          <View
            style={[
              stylesHome.boxSearch,
              {
                borderColor: focusSearch
                  ? MAIN_COLORS.PRIMARY_COLOR
                  : "#34393D",
              },
            ]}>
            <TextInput
              editable={!loading}
              onFocus={() => setFocusSearch(true)}
              onBlur={() => setFocusSearch(false)}
              value={valueSearch}
              placeholder="Search"
              onChangeText={(e) => setValueSearch(e)}
              onSubmitEditing={handleSearch}
              style={[stylesHome.headerSubTitle, stylesHome.boxInput]}
            />
            {valueSearch ? (
              <TouchableOpacity onPress={handleClearSearch}>
                <Image
                  style={{ width: 20, height: 20 }}
                  source={require("../../../assets/portfolio-roadmap/clear-icon.svg")}
                />
              </TouchableOpacity>
            ) : null}
            <TouchableOpacity onPress={handleSearch}>
              <Image
                style={{ width: 24, height: 24, marginLeft: 12 }}
                source={require("../../../assets/portfolio-roadmap/search-normal-white.svg")}
              />
            </TouchableOpacity>
          </View>
        </View>
      }
      childrenMenu={
        <View style={[stylesHome.contentMenu, { gap: showCalendar ? 22 : 0 }]}>
          <CalendarCard
            markedDates={markedDates}
            onPressDay={handleSeeNotification}
          />
          <View style={stylesHome.menuSection}>
            <Text style={stylesHome.menuSectionTitle}>Notification</Text>
            {noti.slice(0, 4).map((noti, index) => {
              return (
                <NotificationCard
                  key={index}
                  item={noti}
                  goToProjectDetails={goToProjectDetails}
                />
              );
            })}
          </View>
        </View>
      }>
      <View style={stylesHome.container}>
        <View style={stylesHome.contentTop}>
          {dataList?.length ? (
            <View style={stylesHome.section}>
              <Text style={stylesHome.titleContentCenterView}>
                {searchResult.searchFinal ? (
                  <Text>
                    {searchResult.opportunities.length
                      ? searchResult.opportunities.length
                      : 0}{" "}
                    results for "
                    <Text
                      style={[
                        stylesHome.titleContentCenterView,
                        { color: MAIN_COLORS.PRIMARY_COLOR },
                      ]}>
                      {searchResult.searchFinal}
                    </Text>
                    "
                  </Text>
                ) : (
                  "All Purchase"
                )}
              </Text>

              <View style={{ flex: 1 }}>
                <View
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    gap: "12px",
                  }}>
                  {loading ? (
                    <>
                      <Skeleton width={380} height={300} borderRadius={10} />
                      <Skeleton width={380} height={300} borderRadius={10} />
                      <Skeleton width={380} height={300} borderRadius={10} />
                      <Skeleton width={380} height={300} borderRadius={10} />
                      <Skeleton width={380} height={300} borderRadius={10} />
                      <Skeleton width={380} height={300} borderRadius={10} />
                    </>
                  ) : (
                    dataList.map((item, index) => (
                      <OpportunityItem
                        item={item}
                        key={index}
                        index={index}
                        style={{ aspectRatio: 380 / 300 }}
                        onPress={() => {
                          setDeal(item);
                          setStateScreen("ProjectDetails");
                        }}
                      />
                    ))
                  )}
                </View>
              </View>
              {listOpportunity?.length > itemOfPage ? (
                <View
                  style={{ justifyContent: "flex-end", flexDirection: "row" }}>
                  <ReactPaginate
                    breakLabel={
                      <Image
                        style={{ width: 20, height: 20, opacity: 0.6 }}
                        source={require("../../../assets/home/options.svg")}
                      />
                    }
                    nextLabel={
                      <View style={stylesHome.paginateItem}>
                        <Image
                          style={{
                            width: 20,
                            height: 20,
                            transform: [{ rotate: "180deg" }],
                          }}
                          source={require("../../../assets/home/arrow.png")}
                        />
                      </View>
                    }
                    pageLabelBuilder={(page) => (
                      <View style={stylesHome.paginateItem}>
                        <Text
                          style={[
                            stylesHome.paginateText,
                            {
                              color:
                                page === currentPage
                                  ? MAIN_COLORS.PRIMARY_COLOR
                                  : "#7D888F",
                            },
                          ]}>
                          {page}
                        </Text>
                      </View>
                    )}
                    previousLabel={
                      <View style={stylesHome.paginateItem}>
                        <Image
                          style={{ width: 20, height: 20 }}
                          source={require("../../../assets/home/arrow.png")}
                        />
                      </View>
                    }
                    pageRangeDisplayed={5}
                    initialPage={currentPageRef ?? currentPage - 1}
                    activeClassName="active"
                    className="react-paginate"
                    renderOnZeroPageCount={null}
                    onPageChange={handleChangePage}
                    pageCount={Math.ceil(listOpportunity.length / 6)}
                  />
                </View>
              ) : null}
            </View>
          ) : searchResult.searchFinal && !searchResult.opportunities.length ? (
            <Text style={stylesHome.titleContentCenterView}>
              0 results for "
              <Text
                style={[
                  stylesHome.titleContentCenterView,
                  { color: MAIN_COLORS.PRIMARY_COLOR },
                ]}>
                {searchResult.searchFinal}
              </Text>
              "
            </Text>
          ) : (
            <View style={stylesHome.contentViewEmpty}>
              <Image
                style={{ height: 150, width: 150 }}
                resizeMode="contain"
                source={require("../../../assets/home/content.svg")}
              />
              <Text style={stylesHome.viewEmptyTitle}>Welcome to TIA</Text>
              <Text style={stylesHome.viewEmptySubTitle}>
                You’re on your way to better property investment.
              </Text>
            </View>
          )}
        </View>
      </View>
    </MainLayout>
  );
};

export const stylesHome = StyleSheet.create({
  section: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  container: {
    flex: 1,
    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR_BLACK,
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  headerSubTitle: {
    fontSize: 14,
    lineHeight: 21,
    fontWeight: 400,
    color: MAIN_COLOR.G600,
    fontFamily: MAIN_FONT.semi,
  },
  headerTitle: {
    fontSize: 16,
    fontWeight: 600,
    lightHeight: 24,
    color: MAIN_COLOR.BACKGROUND_COLOR,
    fontFamily: MAIN_FONT.semi,
  },
  contentTop: {
    flex: 1,
    paddingHorizontal: "2.5%",
    paddingVertical: "2.5%",
    borderRadius: 0,
  },

  rowStartWrap: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    gap: 12,
    flexWrap: "wrap",
  },

  titleContentCenterView: {
    marginBottom: 16,
    fontSize: 20,
    fontWeight: 600,
    lightHeight: 30,
    color: MAIN_COLOR.BACKGROUND_COLOR,
    fontFamily: MAIN_FONT.semi,
  },

  contentViewEmpty: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },

  viewEmptyTitle: {
    fontSize: 20,
    fontWeight: 600,
    lightHeight: 30,
    textAlign: "center",
    color: MAIN_COLOR.G600,
    fontFamily: MAIN_FONT.regular,
    textAlign: "center",
    marginTop: 20,
  },

  viewEmptySubTitle: {
    fontSize: 16,
    fontWeight: 400,
    lightHeight: 24,
    color: MAIN_COLOR.G600,
    fontFamily: MAIN_FONT.regular,
    textAlign: "center",
    marginTop: 10,
  },

  contentMenu: {
    display: "flex",
    width: "100%",
    gap: 22,
  },

  menuSection: {
    display: "flex",
    gap: 12,
  },

  menuSectionTitle: {
    fontSize: 16,
    lineHeight: 24,
    fontWeight: 600,
    color: MAIN_COLOR.BACKGROUND_COLOR,
  },

  boxSearch: {
    width: 380,
    height: 40,
    borderWidth: 1,
    borderRadius: 6,
    borderColor: "#34393D",
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 16,
    overflow: "hidden",
  },

  boxInput: {
    color: MAIN_COLORS.TEXT_LIGHT,
    fontWeight: 400,
    outlineStyle: "none",
    paddingVertical: 8,
    flex: 1,
    fontFamily: MAIN_FONT.regular,
  },

  paginateItem: {
    width: 38,
    height: 38,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: "#34393D",
    alignItems: "center",
    justifyContent: "center",
  },

  paginateText: {
    fontWeight: 400,
    fontSize: 14,
    lightHeight: 21,
    color: "#7D888F",
  },
});

export default memo(HomeComp);
