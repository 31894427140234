import React from "react";
import { View, Text } from "react-native";
import { formatDollar } from "../../common";

import "chart.js/auto";
import "chartjs-adapter-moment";
import { Line } from "react-chartjs-2";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { MAIN_FONT } from "../../Fonts/font";

ChartJS.register(ArcElement, Tooltip, Legend);

function getOrCreateTooltip(chart) {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");
  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.borderRadius = "5px";
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.transform = "translate(-50%, 0)";
    tooltipEl.style.transition = "all .1s ease";
    tooltipEl.style.padding = "8px";
    const table = document.createElement("table");
    table.style.margin = "0px";

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }
  tooltipEl.style.background =
    chart?.tooltip?.labelColors?.[0]?.backgroundColor ??
    chart?.tooltip?.labelColors?.[0]?.borderColor ??
    MAIN_COLOR.yellow;

  return tooltipEl;
}

function externalTooltipHandlerNew(context, padding, radius, backgroudColor) {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((b) => b.lines);

    const tableHead = document.createElement("thead");

    titleLines.forEach((title) => {
      const tr = document.createElement("tr");
      tr.style.borderWidth = 0;

      const th = document.createElement("th");
      th.style.borderWidth = 0;
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement("tbody");
    bodyLines.forEach((body, i) => {
      const colors = tooltip.labelColors[i];

      const tr = document.createElement("tr");
      tr.style.backgroundColor = "inherit";
      tr.style.borderWidth = 0;

      const td = document.createElement("td");
      td.style.borderWidth = 0;
      td.style.fontWeight = 500;
      td.style.fontSize = "10px";
      td.style.lineHeight = "12px";
      td.style.color = "#000";
      td.style.fontFamily = MAIN_FONT.regular;
      td.lineHeight = 1;

      const text = document.createTextNode(body);

      td.appendChild(text);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector("table");

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  const toolTipWidth = tooltip.width;
  const xAxisWidth = chart.scales.x.width;
  let tooltipLeft = 0;

  const isFirstPoint = tooltip.caretX - toolTipWidth / 2 < 0;
  const isLastPoint = tooltip.caretX + toolTipWidth / 2 > xAxisWidth;

  if (isFirstPoint) {
    tooltipLeft = 18;
  } else if (isLastPoint) {
    tooltipLeft = -18;
  }

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + tooltipLeft + "px";
  tooltipEl.style.top =
    positionY -
    25 -
    (padding?.top ?? 0) -
    (padding?.bottom ?? 0) +
    tooltip.caretY +
    "px";
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.backgroundColor = backgroudColor || "#D3B100";
  tooltipEl.style.display = "flex";
  tooltipEl.style.alignItem = "center";
  tooltipEl.style.justifyContent = "center";
  tooltipEl.style.width = "max-content";
  tooltipEl.style.maxHeight = 16 + "px";
  tooltipEl.style.paddingLeft = padding.left + "px";
  tooltipEl.style.paddingRight = padding.right + "px";
  tooltipEl.style.paddingTop = padding.top + "px";
  tooltipEl.style.paddingBottom = padding.bottom + "px";

  if (radius) tooltipEl.style.borderRadius = radius;
}

function formatDollarUnit(value) {
  const number = Number(value || 0);

  if (number >= 1000000 || number <= -1000000)
    return `$${(number / 1000000).toFixed(2)}M`;
  else if (number >= 1000 || number <= -1000)
    return `$${(number / 1000).toFixed(0)}K`;
  else
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    })
      .format(number)
      .replace(/(\.|,)00$/g, "");
}

const ChartMarketMedian = () => {
  const params = new URLSearchParams(window.location.search);
  const encodedArray = params.get("data");

  let result;

  if (encodedArray) {
    result = JSON.parse(decodeURIComponent(encodedArray));
  }

  let minY = Math.min(...result?.dataset?.map((item) => item?.y ?? 0));
  let maxY = Math.max(...result?.dataset?.map((item) => item?.y ?? 0));

  maxY = maxY * 1.2;
  minY = minY < 0 ? minY * 1.2 : minY * 0.8;

  let suffix = result?.ySuffix || result?.unitCard;

  const data = {
    datasets: [
      {
        data: result.dataset ?? [],
        backgroundColor: (context) => {
          const bgColor = [
            "rgba(255, 255, 255, 0.3)",
            "rgba(255, 255, 255, 0.1)",
            "rgba(255, 255, 255, 0.05)",
          ];
          if (!context.chart.chartArea) return;
          const {
            ctx,
            chartArea: { top, bottom },
          } = context.chart;
          const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
          gradientBg.addColorStop(0.1, bgColor[0]);
          gradientBg.addColorStop(0.5, bgColor[1]);
          gradientBg.addColorStop(1, bgColor[2]);
          return gradientBg;
        },
        borderColor: "#fff",
        pointBackgroundColor: "#D3B100",
        pointBorderColor: "#ffffff",
        pointRadius: () => 0,
        pointHoverRadius: 5,
        lineTension: 0.7,
        borderWidth: 1,
        fill: true,
        hoverBorderWidth: 1,
        spanGaps: true,
        tension: 0.1,
        clip: false,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    hover: { mode: "index", intersect: false },
    tooltips: { mode: "index", intersect: false },
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: false,
        position: "nearest",
        cornerRadius: 20,
        callbacks: {
          label: (value) => {
            const prefix = result?.yPrefix || "";
            if (suffix) {
              return `${prefix}${value.raw.y.toFixed(result.unit)}${suffix}`;
            }
            return formatDollar(value.raw.y.toFixed(result.unit ?? 0));
          },
        },
        external: (context) =>
          externalTooltipHandlerNew(
            context,
            { left: 8, right: 8, top: 0, bottom: 0 },
            "46px"
          ),
      },
    },
    scales: {
      x: {
        ticks: {
          align: "center",
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
          color: "#7D888F",
          font: { size: 10 },
          callback: (label, index) =>
            !result?.dataset?.[index]?.x
              ? ""
              : result?.dataset?.[index]?.x.split(" ")[0],
        },
        grid: { display: false },
        border: { color: "#3F4448" },
      },
      y: {
        border: { dash: [3, 3], color: "#3F4448" },
        grid: {
          drawTicks: false,
          color: function (context) {
            if (context.index === 3) return "rgba(0,0,0,0)";
            return "#3F4448";
          },
        },
        beginAtZero: false,
        min: minY,
        max: maxY,
        ticks: {
          display: true,
          padding: 6,
          font: { size: 10 },
          color: "#7D888F",
          maxTicksLimit: 5,
          stepSize: (maxY - minY) / 5,
          callback: function (item, index) {
            if (result?.subTitle === "Rental yield") suffix = "% Yield";
            else if (result?.subTitle === "Vendor discount") suffix = "%";

            if (suffix) return `${item.toFixed(0)}${suffix}`;
            return formatDollarUnit(item);
          },
        },
      },
      y1: {
        stacked: true,
        position: "right",
        grid: { display: false },
        ticks: { display: false },
        border: { color: "#3F4448" },
      },
    },
  };

  const plugins = [
    {
      afterDraw: function (chart) {
        if (chart?.tooltip?._active && chart?.tooltip?._active?.length) {
          const activePoint = chart.tooltip._active[0];
          const ctx = chart.ctx;
          const x = activePoint.element.x;
          const topY = chart.scales.y.chart.tooltip.caretY + 10;
          const bottomY = chart.scales.y.bottom;

          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x, topY);
          ctx.lineTo(x, bottomY);
          ctx.lineWidth = 1;
          ctx.strokeStyle = "#D3B100";
          ctx.stroke();
          ctx.restore();
        }
      },
    },
  ];

  return (
    <View
      style={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "#000",
        paddingTop: 0,
        paddingHorizontal: 7,
        paddingBottom: 12,
      }}
    >
      <Line data={data} options={options} plugins={plugins} />
    </View>
  );
};

export default ChartMarketMedian;
