import * as Yup from "yup";
import React, { memo, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { showMessage } from "react-native-flash-message";
import {
  SafeAreaView,
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Keyboard,
  TextInput,
  useWindowDimensions,
} from "react-native";

import { MAIN_COLORS } from "../../Utility/Colors";
import { confirmPassword } from "../../Clickup/api";
import { MAIN_FONT } from "../../Utility/Fonts/font";
import stylesCommonComponents from "../../Utility/ComponentStyle/style";

import AuthBanner from "../Auth/AuthBanner";

const SignUp = ({ navigation }) => {
  const { width } = useWindowDimensions();

  const isPC = width > 1024;
  const search = window.location.search;
  const params = new URLSearchParams(search);

  const [isFocusPassword, setIsFocusPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const schemaForm = Yup.object().shape({
    email: Yup.string().required("Email can't be empty"),
    password: Yup.string().required("Password can't be empty"),
  });

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: { email: "", password: "" },
    resolver: yupResolver(schemaForm),
  });

  const handleSignUp = async (values) => {
    try {
      setLoading(true);
      const response = await confirmPassword(values);
      if (response.status === 200) {
        showMessage({
          message: "Register successfully",
          type: "info",
          color: "#FFF",
          backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
          duration: 4000,
        });

        setTimeout(() => {
          window.location.href = `${window.location.origin}/client_portal`;
          navigation.navigate("LoginScreen");
        }, 1000);
      } else if (response.status === 404) {
        showMessage({
          message:
            response?.exception?.detail ||
            "Email already exists on clickup. You cannot create additional accounts.",
          type: "info",
          color: "#FFF",
          backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
          duration: 4000,
        });
      } else {
        showMessage({
          message:
            response?.exception?.detail ||
            "An error occurred, please try again.",
          type: "info",
          color: "#FFF",
          backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
          duration: 4000,
        });
      }
    } catch (error) {
      showMessage({
        message: "An error occurred, please try again.",
        type: "info",
        color: "#FFF",
        backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
        duration: 4000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleErrorValidate = (error) => {
    if (error) {
      showMessage({
        message: error.password.message,
        type: "info",
        color: "#FFF",
        backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
        duration: 4000,
      });
    }
  };

  useEffect(() => {
    const email = params.get("email");
    if (email) {
      setValue("email", email);
    }

    return () => {
      window.location.href = `${window.location.origin}/client_portal`;
    };
  }, []);

  return (
    <SafeAreaView
      style={styles.container}
      keyboardShouldPersistTaps={true}
      behavior={Platform.OS === "ios" ? "padding" : "padding"}
      keyboardVerticalOffset={Platform.OS === "ios" ? -200 : 900}>
      {isPC ? <AuthBanner /> : null}
      <View
        onPress={Keyboard.dismiss}
        accessible={false}
        style={{
          width: isPC ? "50%" : "100%",
          backgroundColor: MAIN_COLORS.BACKGROUND_BLACK,
        }}>
        <View style={styles.boxLogoTIA} />
        <View style={[styles.scrollViewContentView, styles.sectionContent]}>
          <View>
            <Text style={styles.sectionTitle}>Hey! Welcome back</Text>
            <Text style={styles.sectionSubTitle}>
              Please sign-up to continue!
            </Text>
          </View>
          <View
            style={[styles.sectionCenterView, { width: isPC ? "60%" : "85%" }]}>
            <View style={styles.sectionForm}>
              <Text style={styles.labelForm}>Email</Text>
              <Controller
                control={control}
                name="email"
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    keyboardType="web-search"
                    autoCorrect={false}
                    outline="none"
                    editable={false}
                    style={styles.inputForm}
                    placeholderTextColor="#ADB9C7"
                    value={value}
                    underlineColorAndroid="transparent"
                    onChangeText={onChange}
                    placeholder="Enter Your Email"
                    returnKeyType={"next"}
                    onSubmitEditing={() => {}}
                    blurOnSubmit={false}
                    disableFullscreenUI
                    autoCapitalize="none"
                  />
                )}
              />
            </View>
            <View style={styles.sectionForm}>
              <Text style={styles.labelForm}>Password</Text>
              <Controller
                control={control}
                name="password"
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    keyboardType="web-search"
                    autoCorrect={false}
                    outline="none"
                    style={[
                      styles.inputForm,
                      {
                        borderColor: isFocusPassword ? "#fff" : "#34393D",
                      },
                    ]}
                    placeholderTextColor="#ADB9C7"
                    value={value}
                    underlineColorAndroid="transparent"
                    onChangeText={onChange}
                    placeholder="Enter Your Password"
                    returnKeyType={"next"}
                    onSubmitEditing={handleSubmit(
                      handleSignUp,
                      handleErrorValidate
                    )}
                    blurOnSubmit={false}
                    autoCapitalize="none"
                    onFocus={() => setIsFocusPassword(true)}
                    onBlur={() => setIsFocusPassword(false)}
                  />
                )}
              />
            </View>
          </View>
          <TouchableOpacity
            disabled={loading}
            style={[
              stylesCommonComponents.button,
              styles.btnSubmit,
              {
                backgroundColor: loading
                  ? MAIN_COLORS.TEXT_GRAY
                  : MAIN_COLORS.PRIMARY_COLOR,
              },
            ]}
            onPress={handleSubmit(handleSignUp, handleErrorValidate)}
          >
            <Text style={styles.textButton}>Register</Text>
          </TouchableOpacity>
        </View>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#161B1E",
    flexDirection: "row",
  },
  scrollViewContentView: {
    flex: 1,
    width: "100%",
  },

  sectionCenterView: {
    alignSelf: "center",
    width: "60%",
    justifyContent: "flex-start",
  },

  sectionContent: {
    alignSelf: "center",
    display: "flex",
    width: "100%",
    flex: 1,
    gap: 56,
  },

  sectionTitle: {
    fontWeight: 600,
    fontSize: 28,
    lineHeight: 42,
    color: MAIN_COLORS.TEXT_LIGHT,
    textAlign: "center",
    marginBottom: 8,
    fontFamily: MAIN_FONT.semi,
  },
  sectionSubTitle: {
    fontSize: 14,
    lineHeight: 21,
    textAlign: "center",
    fontWeight: 400,
    color: MAIN_COLORS.G600,
    fontFamily: MAIN_FONT.regular,
  },

  sectionForm: {
    flex: 1,
    marginBottom: 22,
  },

  labelForm: {
    lineHeight: 21,
    fontSize: 14,
    fontFamily: MAIN_FONT.regular,
    color: "#272D37",
    marginBottom: 5,
    fontWeight: 600,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.semi,
  },

  inputForm: {
    height: 45,
    width: "100%",
    borderWidth: 1,
    outlineWidth: 0,
    borderRadius: 6,
    borderColor: "#34393D",
    paddingHorizontal: 10,
    paddingVertical: 12,
    color: MAIN_COLORS.TEXT_LIGHT,
    backgroundColor: MAIN_COLORS.BACKGROUND_BLACK,
    fontFamily: MAIN_FONT.regular,
  },

  btnSubmit: {
    height: 46,
    width: "60%",
    alignSelf: "center",
    paddingHorizontal: 24,
    paddingVertical: 10,
    marginTop: 0,
    marginHorizontal: "auto",
  },

  textButton: {
    fontFamily: MAIN_FONT.semi,
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 21,
    color: "#19232B",
    fontFamily: MAIN_FONT.semi,
  },

  boxLogoTIA: {
    display: "flex",
    justifyContent: "center",
    height: 165,
    paddingLeft: 54,
  },
});

export default memo(SignUp);
