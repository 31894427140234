import {
  View,
  Text,
  Image,
  Linking,
  Keyboard,
  Platform,
  TextInput,
  Dimensions,
  BackHandler,
  SafeAreaView,
  TouchableOpacity,
} from "react-native";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showMessage } from "react-native-flash-message";
import { RFValue } from "react-native-responsive-fontsize";
import { MaterialIndicator } from "react-native-indicators";
import AsyncStorage from "@react-native-async-storage/async-storage";

//Function
import styles from "../Auth/style";
import { MAIN_COLORS } from "../../Utility/Colors";
import {
  addToken,
  confirmForgotAccount,
  registerAccount,
  signIn,
} from "../../Clickup/api";
import { reset, setClient } from "../../Reducer/tia";

//Component
import AuthBanner from "../Auth/AuthBanner";
import LOADER from "../../Utility/ComponentCard/Loader";
import stylesCommonComponents from "../../Utility/ComponentStyle/style";
import { MAIN_FONT } from "../../Utility/Fonts/font";
import { clearExceptSpecifiedKeys } from "../../Action/asyncCtrl";

class ResetPasswordScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      first_name: "",
      last_name: "",
      password: "",
      confirm_password: "",
      signing: false,
      isLoading: false,
      popupTypeId: 0,
      responseToken: null,
      showPassword: false,
      showConfirmPassword: false,
      isRememberPassword: false,
      isFocus: null,
      token: "",
      uidb64: "",
      popupIconName: "",
      popupButtonText: "",
      popupMessageText: "",
      popupMessageTitle: "",
      showPopupMessageBox: false,
      window: Dimensions.get("window"),
      isPC: Dimensions.get("window").width > 1024 ? true : false,
      isSmall: Dimensions.get("window").height < 900 ? true : false,
      type: props.navigation.state.params?.type || "",
      pathname: props.navigation.state.params?.pathname || "",
    };
  }

  _onBlurr = () => {
    BackHandler.removeEventListener(
      "hardwareBackPress",
      this._handleBackButtonClick
    );
  };

  _onFocus = () => {
    BackHandler.addEventListener(
      "hardwareBackPress",
      this._handleBackButtonClick
    );
  };

  _handleBackButtonClick = () => {
    BackHandler.exitApp();
    return true;
  };

  componentWillUnmount() {
    this.resetBrowserUrl();
  }

  componentDidMount() {
    Dimensions.addEventListener("change", ({ window }) => {
      this.setState({
        isPC: window.width > 1000 ? true : false,
        isSmall: window.height < 900 ? true : false,
      });
    });

    const { pathname } = this.state;
    if (pathname) {
      const parts = pathname.split("/").filter(Boolean);
      const uidb64 = parts[parts.length - 2] || "";
      const token = parts[parts.length - 1] || "";

      this.setState({ uidb64, token });
    }

    if (this.state.type) {
      AsyncStorage.setItem("type", this.state.type)
        .then(() => console.log("Type saved to AsyncStorage"))
        .catch((error) =>
          console.error("Failed to save type to AsyncStorage:", error)
        );
    }

    AsyncStorage.getItem("formData").then((formData) => {
      if (formData) {
        try {
          const { email } = JSON.parse(formData);
          this.setState({ email: email || "" });
        } catch (error) {
          console.error("Error parsing formData:", error);
        }
      }
    });

    AsyncStorage.getItem("formCreateData").then((formCreateData) => {
      if (formCreateData) {
        try {
          const { first_name, last_name, email } = JSON.parse(formCreateData);
          this.setState({
            first_name: first_name || "",
            last_name: last_name || "",
            email: email || "",
          });
        } catch (error) {
          console.error("Error parsing formCreateData:", error);
        }
      }
    });

    AsyncStorage.getItem("formForgotData").then((formForgotData) => {
      if (formForgotData) {
        try {
          const { password, confirm_password, email, token, uidb64 } =
            JSON.parse(formForgotData);
          this.setState({
            password: password || "",
            confirm_password: confirm_password || "",
            email: email || "",
            token: token || "",
            uidb64: uidb64 || "",
          });
        } catch (error) {
          console.error("Error parsing formForgotData:", error);
        }
      }
    });

    this.props.reset({});

    Linking.getInitialURL()
      .then((url) => {
        if (
          url.includes(
            "https://theinvestorsagency.com.au/client_portal/?resetPassword?email="
          )
        ) {
          url = url.replace(
            "https://theinvestorsagency.com.au/client_portal/?resetPassword?email=",
            ""
          );
          this.props.navigation.navigate("ResetPasswordScreen", { email: url });
        }
      })
      .catch(console.warn);

    const search = window.location.search;
    const params = new URLSearchParams(search);
    if (window.location.pathname === "/verify-email" && params.get("email"))
      this.props.navigation.navigate("SignUpScreen");
  }

  validatePassword = (password) => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,30}$/;
    return passwordRegex.test(password);
  };

  validateName = (name, isFirstName = true) => {
    const nameRegex = isFirstName ? /^[a-zA-Z\s]{3,30}$/ : /^[a-zA-Z]{3,30}$/;

    if (!name.trim()) {
      return {
        isValid: false,
        message: isFirstName
          ? "First name can't be empty."
          : "Last name can't be empty.",
      };
    }

    if (!nameRegex.test(name)) {
      return {
        isValid: false,
        message: isFirstName
          ? "First name must only contain letters and spaces, be at least 3 characters, and not exceed 30 characters."
          : "Last name must be a single word with only letters, be at least 3 characters, and not exceed 30 characters.",
      };
    }

    return { isValid: true };
  };

  onPressChangePassword = async () => {
    const {
      first_name,
      last_name,
      email,
      password,
      confirm_password,
      type,
      uidb64,
      token,
    } = this.state;

    if (type === "create") {
      const validateFirstName = this.validateName(first_name, true);
      if (!validateFirstName.isValid) {
        showMessage({
          message: validateFirstName.message,
          type: "info",
          color: "#FFF",
          backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
          duration: 4000,
        });
        return;
      }

      const validateLastName = this.validateName(last_name, false);
      if (!validateLastName.isValid) {
        showMessage({
          message: validateLastName.message,
          type: "info",
          color: "#FFF",
          backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
          duration: 4000,
        });
        return;
      }
    }

    if (!password || !confirm_password) {
      showMessage({
        message: "Password fields cannot be empty.",
        type: "info",
        color: "#FFF",
        backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
        duration: 4000,
      });
      return;
    }

    if (!this.validatePassword(password)) {
      showMessage({
        message:
          "Password must be 8 to 30 characters and include at least one capital letter, one number and one special character.",
        type: "info",
        color: "#FFF",
        backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
        duration: 4000,
      });
      return;
    }

    if (password !== confirm_password) {
      showMessage({
        message: "Password do not match.",
        type: "info",
        color: "#FFF",
        backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
        duration: 4000,
      });
      return;
    }

    try {
      this.setState({ isLoading: true });

      if (type === "create") {
        const formCreateData = {
          first_name,
          last_name,
          email,
          password,
          confirm_password,
        };

        await AsyncStorage.setItem(
          "formCreateData",
          JSON.stringify(formCreateData)
        );

        const result = await registerAccount(formCreateData);

        if (result?.data?.status === 201) {
          showMessage({
            message:
              "Your account has been successfully created." ||
              result?.data?.message,
            type: "info",
            color: "#FFF",
            backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
            duration: 4000,
          });

          clearExceptSpecifiedKeys(["persist:root"]);

          this.state.isPC &&
            this.props.navigation.navigate("LoginScreen", { type: "" });
        } else {
          showMessage({
            message: result?.message || "Users with this email already exists.",
            type: "info",
            color: "#FFF",
            backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
            duration: 4000,
          });
        }
      } else if (type === "forgot") {
        const formForgotData = {
          email,
          password,
          confirm_password,
          token,
          uidb64,
        };

        await AsyncStorage.setItem(
          "formForgotData",
          JSON.stringify(formForgotData)
        );

        const result = await confirmForgotAccount(formForgotData);

        if (result.status === 200) {
          showMessage({
            message: result.message || "Password reset success",
            type: "info",
            color: "#FFF",
            backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
            duration: 4000,
          });

          clearExceptSpecifiedKeys(["persist:root"]);

          this.resetBrowserUrl();

          this.props.navigation.navigate("LoginScreen", { type: "" });
        } else {
          showMessage({
            message:
              result?.exception?.detail ||
              "New password cannot be the same as the old password",
            type: "info",
            color: "#FFF",
            backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
            duration: 4000,
          });
        }
      }
    } catch (error) {
      console.error("Error changing password:", error);

      showMessage({
        message: error.message || "An error occurred while changing password.",
        type: "info",
        color: "#FFF",
        backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
        duration: 4000,
      });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  resetBrowserUrl = () => {
    const baseUrl = `${window.location.origin}/portal-client`;
    window.history.replaceState(null, null, baseUrl);
  };

  render() {
    const { isPC, isFocus, signing, responseToken, type, uidb64, token } =
      this.state;

    return (
      <SafeAreaView
        style={styles.container}
        keyboardShouldPersistTaps={true}
        behavior={Platform.OS === "ios" ? "padding" : "padding"}
        keyboardVerticalOffset={Platform.OS === "ios" ? -200 : 900}
      >
        {isPC ? <AuthBanner /> : null}
        <View
          onPress={Keyboard.dismiss}
          accessible={false}
          style={{
            width: isPC ? "50%" : "100%",
            backgroundColor: MAIN_COLORS.BACKGROUND_BLACK,
          }}
        >
          <View style={styles.boxLogoTIA}>
            {isPC ? (
              <TouchableOpacity
                onPress={() => {
                  this.resetBrowserUrl();
                  this.props.navigation.goBack();
                }}
              >
                <Image
                  source={require("../../assets/home/back.svg")}
                  style={styles.backIcon}
                />
              </TouchableOpacity>
            ) : null}
          </View>
          <View style={[styles.scrollViewContentView, styles.sectionContent]}>
            <View>
              <Text style={styles.sectionTitle}>
                {token || uidb64 ? "Reset Password" : "Account Information"}
              </Text>
              <Text
                style={[
                  styles.sectionSubTitle,
                  { width: isPC ? "60%" : "85%", alignSelf: "center" },
                ]}
              >
                {uidb64 || token
                  ? "Password must be 8 to 30 characters and include at least one capital letter, one number and one special character."
                  : "Enter your account information to finish creating new account."}
              </Text>
            </View>

            <View
              style={[
                styles.sectionCenterView,
                { width: isPC ? "60%" : "85%" },
              ]}
            >
              {type == "create" && (
                <View style={{ flexDirection: "row", gap: 16 }}>
                  <View style={styles.sectionForm}>
                    <Text style={styles.labelForm}>First Name</Text>
                    <div id="input_first_name">
                      <TextInput
                        ref={(input) => (this.first_nameTextInput = input)}
                        keyboardType="web-search"
                        autoCorrect={false}
                        style={[
                          styles.inputForm,
                          {
                            borderColor:
                              isFocus === "first_name" ? "#fff" : "#34393D",
                          },
                        ]}
                        placeholderTextColor="#ADB9C7"
                        value={this.state.first_name}
                        underlineColorAndroid="transparent"
                        placeholder="Enter Your First Name"
                        returnKeyType={"next"}
                        blurOnSubmit={false}
                        autoCapitalize="none"
                        textContentType="none"
                        autoComplete="off"
                        onSubmitEditing={() => {
                          this.last_nameTextInput.focus();
                        }}
                        onFocus={() => this.setState({ isFocus: "first_name" })}
                        onBlur={() => {
                          const cleanedFirstName = this.state.first_name
                            .trim()
                            .replace(/\s+/g, " ");
                          this.setState({ first_name: cleanedFirstName });
                        }}
                        onChangeText={(first_name) => {
                          !signing &&
                            this.setState({ first_name, emailRequired: false });
                        }}
                      />
                    </div>
                  </View>

                  <View style={styles.sectionForm}>
                    <Text style={styles.labelForm}>Last Name</Text>
                    <div id="input_lastName">
                      <TextInput
                        ref={(input) => (this.last_nameTextInput = input)}
                        keyboardType="web-search"
                        autoCorrect={false}
                        style={[
                          styles.inputForm,
                          {
                            borderColor:
                              isFocus === "last_name" ? "#fff" : "#34393D",
                          },
                        ]}
                        placeholderTextColor="#ADB9C7"
                        value={this.state.last_name}
                        underlineColorAndroid="transparent"
                        placeholder="Enter Your Last Name"
                        returnKeyType={"next"}
                        blurOnSubmit={false}
                        autoCapitalize="none"
                        textContentType="none"
                        autoComplete="off"
                        onSubmitEditing={() => {
                          this.passwordTextInput.focus();
                        }}
                        onFocus={() => this.setState({ isFocus: "last_name" })}
                        onBlur={() => this.setState({ isFocus: null })}
                        onChangeText={(last_name) => {
                          !signing &&
                            this.setState({ last_name, emailRequired: false });
                        }}
                      />
                    </div>
                  </View>
                </View>
              )}

              <View style={styles.sectionForm}>
                <Text style={styles.labelForm}>Password</Text>
                <View
                  style={[
                    styles.inputForm,
                    styles.rowCenter,
                    {
                      borderColor: isFocus === "password" ? "#fff" : "#34393D",
                    },
                  ]}
                >
                  <div id="input_password" style={{ flex: 1 }}>
                    <TextInput
                      ref={(input) => (this.passwordTextInput = input)}
                      autoCorrect={false}
                      keyboardType="default"
                      returnKeyType={"done"}
                      value={this.state.password}
                      style={styles.inputPassword}
                      placeholderTextColor="#ADB9C7"
                      placeholder="Enter Your Password"
                      underlineColorAndroid="transparent"
                      blurOnSubmit={false}
                      autoCapitalize="none"
                      textContentType="none"
                      autoComplete="off"
                      onSubmitEditing={() =>
                        this.confirmPasswordTextInput.focus()
                      }
                      onFocus={() => this.setState({ isFocus: "password" })}
                      onBlur={() => this.setState({ isFocus: null })}
                      secureTextEntry={!this.state.showPassword}
                      onChangeText={(password) =>
                        !signing &&
                        this.setState({ password, passwordRequired: false })
                      }
                    />
                  </div>

                  <TouchableOpacity
                    onPress={() =>
                      this.setState({ showPassword: !this.state.showPassword })
                    }
                  >
                    <Image
                      style={{ height: 20, width: 20, resizeMode: "contain" }}
                      source={
                        this.state.showPassword
                          ? require("../../assets/eye.svg")
                          : require("../../assets/eye-slash.svg")
                      }
                    />
                  </TouchableOpacity>
                </View>
              </View>

              <View style={[styles.sectionForm, { marginBottom: 0 }]}>
                <Text style={styles.labelForm}>Confirm Password</Text>
                <View
                  style={[
                    styles.inputForm,
                    styles.rowCenter,
                    {
                      borderColor:
                        isFocus === "confirm_password" ? "#fff" : "#34393D",
                    },
                  ]}
                >
                  <div id="input_confirm_password" style={{ flex: 1 }}>
                    <TextInput
                      ref={(input) => (this.confirmPasswordTextInput = input)}
                      autoCorrect={false}
                      keyboardType="default"
                      returnKeyType={"done"}
                      value={this.state.confirm_password}
                      style={styles.inputPassword}
                      placeholderTextColor="#ADB9C7"
                      placeholder="Enter your confirm password"
                      underlineColorAndroid="transparent"
                      autoCapitalize="none"
                      textContentType="none"
                      autoComplete="off"
                      onSubmitEditing={this.onPressChangePassword}
                      onFocus={() =>
                        this.setState({ isFocus: "confirm_password" })
                      }
                      onBlur={() => this.setState({ isFocus: null })}
                      secureTextEntry={!this.state.showConfirmPassword}
                      onChangeText={(confirm_password) =>
                        !signing &&
                        this.setState({
                          confirm_password,
                          passwordRequired: false,
                        })
                      }
                    />
                  </div>

                  <TouchableOpacity
                    onPress={() =>
                      this.setState({
                        showConfirmPassword: !this.state.showConfirmPassword,
                      })
                    }
                  >
                    <Image
                      style={{ height: 20, width: 20, resizeMode: "contain" }}
                      source={
                        this.state.showConfirmPassword
                          ? require("../../assets/eye.svg")
                          : require("../../assets/eye-slash.svg")
                      }
                    />
                  </TouchableOpacity>
                </View>
              </View>
            </View>

            <View>
              {/* TouchableOpacity Complete */}
              <div id="btn_sign_in">
                <TouchableOpacity
                  style={[
                    stylesCommonComponents.button,
                    styles.btnSubmit,
                    { width: isPC ? "60%" : "85%" },
                  ]}
                  onPress={() => this.onPressChangePassword()}
                >
                  {signing && responseToken ? (
                    <MaterialIndicator
                      size={RFValue(16.75)}
                      color={MAIN_COLORS.BACKGROUND_WHITE}
                    />
                  ) : (
                    <Text style={styles.textButton}>Complete</Text>
                  )}
                </TouchableOpacity>
              </div>
            </View>
          </View>
        </View>
        {this.state.isLoading ? <LOADER /> : null}
      </SafeAreaView>
    );
  }
}

function mapStateToProps(state) {
  return {
    COLORS: state.COLORS,
    USER: state.USER,
    CART: state.CART,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setClient,
      reset,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordScreen);
