import React, { memo } from "react";
import { View, Image, Text, Dimensions, TouchableOpacity } from "react-native";
import { withNavigation } from "react-navigation";

import styles from "./style";

const AuthBanner = ({ navigation }) => {
  const isPc = Dimensions.get("window").width > 1000 ? true : false;
  const isSmall = Dimensions.get("window").height < 900 ? true : false;

  return (
    <View
      style={{
        overflow: "hidden",
        width: isPc ? "50%" : "100%",
        display: !isPc && "none",
      }}>
      <View style={styles.boxLogoTIA}>
        <TouchableOpacity
          activeOpacity={1}
          onPress={() => navigation.navigate("LoginScreen")}>
          <Image
            style={{ height: 57, width: 179 }}
            resizeMode="contain"
            source={require("../../assets/home/logo.png")}
          />
        </TouchableOpacity>
      </View>

      <View style={styles.sectionContent}>
        <View>
          <Text style={styles.sectionTitle}>The Investors Agency</Text>
          <Text style={styles.sectionSubTitle}>
            You’re on your way to better property investment.
          </Text>
        </View>

        <View
          style={{
            flex: 1,
            width: "90%",
            marginHorizontal: "auto",
          }}>
          <Image
            style={{ width: "100%", height: "100%" }}
            resizeMode="contain"
            source={require("../../assets/home/pc.svg")}
          />
        </View>

        <Text style={[styles.sectionSubTitle, { paddingBottom: 24 }]}>
          Copyright 2022 The Investors Agency Ptv Ltd
        </Text>
      </View>
    </View>
  );
};

export default memo(withNavigation(AuthBanner));
