import moment from "moment";
import React, { useState, useEffect, memo } from "react";
import { View, StyleSheet, Text, Image, TouchableOpacity } from "react-native";

import { MAIN_COLORS } from "../../../Utility/Colors";
import { formatDollar } from "../../../Utility/common";
import { FONT_SIZE, FONT_WEIGHT, MAIN_FONT } from "../../../Utility/Fonts/font";
import { isNumber } from "chart.js/helpers";

const Comparative = ({ property, openUrl }) => {
  const data = [
    {
      title: "1st Comparable Property",
      image: property?.["COMPARITIVE PROPERTY 1 (IMAGE)"]?.replace(
        "-format=avif,",
        ""
      ),
      price: property?.["COMPARITIVE PROPERTY 1 (PRICE)"],
      date: property?.["Comparable Property 1 [Date]"],
      url: property?.["COMPARATIVE PROPERTY 1 (URL)"],
    },
    {
      title: "2nd Comparable Property",
      image: property?.["COMPARITIVE PROPERTY 2 (IMAGE)"]?.replace(
        "-format=avif,",
        ""
      ),
      price: property?.["COMPARITIVE PROPERTY 2 (PRICE)"],
      date: property?.["Comparable Property 2 [Date]"],
      url: property?.["COMPARATIVE PROPERTY 2 [URL]"],
    },
    {
      title: "3rd Comparable Property",
      image: property?.["COMPARITIVE PROPERTY 3 (IMAGE)"]?.replace(
        "-format=avif,",
        ""
      ),
      price: property?.["COMPARITIVE PROPERTY 3 (PRICE)"],
      date: property?.["Comparable Property 3 [Date]"],
      url: property?.["COMPARATIVE PROPERTY 3 [URL]"],
    },
  ];
  const [itemSelected, setItemsSelected] = useState(data[0]);

  useEffect(() => {
    if (property) setItemsSelected(data[0]);
  }, [property]);

  return (
    <View style={styles.container}>
      <View style={styles.sectionRowBetween}>
        <View
          style={{
            flex: 2,
            height: "100%",
            overflow: "hidden",
            borderRadius: 10,
          }}
        >
          <Image
            style={{
              width: "100%",
              height: "100%",
              transform: [{ scale: 2.5 }],
            }}
            source={{
              uri: itemSelected?.image,
            }}
          />
        </View>
        <View style={styles.detail}>
          <Text style={styles.detailTitle}>{itemSelected?.title}</Text>
          <View style={styles.sectionRowBetween}>
            <Text style={styles.text}>Sold Price: </Text>
            <Text style={[styles.text, { color: MAIN_COLORS.TEXT_LIGHT }]}>
              {formatDollar(itemSelected?.price)}
            </Text>
          </View>
          <View style={styles.sectionRowBetween}>
            <Text style={styles.text}>Sold Date: </Text>
            <Text style={[styles.text, { color: MAIN_COLORS.TEXT_LIGHT }]}>
              {itemSelected?.date && isNumber(itemSelected?.date)
                ? moment(Number(itemSelected?.date)).format("DD/MM/YYYY")
                : null}
            </Text>
          </View>
          <View style={styles.divider} />
          <TouchableOpacity onPress={() => openUrl(itemSelected.url)}>
            <Text style={styles.textBtnView}>View</Text>
          </TouchableOpacity>
        </View>
      </View>
      <View style={styles.sectionRowBetween}>
        {data.map((item, index) => {
          return (
            <TouchableOpacity
              key={index}
              style={styles.btnThumbnail}
              onPress={() => setItemsSelected(item)}
            >
              <Image
                style={styles.image}
                resizeMode="content"
                source={{
                  uri: item?.image,
                }}
              />
            </TouchableOpacity>
          );
        })}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 12,
    borderRadius: 10,
    display: "flex",
    backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
    gap: 12,
  },

  sectionRowBetween: {
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 12,
  },

  detail: {
    flex: 1.3,
    borderRadius: 6,
    paddingHorizontal: 16,
    paddingVertical: 12,
    borderWidth: 1,
    borderColor: "#4B555D99",
    display: "flex",
    gap: 16,
  },

  detailTitle: {
    fontWeight: FONT_WEIGHT.bold,
    fontSize: FONT_SIZE.medium,
    lightHeight: 21,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.semi,
  },

  divider: {
    width: "100%",
    height: 1,
    backgroundColor: "#4B555D99",
  },

  text: {
    fontSize: FONT_SIZE.medium,
    fontWeight: FONT_WEIGHT.medium,
    lineHeight: 21,
    color: MAIN_COLORS.TEXT_GRAY,
    fontFamily: MAIN_FONT.regular,
  },

  btnThumbnail: {
    flex: 1,
    width: "auto",
    height: "auto",
    aspectRatio: 3 / 1.5,
    borderRadius: 6,
    overflow: "hidden",
  },

  image: {
    transform: [{ scale: 2.8 }],
    width: "100%",
    height: "100%",
  },

  textBtnView: {
    fontSize: FONT_SIZE.small,
    fontWeight: 500,
    lightHeight: 18,
    textAlign: "center",
    color: MAIN_COLORS.PRIMARY_COLOR,
    textDecorationLine: "underline",
    fontFamily: MAIN_FONT.regular,
  },
});

export default memo(Comparative);
