import React from "react";
import {
  View,
  Text,
  Linking,
  Keyboard,
  Platform,
  Dimensions,
  BackHandler,
  SafeAreaView,
  TouchableOpacity,
  Image,
} from "react-native";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showMessage } from "react-native-flash-message";
import { RFValue } from "react-native-responsive-fontsize";
import { MaterialIndicator } from "react-native-indicators";
import { OtpInput } from "react-native-otp-entry";
import AsyncStorage from "@react-native-async-storage/async-storage";

// Function
import styles from "../Auth/style";
import { MAIN_COLORS } from "../../Utility/Colors";
import { reset, setClient } from "../../Reducer/tia";

// Component
import AuthBanner from "../Auth/AuthBanner";
import LOADER from "../../Utility/ComponentCard/Loader";
import stylesCommonComponents from "../../Utility/ComponentStyle/style";
import { MAIN_FONT } from "../../Utility/Fonts/font";
import { resendOTPAccount, verifyOTPAccount } from "../../Clickup/api";

class OTPScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      otp: "",
      signing: false,
      isLoading: false,
      countdown: 60,
      isPC: Dimensions.get("window").width > 1024,
      isSmall: Dimensions.get("window").height < 900,
      type: props.navigation.state.params?.type || "",
    };
    this.countdownInterval = null;
  }

  componentDidMount() {
    Dimensions.addEventListener("change", ({ window }) => {
      this.setState({
        isPC: window.width > 1000,
        isSmall: window.height < 900,
      });
    });

    if (this.state.type) {
      AsyncStorage.setItem("type", this.state.type)
        .then(() => console.log("Type saved to AsyncStorage"))
        .catch((error) =>
          console.error("Failed to save type to AsyncStorage:", error)
        );
    }

    AsyncStorage.getItem("formData").then((formData) => {
      if (formData) {
        const { email } = JSON.parse(formData);
        this.setState({
          email: email || "",
        });
      }
    });

    this.props.reset({});
    this.startCountdown();
  }

  componentWillUnmount() {
    if (this.countdownInterval) clearInterval(this.countdownInterval);
  }

  resendOTP = async () => {
    this.setState({ otp: "" }, () => {
      this.otpInput.clear();
    });

    const { email } = this.state;

    if (!email) {
      showMessage({
        message: "Email does not exist, please try again.",
        type: "info",
        color: "#FFF",
        backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
        duration: 4000,
      });
      return;
    }

    try {
      this.setState({ isLoading: true });

      const response = await resendOTPAccount({ email });

      if (response?.status === 200) {
        showMessage({
          message: response.message || "Resend OTP successful",
          type: "info",
          color: "#FFF",
          backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
          duration: 4000,
        });
        this.startCountdown();
      } else {
        showMessage({
          message: response?.detail || "Resend OTP failed.",
          type: "info",
          color: "#FFF",
          backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
          duration: 4000,
        });
      }
    } catch (error) {
      console.error("Error in resendOTP:", error);
      showMessage({
        message: "An error occurred while resending OTP.",
        type: "info",
        color: "#FFF",
        backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
        duration: 4000,
      });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  startCountdown = () => {
    this.setState({ countdown: 60 });
    if (this.countdownInterval) clearInterval(this.countdownInterval);

    this.countdownInterval = setInterval(() => {
      this.setState((prevState) => {
        if (prevState.countdown <= 1) {
          clearInterval(this.countdownInterval);
          return { countdown: 0 };
        }
        return { countdown: prevState.countdown - 1 };
      });
    }, 1000);
  };

  handleOTPChange = (otp) => {
    this.setState({ otp });
  };

  onPressOTP = async () => {
    const { email, otp } = this.state;
    const formData = { email, otp };

    await AsyncStorage.setItem("formData", JSON.stringify(formData));

    if (otp.length === 6) {
      try {
        const result = await verifyOTPAccount(formData);

        if (result.status === 200) {
          showMessage({
            message: result.message || "OTP verification successful",
            type: "info",
            color: "#FFF",
            backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
            duration: 4000,
          });

          this.props.navigation.navigate("ResetPasswordScreen", {
            type: this.state.type,
          });
        } else {
          showMessage({
            message: result?.exception?.detail || "OTP has expired",
            type: "info",
            color: "#FFF",
            backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
            duration: 4000,
          });
        }
      } catch (error) {
        console.error("Error verifying OTP:", error);
        showMessage({
          message: "Error verifying OTP, please try again.",
          type: "info",
          color: "#FFF",
          backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
          duration: 4000,
        });
      }
    } else {
      showMessage({
        message: "Please enter the complete OTP.",
        type: "info",
        color: "#FFF",
        backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
        duration: 4000,
      });
    }
  };

  render() {
    const { isPC, signing, isLoading, otp, type, countdown } = this.state;

    const text = {
      create: {
        subTitle:
          "OTP code has been sent to your registered Email. Please enter the OTP verification code and proceed to register your account.",
      },
      forgot: {
        subTitle:
          "OTP code has been sent to your Email. Please enter the OTP verification code and proceed to reset your password.",
      },
    };

    return (
      <SafeAreaView
        style={styles.container}
        keyboardShouldPersistTaps={true}
        behavior={Platform.OS === "ios" ? "padding" : "padding"}
        keyboardVerticalOffset={Platform.OS === "ios" ? -200 : 900}>
        <AuthBanner />
        <View
          onPress={Keyboard.dismiss}
          accessible={false}
          style={{
            width: isPC ? "50%" : "100%",
            backgroundColor: MAIN_COLORS.BACKGROUND_BLACK,
          }}>
          <View style={styles.boxLogoTIA}>
            <TouchableOpacity
              onPress={() =>
                this.props.navigation.goBack()
              }>
              <Image
                source={require("../../assets/home/back.svg")}
                style={styles.backIcon}
              />
            </TouchableOpacity>
          </View>
          <View style={[styles.scrollViewContentView, styles.sectionContent]}>
            <View>
              <Text style={styles.sectionTitle}>OTP Verification</Text>
              <Text
                style={[
                  styles.sectionSubTitle,
                  { width: "60%", alignSelf: "center" },
                ]}>
                {text[type]?.subTitle}
              </Text>
            </View>

            <View style={{ width: "60%", alignSelf: "center" }}>
              <OtpInput
                ref={(ref) => (this.otpInput = ref)}
                type="numeric"
                onTextChange={this.handleOTPChange}
                numberOfInputs={6}
                focusColor={MAIN_COLORS.PRIMARY_COLOR}
                theme={{
                  containerStyle: {
                    justifyContent: "center",
                  },
                  pinCodeContainerStyle: {
                    width: 48,
                    height: 56,
                    borderRadius: 8,
                    marginHorizontal: 6,
                  },
                  pinCodeTextStyle: {
                    fontWeight: "500",
                    fontSize: 24,
                    color: MAIN_COLORS.PRIMARY_COLOR,
                  },
                  filledPinCodeContainerStyle: {
                    borderColor: MAIN_COLORS.PRIMARY_COLOR,
                  },
                }}
              />

              <View
                style={{
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: 24,
                }}>
                <Text
                  style={{
                    color: "#9D9EAB",
                    fontSize: 14,
                    fontWeight: 400,
                    fontFamily: MAIN_FONT.regular,
                    marginBottom: 4,
                  }}>
                  Didn’t receive the verification OTP?
                </Text>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                  }}>
                  <TouchableOpacity
                    onPress={this.resendOTP}
                    disabled={countdown > 0}>
                    <Text
                      style={{
                        color: MAIN_COLORS.PRIMARY_COLOR,
                        fontSize: 12,
                        fontWeight: "500",
                        fontFamily: MAIN_FONT.regular,
                      }}>
                      Resend
                    </Text>
                  </TouchableOpacity>
                  <Text
                    style={{
                      color: MAIN_COLORS.PRIMARY_COLOR,
                      fontSize: 12,
                      fontWeight: 500,
                      fontFamily: MAIN_FONT.regular,
                      marginLeft: 4,
                    }}>
                    ({countdown}s)
                  </Text>
                </View>
              </View>
            </View>

            <TouchableOpacity
              style={[stylesCommonComponents.button, styles.btnSubmit]}
              onPress={this.onPressOTP}>
              {signing ? (
                <MaterialIndicator
                  size={RFValue(16.75)}
                  color={MAIN_COLORS.BACKGROUND_WHITE}
                />
              ) : (
                <Text style={styles.textButton}>Next</Text>
              )}
            </TouchableOpacity>
          </View>
        </View>
        {isLoading && <LOADER />}
      </SafeAreaView>
    );
  }
}

function mapStateToProps(state) {
  return {
    COLORS: state.COLORS,
    USER: state.USER,
    CART: state.CART,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setClient,
      reset,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(OTPScreen);
