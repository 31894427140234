import React, { useState, useEffect, Fragment, useRef } from "react";
import {
  Animated,
  Dimensions,
  Image,
  Linking,
  Modal,
  PermissionsAndroid,
  Platform,
  SafeAreaView,
  ScrollView,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import { Autolink } from "react-native-autolink";
import { createElement } from "react-native-web";
import VPlayer from "react-vplayer";
// import Pdf from "react-native-pdf";
import { showMessage } from "react-native-flash-message";
import { widthPercentageToDP as wp } from "react-native-responsive-screen";
import WebView from "react-native-webview";
import { NavigationEvents } from "react-navigation";
import { connect } from "react-redux";
import { setColors } from "../../Action/color";
import { user as setUser } from "../../Reducer/user";
import { MAIN_COLOR } from "../../Utility/Colors";
import LOADER from "../../Utility/ComponentCard/Loader";
import fields from "../../Utility/Fields";
import { MAIN_FONT } from "../../Utility/Fonts/font";
// import * as WebBrowser from "expo-web-browser";
import styles from "./style";
import { getContactByEmail } from "../../Clickup/api";
import Toast from "react-native-toast-message";
import { InfoToast } from "react-native-toast-message";
import { toastConfig } from "../../configs/toastConfig";
import ReactPlayer from "react-player";
import Slider from "@react-native-community/slider";

import ViewGallery from "../../components/ViewGallery";
import Update from "./components/Update";
import Sidebar from "./components/Sidebar";
import Videos from "./components/Videos";
import Note from "./components/Note";
import Checklist from "./components/Checklist";
import BookACall from "../ProjectDetails/components/BookCall";
import ModalBase from "../../components/Modal";
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      today: new Date(),
      monthly: false,
      week: [],
      pdfs: [],
      pngs: [],
      clickUpStatusList: props?.clickUpStatusList ?? [],
      id: props.id,
      task: props.task,
      comments: props.comments,
      status: props.status,
      checklists: props?.checklists ?? [],
      time: props.time,
      curTime: props.curTime,
      progressTime: props.progressTime,
      markedDates: {},
      opacity: new Animated.Value(1),
      height: new Animated.Value(0),
      videoUrl: false,
      controlUrl: false,
      videos: props.task?.Videos ? props.task?.Videos : [],
      links: props.task?.Link ? props.task?.Link : [],
      files: props.task?.Files ? props.task?.Files : [],
      linkVideo: props.task?.["Link Video"] ? props.task?.["Link Video"] : [],
      filesCount: props.filesCount,
      linksCount: props.linksCount,
      videosCount: props.videosCount,
      booking: props.booking,
      assignee: props.assignee,
      videosCount: props.videosCount,
      selectedLink: false,
      videoNull: false,
      fileName: false,
      propertyConditionNotes: props.propertyConditionNotes,
      selected: {},
      walkThroughDate: props.walkThroughDate,
      preSettlementDate: props.preSettlementDate,
      settlementDate: props.settlementDate,
      buildingPestDate: props.buildingPestDate,
      financeDate: props.financeDate,
      solicitorName: props.solicitorName,
      solicitorPhone: props.solicitorPhone,
      solicitorEmail: props.solicitorEmail,
      brokerName: props.brokerName,
      brokerEmail: props.brokerEmail,
      brokerPhone: props.brokerPhone,
      adminStrationPhone: null,
      adminStrationAvatar: null,
      oppotunityAssigneeEmail: props.oppotunityAssigneeEmail,
      buyerAgentName: null,
      buyerAgentEmail: null,
      buyerAgentPhone: null,
      buyerAgentAvatar: null,
      oppotunityHeadBuyerAgentEmail: props.oppotunityHeadBuyerAgentEmail,
      headBuyerAgentName: null,
      headBuyerAgentEmail: null,
      headBuyerAgentPhone: null,
      headBuyerAgentAvatar: null,
      bpInspectorName: props.bpInspectorName,
      bpInspectorEmail: props.bpInspectorEmail,
      bpInspectorPhone: props.bpInspectorPhone,
      propertyManagerName: props.propertyManagerName,
      propertyManagerEmail: props.propertyManagerEmail,
      propertyManagerPhone: props.propertyManagerPhone,
      quantitySurveyorPhone: null,
      adminHeadName: props.adminHeadName,
      adminHeadPhone: props.adminHeadPhone,
      adminHeadEmail: props.adminHeadEmail,
      adminHeadAvatar: props.adminHeadAvatar,
      adminSupportName: props.adminSupportName,
      adminSupportPhone: props.adminSupportPhone,
      adminSupportEmail: props.adminSupportEmail,
      adminSupportAvatar: props.adminSupportAvatar,
      headStrategyName: props.headStrategyName,
      headStrategyPhone: props.headStrategyPhone,
      headStrategyEmail: props.headStrategyEmail,
      headStrategyAvatar: props.headStrategyAvatar,
      directorOperationsName: props.directorOperationsName,
      directorOperationsPhone: props.directorOperationsPhone,
      directorOperationsEmail: props.directorOperationsEmail,
      directorOperationsAvatar: props.directorOperationsAvatar,
      exchangeManagerName: props.exchangeManagerName,
      exchangeManagerPhone: props.exchangeManagerPhone,
      exchangeManagerEmail: props.exchangeManagerEmail,
      exchangeManagerAvatar: props.exchangeManagerAvatar,
      propertyAnalystName: props.propertyAnalystName,
      propertyAnalystPhone: props.propertyAnalystPhone,
      propertyAnalystEmail: props.propertyAnalystEmail,
      propertyAnalystAvatar: props.propertyAnalystAvatar,
    };

    this.player = [];
  }

  getNextSevenDays = () => {
    var curr = new Date(); // get current date
    var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    var last = first + 6; // last day is the first day + 6

    let arr = [];

    for (let i = 0; i < 7; i++) {
      arr.push(new Date(curr.setDate(first + i)));
    }

    this.setState({ week: arr, selected: new Date().getDate() });
  };

  fullScreen() {
    this.player[this.state.videoUrl].presentFullscreenPlayer();
  }

  componentWillUnmount() {}

  componentDidUpdate() {}

  async generateThumbnail() {
    return (
      <View style={{ flex: 1, backgroundColor: "red", width: "100%" }}>
        <Pdf
          singlePage={false}
          enablePaging
          spacing={100}
          source={source}
          onLoadComplete={(numberOfPages, filePath) => {
            console.log(`number of pages: ${numberOfPages}`);
          }}
          onPageChanged={(page, numberOfPages) => {
            console.log(`current page: ${page}`);
          }}
          onError={(error) => {
            console.log(error);
          }}
          onPressLink={(uri) => {
            console.log(`Link presse: ${uri}`);
          }}
          activityIndicatorProps={{ color: "red" }}
          style={{ flex: 1, backgroundColor: "#FFF" }}
        />
      </View>
    );
  }

  async downloadFile(fileName, path) {
    fileName = fileName.replace(/ /g, "");
    console.log("fileName:: ", fileName);

    var RNFS = require("react-native-fs");

    var DownloadDir = RNFS.CachesDirectoryPath; //+ "/test.txt";

    const folderExists = await RNFS.exists(DownloadDir);
    if (!folderExists) {
      await RNFS.mkdir(DownloadDir);
    }

    path = path.replace("/view", "&export=download");

    RNFS.downloadFile({
      fromUrl: path, //.replace("usp=sharing",""),
      toFile: DownloadDir + "/" + this.state.id + fileName + ".pdf",
    }).promise.then((r) => {
      if (r.statusCode === 200) {
        let pdfs = this.state.pdfs;

        pdfs.push(DownloadDir + "/" + this.state.id + fileName + ".pdf");
        this.setState({ pdfs }, () => {
          console.log("pdfs:::", this.state.pdfs);
        });
      }
    });
  }

  async downloadFileWeb(fileName, path) {
    fileName = fileName.replace(/ /g, "");
    console.log("fileName:: ", fileName);

    const fileIdMatch = path.match(/\/d\/([^\/]+)/);
    const fileId = fileIdMatch ? fileIdMatch[1] : null;

    if (!fileId) {
      console.error("Invalid Google Drive link. File ID not found.");
      return;
    }

    const downloadUrl = `https://drive.google.com/uc?id=${fileId}&export=download`;

    try {
      const supported = await Linking.canOpenURL(downloadUrl);
      if (supported) {
        await Linking.openURL(downloadUrl);
      }
    } catch (error) {
      console.error("Error opening URL:", error);
    }
  }

  async downloadPng(fileName, path) {
    var RNFS = require("react-native-fs");
    var DownloadDir = RNFS.CachesDirectoryPath;
    const folderExists = await RNFS.exists(DownloadDir);
    if (!folderExists) {
      await RNFS.mkdir(DownloadDir);
    }
    path = path.replace("/view", "&export=download");
    RNFS.downloadFile({
      fromUrl: path,
      cacheable: true,
      toFile: DownloadDir + "/" + this.state.id + fileName + ".png",
    }).promise.then((r) => {
      console.log(
        "results:",
        r,
        DownloadDir + "/" + this.state.id + fileName + ".png"
      );

      if (r.statusCode === 200) {
        let pngs = this.state.pngs;

        pngs.push(DownloadDir + "/" + this.state.id + fileName + ".png");
        this.setState({ pngs }, () => {
          console.log("pngs::", this.state.pngs);
        });
      } else {
        showMessage({
          message: path,
          type: "info",
          color: "#FFF",
          backgroundColor: MAIN_COLOR.PRIMARY_COLOR,
          duration: 4000,
        });
      }
    });
  }

  onCopyPath = (path, noti) => {
    navigator.clipboard.writeText(path);
    Toast.hide();
    setTimeout(() => {
      Toast.show({
        type: "info",
        text1: noti,
        position: "bottom",
        visibilityTime: 3000,
      });
    }, 300);
  };

  onCopyPhone() {
    Toast.hide();
    setTimeout(() => {
      Toast.show({
        type: "info",
        text1: "Phone number copied!",
        position: "bottom",
        visibilityTime: 3000,
      });
    }, 300);
  }

  getImages(arr) {
    let pngs = [];
    if (!arr.length) {
      this.setState({ pngs: [] });
      return null;
    }

    arr.map((item, index) => {
      let search = item.search("https://drive.google.com/file/d/");

      if (search === 0) {
        this.downloadPng(
          "Image_" + index,
          item.replace(
            "https://drive.google.com/file/d/",
            "https://drive.google.com/uc?id="
          )
        );
      } else {
        let pngs = this.state.pngs;
        pngs.push(item);
        this.setState({ pngs });
      }
    });
  }

  getFiles() {
    const { files } = this.state;

    let pdfs = [];

    if (!files) {
      this.setState({ pdfs: [] });

      return null;
    }

    Object.keys(files).map((item, index) => {
      if (!files[item]) {
        this.setState({ fileNull: true });
        return;
      }

      pdfs.push(files[item]);
    });

    this.setState({ pdfs });
  }

  componentDidMount() {
    PermissionsAndroid.request(
      PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE,
      {
        title: "Cool Photo App Camera Permission",
        message:
          "Cool Photo App needs access to your camera " +
          "so you can take awesome pictures.",
        buttonNeutral: "Ask Me Later",
        buttonNegative: "Cancel",
        buttonPositive: "OK",
      }
    );

    PermissionsAndroid.requestMultiple([
      PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE,
      PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
    ]).then((result) => {});

    if (this.state.status != "Complete" && this.state.status != "In Progress") {
    }

    if (this.state.files["Market Data"]) {
      this.setState({ pngs: this.state.files["Market Data"] });
    } else {
      this.getFiles();
    }

    if (this.state.task[fields.appStageName] == "Profile Review") {
      getContactByEmail("taliesha@theinvestorsagency.com.au").then((result) => {
        const tasks = result?.tasks;
        if (tasks.length) {
          const custom_fields = tasks[0].custom_fields;
          const adminStrationPhone = custom_fields.find(
            (field) => field.name === "Phone"
          )?.value;

          const avatar = custom_fields.find(
            (field) => field.name === "Contact photo"
          )?.value;
          const adminStrationAvatar = avatar?.length ? avatar[0]?.url : null;
          this.setState({
            adminStrationPhone,
            adminStrationAvatar,
          });
        }
      });
    }

    if (
      this.state.task[fields.appStageName] == "Search Process" &&
      this.state.oppotunityAssigneeEmail
    ) {
      getContactByEmail(this.state.oppotunityAssigneeEmail).then((result) => {
        const tasks = result?.tasks;
        if (tasks.length) {
          const custom_fields = tasks[0].custom_fields;
          const buyerAgentName = tasks[0].name;
          const buyerAgentEmail = custom_fields.find(
            (field) => field.name === "Email"
          )?.value;
          const buyerAgentPhone = custom_fields.find(
            (field) => field.name === "Phone"
          )?.value;

          const avatar = custom_fields.find(
            (field) => field.name === "Contact photo"
          )?.value;
          const buyerAgentAvatar = avatar?.length ? avatar[0]?.url : null;

          this.setState({
            buyerAgentName,
            buyerAgentEmail,
            buyerAgentPhone,
            buyerAgentAvatar,
          });
        }
      });
    }

    if (
      this.state.task[fields.appStageName] == "Profile Review" &&
      this.state.oppotunityHeadBuyerAgentEmail
    ) {
      getContactByEmail(this.state.oppotunityHeadBuyerAgentEmail).then(
        (result) => {
          const tasks = result?.tasks;
          if (tasks.length) {
            const custom_fields = tasks[0].custom_fields;
            const headBuyerAgentName = tasks[0].name;
            const headBuyerAgentEmail = custom_fields.find(
              (field) => field.name === "Email"
            )?.value;
            const headBuyerAgentPhone = custom_fields.find(
              (field) => field.name === "Phone"
            )?.value;

            const avatar = custom_fields.find(
              (field) => field.name === "Contact photo"
            )?.value;
            const headBuyerAgentAvatar = avatar?.length ? avatar[0]?.url : null;
            this.setState({
              headBuyerAgentName,
              headBuyerAgentEmail,
              headBuyerAgentPhone,
              headBuyerAgentAvatar,
            });
          }
        }
      );
    }

    if (this.state.task[fields.appStageName] == "Settlement") {
      getContactByEmail("ayden.hassan@mcgqs.com.au").then((result) => {
        const tasks = result?.tasks;
        if (tasks.length) {
          const custom_fields = tasks[0].custom_fields;
          const quantitySurveyorPhone = custom_fields.find(
            (field) => field.name === "Phone"
          )?.value;

          this.setState({
            quantitySurveyorPhone,
          });
        }
      });
    }
  }

  render() {
    const {
      booking,
      assignee,
      pdfs,
      pngs,
      linkVideo,
      videosCount,
      linksCount,
      videos,
      task,
      files,
      status,
      height,
      selectedLink,
      time,
      curTime,
      progressTime,
      videoNull,
      fileNull,
      links,
      clickUpStatusList,
      id,
      checklists,
      propertyConditionNotes,
      walkThroughDate,
      preSettlementDate,
      settlementDate,
      buildingPestDate,
      financeDate,
      solicitorName,
      solicitorPhone,
      solicitorEmail,
      brokerName,
      brokerEmail,
      brokerPhone,
      adminStrationPhone,
      adminStrationAvatar,
      buyerAgentName,
      buyerAgentEmail,
      buyerAgentPhone,
      buyerAgentAvatar,
      headBuyerAgentName,
      headBuyerAgentEmail,
      headBuyerAgentPhone,
      headBuyerAgentAvatar,
      bpInspectorName,
      bpInspectorPhone,
      bpInspectorEmail,
      propertyManagerName,
      propertyManagerEmail,
      propertyManagerPhone,
      quantitySurveyorPhone,
      adminHeadName,
      adminHeadPhone,
      adminHeadEmail,
      adminHeadAvatar,
      adminSupportName,
      adminSupportPhone,
      adminSupportEmail,
      adminSupportAvatar,
      headStrategyName,
      headStrategyPhone,
      headStrategyEmail,
      headStrategyAvatar,
      directorOperationsName,
      directorOperationsPhone,
      directorOperationsEmail,
      directorOperationsAvatar,
      exchangeManagerName,
      exchangeManagerPhone,
      exchangeManagerEmail,
      exchangeManagerAvatar,
      propertyAnalystName,
      propertyAnalystPhone,
      propertyAnalystEmail,
      propertyAnalystAvatar,
    } = this.state;
    const MyLatLngMatcher = {
      pattern:
        /(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})/g,
      onPress: () => alert("LatLng pressed!"),
    };

    const web = (link) => {
      const Video = (props) => {
        return (
          <video
            src={props.source}
            width="100%"
            height="100%"
            controls={false}
            frameBorder="0"
            webkitallowfullscreen={"true"}
            mozallowfullscreen={"true"}
            allowFullScreen
            style={{
              objectFit: "cover",
            }}
          />
        );
      };

      return <Video source={link} />;
    };

    const webVideo = (link) => {
      const Video = (props) => {
        return (
          <video
            src={props.source}
            width="100%"
            height="100%"
            controls={true}
            frameBorder="0"
            webkitAllowFullScreen
            mozAllowFullScreen
            allowFullScreen
            style={{
              objectFit: "cover",
            }}
          />
        );
      };

      return <Video source={link} />;
    };

    const webVideoControl = (videos, onFullScreenToggle, isFullScreen) => {
      const VideoPlayer = (props) => {
        const playerRef = useRef(null);
        const [playing, setPlaying] = useState(false);
        const [volume, setVolume] = useState(1);
        const [isMuted, setIsMuted] = useState(false);
        const [lastVolume, setLastVolume] = useState(1);
        const [currentTime, setCurrentTime] = useState(0);
        const [duration, setDuration] = useState(0);
        const [isHoveredSpeaker, setIsHoveredSpeaker] = useState(false);
        const trackWidth = (currentTime / duration) * 100;
        const animatedWidth = useRef(new Animated.Value(0)).current;

        useEffect(() => {
          Animated.timing(animatedWidth, {
            toValue: isHoveredSpeaker ? 100 : 0,
            duration: 300,
            useNativeDriver: false,
          }).start();
        }, [isHoveredSpeaker]);

        const handlePlayPause = () => {
          setPlaying(!playing);
        };

        const handleVolumeChange = (value) => {
          setVolume(value);
          if (value === 0) {
            setIsMuted(true);
          } else {
            setIsMuted(false);
            setLastVolume(value);
          }
        };

        const toggleMute = () => {
          if (isMuted) {
            setVolume(lastVolume || 1);
            setIsMuted(false);
          } else {
            setLastVolume(volume);
            setVolume(0);
            setIsMuted(true);
          }
        };

        const handleSliderChange = (value) => {
          setCurrentTime(value);
          if (playerRef.current) {
            playerRef.current.seekTo(value);
          }
        };

        const handleDuration = (duration) => {
          setDuration(duration);
        };

        const handleProgress = (state) => {
          setCurrentTime(state.playedSeconds);
        };

        const handleCopyLink = () => {
          this.onCopyPath(videos, "Link video copied!");
        };

        const toggleFullScreen = () => {
          const newFullScreenState = !isFullScreen;
          onFullScreenToggle(newFullScreenState);
        };

        return (
          <View
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
            }}
          >
            <ReactPlayer
              ref={playerRef}
              url={props.source}
              playing={playing}
              volume={volume}
              width="100%"
              height="100%"
              controls={false}
              onDuration={handleDuration}
              onProgress={handleProgress}
            />

            {!playing && (
              <TouchableOpacity
                onPress={handlePlayPause}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  width: 68,
                  height: 68,
                  zIndex: 100,
                  transform: "translate(-50%, -50%)",
                }}
              >
                <Image
                  style={{ width: 68, height: 68 }}
                  source={require("../../assets/pause-video-circle.svg")}
                />
              </TouchableOpacity>
            )}

            {/* Control */}
            <View
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 16,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                gap: 26,
              }}
            >
              {/* Button Play */}
              <TouchableOpacity onPress={handlePlayPause}>
                <Image
                  source={
                    !playing
                      ? require("../../assets/player-play.svg")
                      : require("../../assets/pause-video.svg")
                  }
                  style={{ width: 24, height: 24 }}
                />
              </TouchableOpacity>

              {/* Volume Slider */}
              <Animated.View
                onMouseEnter={() => setIsHoveredSpeaker(true)}
                onMouseLeave={() => setIsHoveredSpeaker(false)}
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: animatedWidth,
                  marginRight: 26,
                  cursor: "pointer",
                }}
              >
                <TouchableOpacity onPress={toggleMute}>
                  <Image
                    source={
                      isMuted
                        ? require("../../assets/player-volume-mute.svg")
                        : require("../../assets/player-volume.svg")
                    }
                    style={{ width: 24, height: 24 }}
                  />
                </TouchableOpacity>
                {isHoveredSpeaker && (
                  <Animated.View
                    style={{
                      position: "absolute",
                      left: 30,
                      width: animatedWidth,
                      height: 12,
                      backgroundColor: "transparent",
                    }}
                  >
                    <View
                      style={{
                        height: 12,
                        backgroundColor: "#4A5156",
                        borderRadius: 6,
                        width: "100%",
                      }}
                    />

                    <Animated.View
                      style={{
                        height: 12,
                        backgroundColor: "#D3B100",
                        borderRadius: 6,
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: animatedWidth.interpolate({
                          inputRange: [0, 100],
                          outputRange: ["0%", `${volume * 100}%`],
                        }),
                      }}
                    />

                    <Slider
                      style={{
                        position: "absolute",
                        width: "100%",
                        height: 12,
                      }}
                      minimumValue={0}
                      maximumValue={1}
                      step={0.1}
                      value={volume}
                      onValueChange={handleVolumeChange}
                      minimumTrackTintColor="transparent"
                      maximumTrackTintColor="transparent"
                      thumbImage={require("../../assets/thumb-image.svg")}
                    />
                  </Animated.View>
                )}
              </Animated.View>

              {/* Progress Slider */}
              <View
                style={{
                  flex: 1,
                  position: "relative",
                  width: "100%",
                  height: 12,
                  cursor: "pointer",
                }}
              >
                <View
                  style={{
                    position: "absolute",
                    height: 12,
                    backgroundColor: "#4A5156",
                    width: "100%",
                    borderRadius: 50,
                  }}
                />

                <View
                  style={{
                    position: "absolute",
                    height: 12,
                    backgroundColor: "#D3B100",
                    width: `${trackWidth}%`,
                    borderBottomLeftRadius: 50,
                    borderTopLeftRadius: 50,
                  }}
                />

                <Slider
                  style={{ width: "100%", height: 12 }}
                  minimumValue={0}
                  maximumValue={duration}
                  step={0.1}
                  value={currentTime}
                  onValueChange={handleSliderChange}
                  minimumTrackTintColor="transparent"
                  maximumTrackTintColor="transparent"
                  thumbImage={require("../../assets/thumb-image.svg")}
                />
              </View>

              <TouchableOpacity
                onPress={handleCopyLink}
                style={{
                  padding: 4,
                }}
              >
                <Image
                  source={require("../../assets/share-video.svg")}
                  style={{ width: 24, height: 24 }}
                />
              </TouchableOpacity>

              {/* Button Fullscreen */}
              <TouchableOpacity
                onPress={toggleFullScreen}
                style={{
                  padding: 4,
                }}
              >
                <Image
                  source={
                    !isFullScreen
                      ? require("../../assets/maximize-video.svg")
                      : require("../../assets/minimize-video.svg")
                  }
                  style={{ width: 24, height: 24 }}
                />
              </TouchableOpacity>
            </View>
          </View>
        );
      };

      return <VideoPlayer source={videos} />;
    };

    const thumbnailResult = (source, index) => {
      const { title, uri } = source;
      const [modalVisible, setModalVisible] = useState(false);
      const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
      const [isModalOpen, setIsModalOpen] = useState(false);
      const [hoveredIndex, setHoveredIndex] = useState(null);

      if (!source) return null;

      const titleMapping = {
        "INVOICE URL": "Receipt.pdf",
        "AGREEMENT URL": "Agreement.pdf",
        "Client Profile Url": "Profile.pdf",
        "Rent Appraisal Link": "Rental Appraisal.pdf",
        "Signed Contract Url": "Purchase Contracts.pdf",
        "Building Report": "Building Report.pdf",
        "Pest Report": "Pest Report.pdf",
      };

      const mappedTitle = titleMapping[title] || title;

      const handleOpenModal = (event) => {
        const { pageY, pageX } = event.nativeEvent;
        setModalPosition({ top: pageY + 20, left: pageX - 110 });
        setModalVisible(true);
        setIsModalOpen(true);
      };

      const handleCloseModal = () => {
        setModalVisible(false);
        setIsModalOpen(false);
      };

      const handleActionPress = (action) => {
        handleCloseModal();
        if (action === "View") {
          Linking.openURL(uri);
        } else if (action === "Download") {
          this.downloadFileWeb("Receipt", uri);
        } else if (action === "Share") {
          this.onCopyPath(uri, "Link drive copied!");
        }
      };

      return (
        <View
          key={index}
          style={{
            padding: 12,
            backgroundColor: MAIN_COLOR.BACKGROUND_COLOR_MAIN,
            borderRadius: 6,
            marginBottom: 16,
            position: "relative",
          }}
        >
          <View
            style={{
              width: "100%",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <View style={{ flexDirection: "row", marginRight: 12 }}>
              <Image
                source={require("../../assets/pdf.svg")}
                resizeMode="contain"
                style={{
                  height: 46,
                  width: 46,
                  borderRadius: 6,
                  marginRight: 12,
                }}
              />
              <View>
                <Text
                  style={{
                    fontSize: 14,
                    color: "#FFFFFF",
                    fontFamily: MAIN_FONT.semi,
                    marginBottom: 4,
                  }}
                >
                  {mappedTitle}
                </Text>
                <Text
                  style={{
                    fontSize: 14,
                    color: MAIN_COLOR.G600,
                    fontFamily: MAIN_FONT.regular,
                  }}
                >
                  23 MB
                </Text>
              </View>
            </View>

            <View style={{ flexDirection: "row" }}>
              <TouchableOpacity
                style={{
                  flexDirection: "row",
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 77,
                  padding: 4,
                  width: 32,
                  height: 32,
                  backgroundColor: isModalOpen
                    ? "#232D35"
                    : MAIN_COLOR.BACKGROUND_COLOR_MAIN,
                }}
                onPress={handleOpenModal}
              >
                <Image
                  style={{
                    resizeMode: "contain",
                    width: 18,
                    height: 18,
                  }}
                  source={require("../../assets/three-dots.svg")}
                />
              </TouchableOpacity>
            </View>
          </View>

          <Modal
            animationType="fade"
            transparent={true}
            visible={modalVisible}
            onRequestClose={handleCloseModal}
          >
            <TouchableWithoutFeedback onPress={handleCloseModal}>
              <View
                style={{
                  flex: 1,
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <View
                  style={{
                    position: "absolute",
                    top: modalPosition.top,
                    left: modalPosition.left,
                    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR_MAIN,
                    borderRadius: 6,
                    padding: 16,
                    width: "auto",
                    height: "auto",
                    borderWidth: 1,
                    borderColor: "#34393D80",
                    shadowColor: "#000",
                    shadowOpacity: 0.1,
                    shadowOffset: {
                      width: 0,
                      height: 0,
                    },
                    shadowRadius: 12,
                  }}
                >
                  {["View", "Download", "Share"].map((action, index) => (
                    <TouchableWithoutFeedback
                      key={action}
                      onPress={() => handleActionPress(action)}
                      onPressIn={() => setHoveredIndex(index)}
                      onPressOut={() => setHoveredIndex(null)}
                    >
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          paddingTop: action === "View" ? 0 : 12,
                          paddingBottom: action === "Share" ? 0 : 12,
                          borderBottomWidth: action === "Share" ? 0 : 1,
                          borderBottomColor: "#34393D",
                          borderBottomStyle: "solid",
                          cursor: "pointer",
                        }}
                      >
                        <Image
                          style={{
                            resizeMode: "contain",
                            width: 20,
                            height: 20,
                            marginRight: 8,
                            tintColor:
                              hoveredIndex === index
                                ? "#FFFFFF"
                                : MAIN_COLOR.G600,
                          }}
                          source={
                            action === "View"
                              ? require("../../assets/eye.svg")
                              : action === "Download"
                              ? require("../../assets/receive-square.svg")
                              : require("../../assets/link-2.svg")
                          }
                        />
                        <Text
                          style={{
                            color:
                              hoveredIndex === index
                                ? "#FFFFFF"
                                : MAIN_COLOR.G600,
                          }}
                        >
                          {action}
                        </Text>
                      </View>
                    </TouchableWithoutFeedback>
                  ))}
                </View>
              </View>
            </TouchableWithoutFeedback>
          </Modal>
        </View>
      );
    };

    const renderVideoWithImage = (videoUrl, setVideoUrl, setIsModalOpen) => {
      const [thumbnail, setThumbnail] = useState(null);

      return (
        <View style={{ width: "100%", height: "100%" }}>
          <View
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
              backgroundColor: MAIN_COLOR.BACKGROUND_COLOR_MAIN,
              borderRadius: 8,
              overflow: "hidden",
            }}
          >
            <TouchableOpacity
              activeOpacity={1}
              onPress={() => {
                setVideoUrl(videoUrl);
                setIsModalOpen(true);
              }}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                width: 40,
                height: 40,
                zIndex: 100,
                transform: "translate(-50%, -50%)",
              }}
            >
              <Image
                style={{ width: 40, height: 40 }}
                source={require("../../assets/pause-video-circle.svg")}
              />
            </TouchableOpacity>
            {web(videoUrl)}
          </View>
        </View>
      );
    };

    const renderVideos = (videos, listVideos) => {
      const [videoUrl, setVideoUrl] = useState(videos[1]);
      const [isModalOpen, setIsModalOpen] = useState(false);
      const [isFullScreen, setIsFullScreen] = useState(false);

      const handleFullScreenToggle = (isFull) => {
        setIsFullScreen(isFull);
      };

      const currentVideo = listVideos.findIndex((video) => video === videoUrl);

      const handlePrevVideo = () => {
        if (currentVideo > 0) {
          const prevVideoUrl = listVideos[currentVideo - 1];
          setVideoUrl(prevVideoUrl);
          setIsModalOpen(true);
        }
      };

      const handleNextVideo = () => {
        if (currentVideo < listVideos.length - 1) {
          const nextVideoUrl = listVideos[currentVideo + 1];
          setVideoUrl(nextVideoUrl);
          setIsModalOpen(true);
        }
      };

      return (
        <Fragment>
          {!videoUrl.includes(".mp4") ? (
            <View
              activeOpacity={0.8}
              style={{
                width: "100%",
                height: "100%",
                alignSelf: "center",
                marginBottom: 20,
                borderRadius: 20,
              }}
            >
              <WebView
                androidHardwareAccelerationDisabled
                allowsFullscreenVideo
                startInLoadingState
                allowsInlineMediaPlayback
                style={{ height: 180, width: "100%", borderRadius: 20 }}
                source={{
                  html: `<iframe src=${videoUrl} frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe>`,
                }}
              />
            </View>
          ) : (
            <>
              {renderVideoWithImage(videoUrl, setVideoUrl, setIsModalOpen)}
              {isModalOpen && videoUrl && (
                <ViewGallery
                  open={isModalOpen}
                  isPagination={listVideos?.length > 0}
                  onClose={() => {
                    setIsModalOpen(false);
                    setIsFullScreen(false);
                  }}
                  isFullScreen={isFullScreen}
                  hidePrev={currentVideo === 0}
                  hideNext={currentVideo === listVideos.length - 1}
                  onPrev={handlePrevVideo}
                  onNext={handleNextVideo}
                >
                  {webVideoControl(
                    videoUrl,
                    handleFullScreenToggle,
                    isFullScreen,
                    listVideos
                  )}
                </ViewGallery>
              )}
            </>
          )}
        </Fragment>
      );
    };

    const renderBox = (item, index) => {
      return (
        <>
          {item?.explanation && (
            <View
              key={index}
              style={{
                width: "100%",
                borderRadius: 12,
                padding: 12,
                backgroundColor: MAIN_COLOR.BACKGROUND_COLOR_MAIN,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    fontFamily: MAIN_FONT.regular,
                    fontSize: 14,
                    color: "#FFFFFF",
                    lineHeight: 20,
                    textAlign: "left",
                    marginBottom: 16,
                  }}
                >
                  Note:
                </Text>
              </View>

              <>
                {!!item.description && (
                  <Text
                    style={{
                      fontFamily: MAIN_FONT.regular,
                      fontSize: 14,
                      color: "#7D888F",
                      lineHeight: 21,
                    }}
                  >
                    {item.description && item.description.toUpperCase()}
                  </Text>
                )}

                {item.explanation &&
                  item.explanation
                    .trim()
                    .split("\n")
                    .map((line, idx) => (
                      <View
                        key={idx}
                        style={{ flexDirection: "row", alignItems: "center" }}
                      >
                        <View
                          style={{
                            width: 4,
                            height: 4,
                            borderRadius: 4,
                            backgroundColor: "#7D888F",
                            marginHorizontal: 8,
                          }}
                        />
                        <Text
                          style={{
                            fontFamily: MAIN_FONT.regular,
                            fontSize: 14,
                            color: "#7D888F",
                            lineHeight: 21,
                          }}
                        >
                          {line}
                        </Text>
                      </View>
                    ))}
              </>
            </View>
          )}
        </>
      );
    };

    return (
      <View>
        <View style={styles.container}>
          <View
            style={{
              flex: 1,
              flexDirection: "column",
              gap: 16,
              alignSelf:
                task[fields.appStageName] === "Unconditional"
                  ? "auto"
                  : "flex-start",
            }}
          >
            {task[fields.appStageName] === "Building & Pest Report Results" && (
              <Update
                title={"Building & Pest Notes"}
                task={task}
                curTime={curTime}
                onCopyPath={this.onCopyPath}
              />
            )}
            <Update
              title={"Update"}
              task={task}
              time={time}
              onCopyPath={this.onCopyPath}
            />
            <Checklist
              id={id}
              task={task}
              checklists={checklists}
              clickUpStatusList={clickUpStatusList}
            />
          </View>
          <View style={styles.sidebar}>
            <ScrollView>
              <Sidebar
                time={time}
                curTime={curTime}
                files={files}
                pdfs={pdfs}
                pngs={pngs}
                task={task}
                status={status}
                clickUpStatusList={clickUpStatusList}
                id={id}
                checklists={checklists}
                walkThroughDate={walkThroughDate}
                preSettlementDate={preSettlementDate}
                settlementDate={settlementDate}
                buildingPestDate={buildingPestDate}
                financeDate={financeDate}
                solicitorName={solicitorName}
                solicitorPhone={solicitorPhone}
                solicitorEmail={solicitorEmail}
                brokerName={brokerName}
                brokerEmail={brokerEmail}
                brokerPhone={brokerPhone}
                adminStrationPhone={adminStrationPhone}
                adminStrationAvatar={adminStrationAvatar}
                buyerAgentName={buyerAgentName}
                buyerAgentEmail={buyerAgentEmail}
                buyerAgentPhone={buyerAgentPhone}
                buyerAgentAvatar={buyerAgentAvatar}
                headBuyerAgentName={headBuyerAgentName}
                headBuyerAgentEmail={headBuyerAgentEmail}
                headBuyerAgentPhone={headBuyerAgentPhone}
                headBuyerAgentAvatar={headBuyerAgentAvatar}
                bpInspectorName={bpInspectorName}
                bpInspectorPhone={bpInspectorPhone}
                bpInspectorEmail={bpInspectorEmail}
                propertyManagerName={propertyManagerName}
                propertyManagerEmail={propertyManagerEmail}
                propertyManagerPhone={propertyManagerPhone}
                quantitySurveyorPhone={quantitySurveyorPhone}
                onCopyPhone={this.onCopyPhone}
                thumbnailResult={thumbnailResult}
                adminHeadName={adminHeadName}
                adminHeadPhone={adminHeadPhone}
                adminHeadEmail={adminHeadEmail}
                adminHeadAvatar={adminHeadAvatar}
                adminSupportName={adminSupportName}
                adminSupportPhone={adminSupportPhone}
                adminSupportEmail={adminSupportEmail}
                adminSupportAvatar={adminSupportAvatar}
                headStrategyName={headStrategyName}
                headStrategyPhone={headStrategyPhone}
                headStrategyEmail={headStrategyEmail}
                headStrategyAvatar={headStrategyAvatar}
                directorOperationsName={directorOperationsName}
                directorOperationsPhone={directorOperationsPhone}
                directorOperationsEmail={directorOperationsEmail}
                directorOperationsAvatar={directorOperationsAvatar}
                exchangeManagerName={exchangeManagerName}
                exchangeManagerPhone={exchangeManagerPhone}
                exchangeManagerEmail={exchangeManagerEmail}
                exchangeManagerAvatar={exchangeManagerAvatar}
                propertyAnalystName={propertyAnalystName}
                propertyAnalystPhone={propertyAnalystPhone}
                propertyAnalystEmail={propertyAnalystEmail}
                propertyAnalystAvatar={propertyAnalystAvatar}
              />
            </ScrollView>
          </View>
        </View>

        <Videos
          task={task}
          videos={videos}
          links={links}
          renderVideos={renderVideos}
        />

        <Note
          task={task}
          propertyConditionNotes={propertyConditionNotes}
          renderBox={renderBox}
        />

        {booking ? (
          <BookACall task={task} booking={booking} assignee={assignee} />
        ) : null}

        <Toast config={toastConfig} />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return {
    COLORS: state.COLORS,
    USER: state.USER,
    CART: state.CART,
    TIA: state.TIA,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUserInfo: (state, action) => dispatch(setUser(state, action)),
    setUserToken: (state, action) => dispatch(setUser(state, action)),
    setActiveSupplier: (id, host) => dispatch(setActiveSupplier(id, host)),
    setColorsToRedux: (data) => dispatch(setColors(data)),
    setActiveSupplierInfo: (data) => dispatch(setActiveSupplierInfo(data)),
    setSupplierList: (list) => dispatch(setSupplierList(list)),
    setCartInfo: (id, total) => dispatch(setCartInfo(id, total)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
