import { orderBy } from "lodash";
import { useSelector } from "react-redux";
import React, { memo, useState, useEffect } from "react";
import { View, Text, ActivityIndicator } from "react-native";

//Functions
import { MAIN_COLORS } from "../../../Utility/Colors";
import { getContactByEmail } from "../../../Clickup/api";
import { FONT_SIZE, FONT_WEIGHT, MAIN_FONT } from "../../../Utility/Fonts/font";

//Components
import ContactItem from "../../../Utility/ComponentCard/ContactCard/ContactItem";

const PropertyContacts = ({ portfolio }) => {
  const { opportunities, teamTIAData } = useSelector((state) => state.TIA);

  const [loading, setLoading] = useState(false);
  const [contacts, setContacts] = useState([]);

  const getContactQuantitySurvey = async () => {
    const result = await getContactByEmail("ayden.hassan@mcgqs.com.au");
    const tasks = result?.tasks;
    if (tasks.length) {
      const quantitySurveyorPhone = tasks[0].custom_fields.find(
        (field) => field.name === "Phone"
      )?.value;
      return {
        type: "Quantity Surveyor",
        name: "Ayden Hassan",
        phone: quantitySurveyorPhone,
        email: "ayden.hassan@mcgqs.com.au",
        order: 8,
      };
    }
  };

  const fetchData = async () => {
    const item = opportunities.find(
      (one) => one?.["Proposed Property"]?.[0]?.id === portfolio.id
    );

    if (item) {
      const manager = {
        type: "Property Manager",
        name: item["Property Manager"],
        phone: item["Property Manager Phone"],
        email: item["Property Manager Email"],
        order: 4,
      };
      const legalPartner = {
        type: "Solicitor",
        name: item["SOLICITOR'S NAME"],
        phone: item["SOLICITOR'S PHONE"],
        email: item["Solicitor's Email"],
        order: 5,
      };
      const financialPartner = {
        type: "Mortgage Broker",
        name: item["MORTGAGE BROKER"],
        phone: item["MORTGAGE BROKER PHONE"],
        email: item["MORTGAGE BROKER EMAIL"],
        order: 6,
      };
      const inspector = {
        type: "Building and Pest Inspector",
        name: item["B&P Inspector"],
        phone: item["B&P Inspector Phone"],
        email: item["B&P Inspector Email"],
        order: 7,
      };

      const promises = [getContactQuantitySurvey()];

      setLoading(true);
      await Promise.all(promises).then((result) => {
        const partners = [manager, legalPartner, financialPartner, inspector, ...result];
        const contacts = partners.filter((one) => one?.name && one?.email && one?.phone);
        setContacts(contacts);
      });
      setLoading(false);
    } else {
      setContacts([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [portfolio]);

  const item = opportunities.find(
    (one) => one?.["Proposed Property"]?.[0]?.id === portfolio.id
  );

  const filteredTeamTIAData = teamTIAData
    .filter((member) => member.name !== "Darren Venter")
    .filter((member) => member.type !== "Director & Head of Growth")
    .filter((member) => member.type !== "Property Analyst");

  const assignee = teamTIAData.find(
    (member) =>
      member.name === item?.assignees?.[0]?.username &&
      member.email === item?.assignees?.[0]?.email &&
      member.type === "Property Analyst"
  );

  if (assignee) {
    filteredTeamTIAData.push(assignee);
  }

  const dataToRender = [...filteredTeamTIAData, ...contacts];

  return (
    <>
      {loading ? (
        <ActivityIndicator size="large" color={MAIN_COLORS.PRIMARY_COLOR} />
      ) : (
        <>
          <Text
            style={{
              fontWeight: FONT_WEIGHT.bold,
              fontSize: FONT_SIZE.large,
              lightHeight: 27,
              color: MAIN_COLORS.TEXT_LIGHT,
              fontFamily: MAIN_FONT.semi,
            }}
          >
            Contacts
          </Text>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 16,
              flexWrap: "wrap",
            }}
          >
            {dataToRender.map((contact, index) => (
              <ContactItem
                key={index}
                style={{
                  flexDirection: "row",
                  borderRadius: 10,
                  padding: 12,
                  gap: 8,
                  backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
                  width: "calc(50% - 8px)",
                }}
                item={contact}
              />
            ))}
          </View>
        </>
      )}
    </>
  );
};

export default memo(PropertyContacts);
