import { isEmpty, sortBy } from "lodash";
import React, { useEffect, useState } from "react";
import {
  Platform,
  SafeAreaView,
  View,
  Text,
  StyleSheet,
  ScrollView,
} from "react-native";
import Toast from "react-native-toast-message";
import { useDispatch, useSelector } from "react-redux";
import {
  getDetailClient,
  getDetailsByScenarioId,
  getListRoadmapPartner,
  getListScenarios,
  getProperty,
  getProfileAccount,
  getAllPropertyIdByOpportunity,
} from "../../Clickup/api";
import {
  setListPurchaseTarget,
  setLoading,
  setPurchasedProperties,
  setScenarioSelected,
} from "../../Reducer/portfolio-roadmap";
import { setClient } from "../../Reducer/tia";
import { MAIN_COLORS } from "../../Utility/Colors";
import { MAIN_FONT, FONT_WEIGHT } from "../../Utility/Fonts/font";
import Loader from "../../Utility/ComponentCard/Loader";
import { monthDiff, ORIGIN } from "../../Utility/common";
import { toastConfig } from "../../configs/toastConfig";
import { ROLES, usePermission } from "../../hooks/usePermission";
import { fetchPropertyData } from "../../Utility/common";

import Header from "./Header/indexv2";
import PurchaseOverview from "./PurchaseOverview/indexv2";
import AddPartner from "./PurchaseOverview/AddPartner/indexv2";
import SaveScenario from "./PurchaseOverview/SaveScenario/indexv2";
import ButtonExpand from "./components/ButtonExpand";
import EmptyState from "./components/EmptyState";
import AccessDenied from "./components/AccessDenied";
import MainLayout from "../../Layout/Main";
import FinancePlanner from "./Header/FinancePlanner/indexv2";
import ListPurchases from "./Menu";

const PortfolioRoadmap = () => {
  const dispatch = useDispatch();
  const { opportunities, client, role } = useSelector((state) => state.TIA);
  const { loading, scenarioSelected } = useSelector(
    (state) => state.PORTFOLIO_ROADMAP
  );

  const { isView: viewAddPartner } = usePermission("addPartner");
  const { isView: viewSaveScenario } = usePermission("saveScenario");
  const { isView: viewFinancePlanner } = usePermission("financePlanner");
  const [listScenarios, setListScenarios] = useState({});
  const [listPurchases, setListPurchases] = useState([]);
  const [accessDenied, setAccessDenied] = useState(false);
  const [listRoadmapPartner, setListRoadmapPartner] = useState([]);
  const [isEmptyScreen, setIsEmptyScreen] = useState(!!scenarioSelected);
  const [showPopupAddPartner, setShowPopupAddPartner] = useState(false);
  const [showPopupAddScenario, setShowPopupAddScenario] = useState(false);
  const [disabledClickOutside, setDisabledClickOutside] = useState(false);
  const [purchaseSelected, setPurchaseSelected] = useState(null);

  const fetchListScenario = async () => {
    try {
      if (!client.client_id) return;
      const params = new URLSearchParams(window.location.search);
      const isClient = role?.toLowerCase() === ROLES.CLIENT;

      let scenarioId = params.get("scenario_id");
      let scenarioType = "scenarios";

      dispatch(setLoading(true));
      const list_scenario = await getListScenarios(client.client_id, role);
      if (!isEmpty(list_scenario.data)) {
        const scenarios = list_scenario.data?.scenarios;
        const shared_scenarios = list_scenario.data?.shared_scenarios;

        let scenario;
        if (scenarios?.length && isClient) {
          scenarioId = scenarioId ? scenarioId : scenarios[0]?.id;
          scenario = await getDetailsByScenarioId(scenarioId, client.client_id);
        } else if (shared_scenarios?.length) {
          scenarioId = scenarioId ? scenarioId : shared_scenarios[0]?.id;
          scenario = await getDetailsByScenarioId(scenarioId, client.client_id);

          if (shared_scenarios.some((item) => item.id === scenario.id)) {
            scenarioType = "share_scenarios";
          }
        }

        if (scenario && scenario?.status === false) {
          throw new Error("You do not have permission to view this scenario");
        }

        params.set("scenario_id", scenario.id);
        window.history.replaceState(
          {},
          "",
          `${ORIGIN}/scenario-detail?${params}`
        );

        setListScenarios({ scenarios, shared_scenarios });
        dispatch(setScenarioSelected({ type: scenarioType, ...scenario }));
      } else {
        setIsEmptyScreen(true);
      }
    } catch (error) {
      dispatch(setScenarioSelected(null));
      setAccessDenied(true);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const fetchListRoadmapPartner = async () => {
    try {
      dispatch(setLoading(true));
      const response = await getListRoadmapPartner(client.client_id, role);
      setListRoadmapPartner(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const fetchAllPropertyIdByOpportunity = async () => {
    try {
      if (client) {
        const opportunityIds = client.custom_fields
          ?.find((feild) => feild.name === "Opportunities")
          ?.value.map((item) => item.id);

        const result = await getAllPropertyIdByOpportunity(
          opportunityIds,
          true
        );
        if (result.status === 200 && result.data.length)
          return result.data?.map((property) => ({
            ...(property?.custom_fields || [])?.reduce(
              (fields, field) => ({
                ...fields,
                [field.name]: field.value,
              }),
              {}
            ),
            id: property.id,
          }));

        return [];
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    const getInfoClient = async () => {
      const infoUser = await getProfileAccount(client?.id);
      if (infoUser.status === 200) {
        const { user_info, ...passFeild } = infoUser.data;
        dispatch(setClient({ ...passFeild, ...user_info }));
      }
    };
    if(role === "Partner") getInfoClient();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const propertiesOfOpportunity = await fetchAllPropertyIdByOpportunity();

      const propertyIds = client.custom_fields
        ?.find((one) => one.name === "Properties")
        ?.value?.map((item) => item.id);

      const propertyDataPromises = (propertyIds || []).map(fetchPropertyData);

      Promise.all(propertyDataPromises)
        .then((propertyData) => {
          const validPropertyData = [
            ...(propertyData?.filter(Boolean)),
            ...propertiesOfOpportunity,
          ];
          const listPurchase = sortBy(
            validPropertyData
              ?.map((one) => {
                if (
                  !!one?.["Purchase date "] &&
                  !!one?.["CURRENT RENTAL RATE P/W $"]
                ) {
                  const isNonTIA = !!one["Purchased By"];
                  return {
                    rentPW: Number(one["CURRENT RENTAL RATE P/W $"]),
                    interestPerMonth: one["Interest Only Loan At"],
                    propertyPrice: Number(one["Purchase Price"]),
                    date: Number(one["Purchase date "]),
                    recommendedBorrowing: Number(
                      (isNonTIA ? one["Debt remaining"] : one["Loan Amount"]) ||
                        0
                    ),
                    availableEquity: 0,
                    isNonTIA,
                    deposit: one["Deposit Amount"],
                    interestRate: Number(one["Interest Only Loan Percent"]),
                  };
                }
              })
              .filter(Boolean),
            "date"
          );

          if (listPurchase) {
            const newListPurchase = listPurchase.map((item, index) => {
              return {
                ...item,
                noOfMonth: monthDiff(
                  new Date(item.date),
                  new Date(
                    index !== listPurchase.length - 1
                      ? listPurchase[index + 1].date
                      : new Date()
                  )
                ),
              };
            });
            setListPurchases(newListPurchase);
          }
          dispatch(setPurchasedProperties(validPropertyData));
        })
        .catch((error) => {
          console.log(error);
        });
    };

    fetch();
    fetchListScenario();
    if (viewAddPartner) fetchListRoadmapPartner();
  }, [opportunities, client]);

  useEffect(() => {
    if (scenarioSelected) {
      setIsEmptyScreen(false);
      dispatch(setListPurchaseTarget(scenarioSelected?.purchaseTargets));
    }
  }, [scenarioSelected]);

  return (
    <>
      <MainLayout
        childrenHeader={
          <View style={styles.header}>
            <Text style={styles.title}>Roadmap</Text>
            <View style={styles.headerRight}>
              <View style={styles.toastPosition}>
                <Toast config={toastConfig} />
              </View>
              {viewSaveScenario && (
                <View style={{ position: "absolute", top: -23, right: 298 }}>
                  <div id="btn_modal_save_scenario">
                    <ButtonExpand
                      title="Save Scenario"
                      showRightIc
                      showPopup={showPopupAddScenario}
                      setShowPopup={setShowPopupAddScenario}
                      disabledClickOutside={disabledClickOutside}
                      leftIcon={require("../../assets/portfolio-roadmap/archive-add.svg")}
                    >
                      <SaveScenario
                        setShowPopup={setShowPopupAddScenario}
                        userId={client.client_id}
                        listScenarios={listScenarios}
                        fetchData={fetchListScenario}
                        setDisabledClickOutside={setDisabledClickOutside}
                        setAccessDenied={setAccessDenied}
                      />
                    </ButtonExpand>
                  </div>
                </View>
              )}
              {viewAddPartner && (
                <View style={{ position: "absolute", top: -23 }}>
                  <ButtonExpand
                    showPopup={showPopupAddPartner}
                    setShowPopup={setShowPopupAddPartner}
                    title="Add partners to view"
                    showRightIc
                    disabledClickOutside={disabledClickOutside}
                    leftIcon={require("../../assets/portfolio-roadmap/user-cirlce-add.svg")}
                  >
                    <AddPartner
                      setDisabledClickOutside={setDisabledClickOutside}
                      setShowPopup={setShowPopupAddPartner}
                      listRoadmapPartner={listRoadmapPartner}
                      setListRoadmapPartner={setListRoadmapPartner}
                    />
                  </ButtonExpand>
                </View>
              )}
            </View>
          </View>
        }
        childrenMenu={
          <View style={{ gap: 22 }}>
            {viewFinancePlanner && (
              <FinancePlanner listPurchases={listPurchases} />
            )}
            <ListPurchases
              setPurchaseSelected={setPurchaseSelected}
              purchaseSelected={purchaseSelected}
            />
          </View>
        }
      >
        <SafeAreaView
          style={{ flex: 1, backgroundColor: MAIN_COLORS.BACKGROUND_BLACK }}
          keyboardShouldPersistTaps={true}
          behavior="padding"
          keyboardVerticalOffset={Platform.OS === "ios" ? -200 : 900}
        >
          {accessDenied ? (
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AccessDenied />
            </View>
          ) : (
            <ScrollView
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={false}
              style={styles.content}
              contentContainerStyle={{
                gap: 22,
                paddingBottom: 75,
                height: "100%",
              }}
            >
              <Header listPurchases={listPurchases} />
              {isEmptyScreen ? (
                <EmptyState />
              ) : (
                <PurchaseOverview
                  listPurchases={listPurchases}
                  purchaseSelected={purchaseSelected}
                  setPurchaseSelected={setPurchaseSelected}
                />
              )}
            </ScrollView>
          )}
        </SafeAreaView>
      </MainLayout>
      {loading && <Loader />}
    </>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  title: {
    fontFamily: MAIN_FONT.semi,
    fontSize: 20,
    fontWeight: FONT_WEIGHT.bold,
    color: MAIN_COLORS.TEXT_LIGHT,
    lineHeight: 30,
  },

  headerRight: {
    flexDirection: "row",
    justifyContent: "flex-end",
    gap: 16,
  },

  toastPosition: {
    position: "fixed",
    right: 120,
    top: 0,
    width: 150,
    zIndex: 9999,
  },

  content: {
    flex: 1,
    paddingHorizontal: 32,
    marginVertical: 22,
    display: "flex",
    gap: 22,
  },
});

export default PortfolioRoadmap;
