import {
  NOTIFICATIONS,
  OPPORTUNITIES,
  PROPERTIES,
  CLIENT,
  TEMPLATE,
  DETAILS,
  SET_TEAM_TIA_DATA,
} from "../Utility/ReduxType";
import { setDataToAsyncStorage } from "../Action/asyncCtrl";

export const setOpportunities = (content) => ({
  type: OPPORTUNITIES.SET_OPPORTUNITIES,
  payload: content,
});

export const setLoader = (content) => ({
  type: "LOADER",
  payload: content,
});

export const setEquity = (content) => ({
  type: "EQUITY",
  payload: content,
});

export const setNotifications = (content) => ({
  type: NOTIFICATIONS.SET_NOTIFICATIONS,
  payload: content,
});

export const setNotificationsObject = (content) => ({
  type: NOTIFICATIONS.SET_NOTIFICATIONS_OBJECT,
  payload: content,
});

export const setProperties = (content) => ({
  type: PROPERTIES.SET_PROPERTIES,
  payload: content,
});

export const setClient = (content) => ({
  type: CLIENT.SET_CLIENT,
  payload: content,
});

export const reset = (content) => ({
  type: "RESET",
  payload: content,
});

export const setTemplate = (content) => ({
  type: TEMPLATE.SET_TEMPLATE,
  payload: content,
});

export const setOpenDetails = (content) => ({
  type: DETAILS.SET_OPEN,
  payload: content,
});

export const setCurrentScreen = (content) => ({
  type: "SCREEN",
  payload: content,
});

export const setTeamTIAData = (content) => ({
  type: SET_TEAM_TIA_DATA,
  payload: content,
});

export const setDeal = (content) => ({
  type: "SET_DEAL",
  payload: content,
});

export const setPropertyIds = (content) => ({
  type: "SET_PROPERTY_IDS",
  payload: content,
});

export const setChecklist = (content) => ({
  type: "SET_CHECKLIST",
  payload: content,
});

export const setClickUpStatusList = (content) => ({
  type: "SET_CLICK_UP_STATUS_LIST",
  payload: content,
});

const initialState = {
  checklists: [],
  clickUpStatusList:[],
  opportunities: [],
  notifications: [],
  properties: [],
  client: [],
  notificationObjects: {},
  template: {},
  loader: false,
  equity: 0,
  detailsOpen: "",
  role: "Client",
  currentScreen: "Home",
  teamTIAData: [],
  dealSelect: null,
};

export const tia = (state = initialState, action) => {
  const {
    notifications,
    opportunities,
    properties,
    notificationObjects,
    template,
    loader,
  } = state;

  switch (action.type) {
    case CLIENT.SET_CLIENT: {
      return {
        ...state,
        client: action.payload,
        role: action.payload.role === "partner" ? "Partner" : "Client",
      };
      // return Object.assign({}, state, {client: action.payload});
    }

    case NOTIFICATIONS.SET_NOTIFICATIONS: {
      // alert('Notifications: '+JSON.stringify(state));
      return Object.assign({}, state, { notifications: action.payload });
    }

    case NOTIFICATIONS.SET_NOTIFICATIONS_OBJECT: {
      return Object.assign({}, state, {
        notificationObjects: {
          ...notificationObjects,
          [action.payload.id]: action.payload.data,
        },
      });
    }

    case OPPORTUNITIES.SET_OPPORTUNITIES: {
      const opportunityIds = state.opportunities.map((opp) => opp.id);
      const opportunityToAdd = action.payload
        .map((opp) => (!opportunityIds.includes(opp.id) ? opp : null))
        .filter(Boolean);

      return {
        ...state,
        opportunities: [...state.opportunities, ...opportunityToAdd],
      };
    }

    case "LOADER": {
      return Object.assign({}, state, { loader: action.payload });
    }

    case "EQUITY": {
      return Object.assign({}, state, { equity: action.payload });
    }

    case TEMPLATE.SET_TEMPLATE: {
      return Object.assign({}, state, { template: action.payload });
    }

    case "RESET": {
      return Object.assign({}, state, {
        opportunities: [],
        notifications: [],
        properties: [],
        client: [],
        notificationObjects: {},
        teamTIAData: [],
        dealSelect: null,
        currentScreen: "Home",
      });
    }

    case PROPERTIES.SET_PROPERTIES: {
      return {
        type: action.type,
        payload: action.payload,
        ...state,
        properties: action.payload,
      };
    }

    case DETAILS.SET_OPEN: {
      return {
        ...state,
        detailsOpen: action.payload,
      };
    }

    case "SCREEN": {
      return {
        ...state,
        currentScreen: action.payload,
      };
    }

    case SET_TEAM_TIA_DATA: {
      return {
        ...state,
        teamTIAData: action.payload,
      };
    }

    case "SET_DEAL": {
      return {
        ...state,
        dealSelect: action.payload,
      };
    }

    case "SET_CHECKLIST": {
      const checklistIds = state.checklists.map((list) => list.id);

      const checklistToAdd = action.payload
        .map((list) => (!checklistIds.includes(list.id) ? list : null))
        .filter(Boolean);

      return {
        ...state,
        checklists: [...state.checklists, ...checklistToAdd],
      };
    }

    case "SET_CLICK_UP_STATUS_LIST": {
      const clickUpStatusListIds = state.clickUpStatusList.map((list) => list.taskId);      

      const clickUpStatusListToAdd = action.payload
        .map((list) => (!clickUpStatusListIds.includes(list.taskId) ? list : null))
        .filter(Boolean);

      return {
        ...state,
        clickUpStatusList: [...state.clickUpStatusList, ...clickUpStatusListToAdd],
      };
    }

    default:
      return state;
  }
};
