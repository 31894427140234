import React from "react";
import lodash from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Controller, useForm } from "react-hook-form";
import {
  Text,
  View,
  Image,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
} from "react-native";

//Functions
import { MAIN_COLORS } from "../../Utility/Colors";
import { toastConfig } from "../../configs/toastConfig";
import { setEquity, setClient } from "../../Reducer/tia";
import { formatDollar } from "../../Utility/common";
import { FONT_SIZE, FONT_WEIGHT, MAIN_FONT } from "../../Utility/Fonts/font";
import {
  removeProperty,
  removeCustomFeildValue,
  getTotalPotentialEquity,
  getPropertyIds,
} from "../../Clickup/api";

//Components
import Modal from "../../components/Modal";
import MainLayout from "../../Layout/Main";
import SearchProperty from "./SearchProperty";
import Toast from "react-native-toast-message";
import PortfolioItem from "./PortfolioItem/index.v2";
import LOADER from "../../Utility/ComponentCard/Loader";
import EmptyContent from "../../components/EmptyContent";
import PropertyCard from "../../Utility/ComponentCard/PropertyCard";
import SearchSuggestCard from "../../Utility/ComponentCard/SearchSuggestCard";
import TextInput from "../../components/TextInput";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      properties: [],
      equity: 0,
      total: 0,
      refreshing: false,
      isLoading: false,
      currentComponent: "index",
      listNonTIAProperties: [],
      visibleRemoveProperty: false,
      propertyRemove: {},
      item: null,
      index: null,
      totalPotentialValue: 0,
      selectedPropertyId: null,
      notiAfterSave: false,
      suggestQuery: "",
      suggestResult: "",
      searchQuery: "",
      searchResults: 0,
      isFocused: false,
      extendedProperties: [],
      filteredSuggestProperties: [],
      filteredSuggestResultProperties: [],
      filteredResultProperties: [],
      filteredCount: 6,
      propertiesCount: 6,
      nonTIAPropertiesCount: 6,
    };
    this.debouncedSuggestProperty = lodash.debounce(
      this.suggestProperty.bind(this),
      500
    );
  }

  componentWillUnmount() {}

  componentDidUpdate(prevProps) {
    const currentValue = this.props.TIA.client.custom_fields?.find(
      (item) => item.name === "Properties"
    )?.value;
    const oldValue = prevProps.TIA.client.custom_fields?.find(
      (item) => item.name === "Properties"
    )?.value;
    if (currentValue?.length > oldValue?.length) {
      this.getListPropertyNonTIA(this.props.TIA.client);
    }
  }

  async fetchTotalPotentialEquity(list_id) {
    try {
      const totalPotentialEquity = await getTotalPotentialEquity(list_id);
      if (totalPotentialEquity?.err) return 0;
      return totalPotentialEquity?.data || 0;
    } catch (error) {
      return null;
    }
  }

  async getListPropertyNonTIA(newClient) {
    const { client } = this.props.TIA;
    const data = newClient || client;

    const propertyIds =
      data?.custom_fields
        ?.find((one) => one.name === "Properties")
        ?.value?.filter((item) => item.id)
        .map((item) => item.id) || [];

    const opportunityIds =
      client.custom_fields
        ?.find((field) => field.name === "Opportunities")
        ?.value?.filter(
          (item) => item.id && !["2nbu48z", "862kfuchw"].includes(item.id)
        )
        .map((item) => item.id) || [];

    const listPropertyIds = [...(propertyIds || []), ...(opportunityIds || [])];

    if (!listPropertyIds.length) return;
    const totalPotentialEquity = await this.fetchTotalPotentialEquity(
      listPropertyIds
    );
    this.setState({ totalPotentialValue: totalPotentialEquity });

    const oppsIdString = opportunityIds.join(",");
    const propertiesIdString = propertyIds.join(",");

    try {
      this.setState({ isLoading: true });
      const response = await getPropertyIds(oppsIdString, propertiesIdString);
      if (response.status !== 200) {
        console.error("Failed to fetch property data");
        return;
      }

      const propertyData = response?.data?.map((propertyItem) => {
        const customFields = propertyItem?.custom_fields || [];
        const fieldData = customFields.reduce(
          (fields, field) => ({
            ...fields,
            [field.name]: field.value,
          }),
          {}
        );
        return {
          ...propertyItem,
          ...fieldData,
        };
      });

      const listNonTIAProperty = [];
      const listTIAProperty = [];

      propertyData.forEach((item) => {
        if (item) {
          item["Purchased By"]
            ? listNonTIAProperty.push(item)
            : listTIAProperty.push(item);
        }
      });

      const mappedNonTIAProperty = listNonTIAProperty.map((object) => ({
        object: object,
        purchase_by: object["Purchased By"],
        image: object["Image IDs"]
          ? `https://insights.proptrack.com/imagery/1000x915/${
              object["Image IDs"].split(",")[0].split(".")[0]
            }/image.jpg`
          : object["PROPERTY IMAGE URL"],
        purchase_date: object["Purchase date "],
        sold_price: object["Comparable Property Purchase Price"],
        url: object["Comparable Property URL"],
        beds: object["NO. OF BEDROOMS"] || object["Comparable Property Bed"],
        cars: object["# OF PARKING"] || object["Comparable Property Car"],
        baths: object["NO. OF BATHROOMS"] || object["Comparable Property Bath"],
        area: object["AREA (M2)"] || object["Comparable Property Land Size"],
        price: object["Purchase Price"],
        growth: object["Growth (%)"],
        current: object["Current Potential Value"],
        date_updated: object["date_updated"],
        Suburb:
          object["PROPERTY STREET ADDRESS"] + ", " + object["PROPERTY SUBURB"],
        id: object.id,
        state: object["PROPERTY STATE"],
        core_suburb: object["PROPERTY SUBURB"],
        net_cash_flow: object["Annual Net Cashflow"],
        gross_cash_flow: object["Annual Gross Income"],
        rent_per_week: object["CURRENT RENTAL RATE P/W $"],
        gross_yield: object["Annual Gross Yield"],
        net_yeild: object["Annual Net Yield %"],
        current_potential_value: object["Current Potential Value (Old)"]
          ? Number(object["Current Potential Value (Old)"])
          : null,
        post_code: object["PROPERTY POSTCODE"],
        imported_properties: true,
      }));

      const mappedTIAProperty = listTIAProperty.map((object) => ({
        object: object,
        purchase_by: object["Purchased By"],
        image: object["Image IDs"]
          ? `https://insights.proptrack.com/imagery/1000x915/${
              object["Image IDs"].split(",")[0].split(".")[0]
            }/image.jpg`
          : object["PROPERTY IMAGE URL"],
        purchase_date: object["Purchase date "],
        sold_price: object["Comparable Property Purchase Price"],
        url: object["Comparable Property URL"],
        beds: object["NO. OF BEDROOMS"] || object["Comparable Property Bed"],
        cars: object["# OF PARKING"] || object["Comparable Property Car"],
        baths: object["NO. OF BATHROOMS"] || object["Comparable Property Bath"],
        area: object["AREA (M2)"] || object["Comparable Property Land Size"],
        price: object["Purchase Price"],
        growth: object["Growth (%)"],
        current: object["Current Potential Value"],
        date_updated: object["date_updated"],
        Suburb:
          object["PROPERTY STREET ADDRESS"] + ", " + object["PROPERTY SUBURB"],
        id: object.id,
        state: object["PROPERTY STATE"],
        core_suburb: object["PROPERTY SUBURB"],
        net_cash_flow: object["Annual Net Cashflow"],
        gross_cash_flow: object["Annual Gross Income"],
        rent_per_week: object["CURRENT RENTAL RATE P/W $"],
        gross_yield: object["Annual Gross Yield"],
        net_yeild: object["Annual Net Yield %"],
        current_potential_value: object["Current Potential Value (Old)"]
          ? Number(object["Current Potential Value (Old)"])
          : null,
        post_code: object["PROPERTY POSTCODE"],
        imported_properties: false,
      }));

      const extendedProperties = [
        ...mappedNonTIAProperty,
        ...mappedTIAProperty,
      ];

      this.setState({
        listNonTIAProperties: mappedNonTIAProperty,
        properties: mappedTIAProperty,
        extendedProperties,
      });
    } catch (error) {
      console.error("An error occurred while fetching property data:", error);
      this.setState({ error: true });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  setLoading = (value) => {
    this.setState({ isLoading: value });
  };

  handleSelectProperty = (propertyId) => {
    this.setState({ selectedPropertyId: propertyId });
  };

  setNotiAfterSave = (value) => {
    this.setState({ notiAfterSave: value });
  };

  componentDidMount() {
    const client = this.props.TIA.client
    this.getListPropertyNonTIA(client);
  }

  suggestProperty(query) {
    if (!query) return;

    const { extendedProperties } = this.state;
    const queryParts = query
      .split(",")
      .map((part) => part.trim().toLowerCase());

    const filteredSuggestProperties = extendedProperties.filter((property) => {
      const suburbMatch = queryParts.some((part) =>
        property?.Suburb?.toLowerCase().includes(part)
      );
      const stateMatch = queryParts.some((part) =>
        property?.state?.toLowerCase().includes(part)
      );
      const postCodeMatch = queryParts.some((part) =>
        property?.post_code?.toLowerCase().includes(part)
      );

      return suburbMatch || stateMatch || postCodeMatch;
    });

    this.setState({ filteredSuggestProperties });

    console.log(`Search suggest for "${query}":`, filteredSuggestProperties);
  }

  clearSearch = () => {
    this.setState({
      searchQuery: "",
      suggestQuery: "",
      suggestResult: "",
      filteredSuggestProperties: [],
      filteredResultProperties: [],
      filteredSuggestResultProperties: [],
      searchResults: 0,
    });
  };

  handleInputChange = (query) => {
    const formattedQuery = query.replace(/\s+/g, " ");

    this.setState({ suggestQuery: formattedQuery });

    if (!formattedQuery) {
      this.setState({
        suggestQuery: "",
      });
      this.clearSearch();
      this.debouncedSuggestProperty.cancel();
    } else {
      this.debouncedSuggestProperty(formattedQuery);
    }
  };

  handleSearch = () => {
    const { suggestQuery, extendedProperties } = this.state;

    if (suggestQuery) {
      const queryParts = suggestQuery
        .split(",")
        .map((part) => part.trim().toLowerCase());

      const filteredResultProperties = extendedProperties.filter((property) => {
        const suburbMatch = queryParts.some((part) =>
          property?.Suburb?.toLowerCase().includes(part)
        );
        const stateMatch = queryParts.some((part) =>
          property?.state?.toLowerCase().includes(part)
        );
        const postCodeMatch = queryParts.some((part) =>
          property?.post_code?.toLowerCase().includes(part)
        );

        return suburbMatch || stateMatch || postCodeMatch;
      });

      this.setState({
        searchQuery: suggestQuery,
        searchResults: filteredResultProperties.length,
        filteredResultProperties,
        filteredSuggestProperties: [],
        filteredSuggestResultProperties: [],
      });

      console.log(
        "Search results for:",
        suggestQuery,
        filteredResultProperties
      );
    }
  };

  handleFocus = () => {
    this.setState({ isFocused: true });
  };

  handleBlur = () => {
    this.setState({ isFocused: false });
  };

  handleViewMore = (type) => {
    const INCREMENT = 6;
    this.setState((prevState) => ({
      [type]: prevState[type] + INCREMENT,
    }));
  };

  render() {
    const {
      properties,
      equity,
      total,
      refreshing,
      item: itemSelected,
      index,
      isLoading,
      listNonTIAProperties,
      visibleRemoveProperty,
      propertyRemove,
      currentComponent,
      totalPotentialValue,
      selectedPropertyId,
      notiAfterSave,
      suggestQuery,
      suggestResult,
      searchQuery,
      searchResults,
      isFocused,
      extendedProperties,
      filteredSuggestProperties,
      filteredResultProperties,
      filteredSuggestResultProperties,
      filteredCount,
      propertiesCount,
      nonTIAPropertiesCount,
    } = this.state;

    const matchingOpportunity = this.props.TIA.opportunities.find(
      (opportunity) =>
        (itemSelected?.object?.Opportunities || []).some(
          (item) => item.id === opportunity.id
        )
    );

    const handleRemoveProperty = async () => {
      try {
        const { client } = this.props.TIA;

        this.setState({ isLoading: true });
        this.setState({ visibleRemoveProperty: false });
        const { id: field_id, value } = client?.custom_fields.find(
          (item) => item.name === "Properties"
        );
        const { team_id } = value.find((item) => item.id === propertyRemove.id);

        const result = await removeCustomFeildValue(
          propertyRemove.id,
          field_id,
          team_id
        );
        await removeProperty(propertyRemove.id, team_id);
        if (result?.err) {
          Toast.show({
            type: "error",
            text1: "Delete failed!",
            position: "top",
            visibilityTime: 3000,
          });
        } else {
          const newClient = {
            ...client,
            ["custom_fields"]: lodash.map(client["custom_fields"], (field) => {
              if (field.name === "Properties") {
                return {
                  ...field,
                  ["value"]: lodash.filter(
                    field["value"],
                    (item) => item.id !== propertyRemove.id
                  ),
                };
              }
              return field;
            }),
          };
          this.props.setClient(newClient);
          await this.getListPropertyNonTIA(newClient);
          Toast.show({
            type: "success",
            text1: "Delete successfully!",
            position: "top",
            visibilityTime: 3000,
          });
        }
      } finally {
        this.setState({ isLoading: false });
      }
    };

    return (
      <>
        <MainLayout
          childrenHeader={
            <View style={{ justifyContent: "center", height: "100%" }}>
              {currentComponent === "SearchProperty" ? (
                <View
                  style={[
                    styles.rowCenterBetween,
                    { justifyContent: "flex-start", gap: 16 },
                  ]}>
                  <TouchableOpacity
                    onPress={() =>
                      this.setState({
                        currentComponent: "index",
                        index: null,
                      })
                    }>
                    <Image
                      style={{ width: 30, height: 30 }}
                      source={require("../../assets/home/back.svg")}
                    />
                  </TouchableOpacity>
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Text style={styles.headerTitle}>Portfolio </Text>
                    <Image
                      style={{
                        width: 30,
                        height: 30,
                        transform: [{ rotate: "180deg" }],
                      }}
                      source={require("../../assets/home/back.svg")}
                    />
                    <Text style={[styles.headerTitle, { color: "#D3B100" }]}>
                      Add Property
                    </Text>
                  </View>
                </View>
              ) : (
                <Text style={styles.headerTitle}>Portfolio</Text>
              )}
            </View>
          }
          childrenMenu={
            <View
              style={{
                display: "flex",
                gap: 16,
                position: "relative",
                height: "100%",
              }}>
              <View style={styles.containerMenu}>
                <View style={styles.rowCenterBetween}>
                  <Text style={styles.text}>Potential equity:</Text>
                  <Text
                    style={[
                      styles.menuTitle,
                      { color: MAIN_COLORS.PRIMARY_COLOR },
                    ]}>
                    {formatDollar(totalPotentialValue)}
                  </Text>
                </View>
                <View style={styles.rowCenterBetween}>
                  <TouchableOpacity
                    style={styles.btnAddProperty}
                    onPress={() =>
                      this.setState({
                        currentComponent: "SearchProperty",
                        index: null,
                      })
                    }>
                    <Image
                      style={{ width: 18, height: 18 }}
                      source={require("../../assets/home/add-circle.svg")}
                    />
                    <Text
                      style={[
                        styles.text,
                        { color: MAIN_COLORS.PRIMARY_COLOR },
                      ]}>
                      Add Property
                    </Text>
                  </TouchableOpacity>
                  <Image
                    style={{ width: 80, height: 30 }}
                    source={require("../../assets/home/undulating.svg")}
                  />
                </View>
              </View>

              {/* Search Property */}
              <View style={{ position: "relative", zIndex: 1 }}>
                {/* Search Box */}
                <TextInput
                  value={suggestQuery}
                  placeholder="Search"
                  styleWrapInput={{ borderWidth: 0 }}
                  styleInput={[
                    styles.inputSearch,
                    (isFocused || suggestQuery) && styles.focusedInput,
                  ]}
                  styleSuffix={styles.styleSuffix}
                  styleSuffixIcon={styles.suffixIconSearch}
                  // suffixIcon={
                  //   isFocused
                  //     ? require("../../assets/portfolio-roadmap/search-normal-white.svg")
                  //     : require("../../assets/portfolio-roadmap/search-normal-input.svg")
                  // }
                  onChangeText={this.handleInputChange}
                  size={40}
                  onFocus={this.handleFocus}
                  onBlur={this.handleBlur}
                  onSubmitEditing={this.handleSearch}
                  returnKeyType="search"
                />
                {suggestQuery ? (
                  <TouchableOpacity
                    style={styles.clearButton}
                    onPress={this.clearSearch}>
                    <Image
                      style={styles.clearIcon}
                      source={require("../../assets/portfolio-roadmap/clear-icon.svg")}
                    />
                  </TouchableOpacity>
                ) : null}

                <TouchableOpacity
                  style={styles.searchButton}
                  onPress={this.handleSearch}>
                  <Image
                    style={styles.searchIcon}
                    source={require("../../assets/portfolio-roadmap/search-normal-white.svg")}
                  />
                </TouchableOpacity>

                {/* Search suggestions Box */}
                {filteredSuggestProperties?.length ? (
                  <View style={styles.searchSuggestionsBox}>
                    <Text
                      style={[
                        styles.menuTitle,
                        { fontWeight: 400, fontFamily: MAIN_FONT.regular },
                      ]}>
                      Search suggestions
                    </Text>
                    {filteredSuggestProperties.map((property, index) => {
                      return (
                        <SearchSuggestCard
                          key={index}
                          style={{
                            width: "100%",
                            borderColor:
                              property.id === selectedPropertyId
                                ? MAIN_COLORS.PRIMARY_COLOR
                                : "#4B555D99",
                          }}
                          item={property}
                          onPress={() => {
                            this.handleSelectProperty(property.id);
                            this.setState({
                              item: property,
                              index,
                              currentComponent: "index",
                              suggestResult: suggestQuery,
                              filteredSuggestProperties: [],
                              filteredResultProperties: [],
                              filteredSuggestResultProperties: [property],
                            });
                          }}
                        />
                      );
                    })}
                  </View>
                ) : null}
              </View>

              {filteredResultProperties?.length ||
              filteredSuggestResultProperties?.length ? (
                <>
                  {suggestResult &&
                    filteredSuggestResultProperties?.length > 0 && (
                      <View>
                        <Text style={styles.menuTitle}>
                          {filteredSuggestResultProperties[0]?.purchase_by ===
                            0 ||
                          filteredSuggestResultProperties[0]?.purchase_by ===
                            undefined
                            ? "Purchases"
                            : filteredSuggestResultProperties[0]
                                ?.purchase_by === 1
                            ? "Imported Properties"
                            : ""}
                        </Text>
                      </View>
                    )}

                  {suggestResult &&
                    filteredSuggestResultProperties?.length > 0 && (
                      <>
                        {filteredSuggestResultProperties
                          .slice(0, filteredCount)
                          .map((property, index) => {
                            const opp = this.props.TIA.opportunities.find(
                              (item) =>
                                item?.["Proposed Property"]?.[0]?.id ===
                                property.id
                            );

                            return (
                              <PropertyCard
                                key={index}
                                partner={opp?.co_labeling_logo}
                                style={{
                                  width: "100%",
                                  borderColor: MAIN_COLORS.PRIMARY_COLOR,
                                  borderWidth:
                                    property.id === selectedPropertyId ? 1 : 0,
                                }}
                                item={property}
                                onPress={() => {
                                  this.handleSelectProperty(property.id);
                                  this.setState({
                                    item: property,
                                    index,
                                    currentComponent: "index",
                                  });
                                }}
                              />
                            );
                          })}

                        {filteredSuggestResultProperties.length >
                          filteredCount && (
                          <TouchableOpacity
                            style={styles.viewMoreButton}
                            onPress={() =>
                              this.handleViewMore("filteredCount")
                            }>
                            <Text style={styles.viewMoreButtonTxT}>
                              View more{" "}
                              {filteredSuggestResultProperties.length -
                                filteredCount}{" "}
                              apartments
                            </Text>
                            <Image
                              style={{ width: 24, height: 24 }}
                              source={require("../../assets/home/drop-down.png")}
                            />
                          </TouchableOpacity>
                        )}
                      </>
                    )}

                  {searchQuery && filteredResultProperties?.length > 0 && (
                    <View>
                      <Text style={styles.menuTitle}>
                        {searchResults} results for "
                        <Text style={{ color: MAIN_COLORS.PRIMARY_COLOR }}>
                          {searchQuery}
                        </Text>
                        "
                      </Text>
                    </View>
                  )}

                  {filteredResultProperties
                    .slice(0, filteredCount)
                    .map((property, index) => {
                      const opp = this.props.TIA.opportunities.find(
                        (item) =>
                          item?.["Proposed Property"]?.[0]?.id === property.id
                      );

                      return (
                        <PropertyCard
                          key={index}
                          partner={opp?.co_labeling_logo}
                          style={{
                            width: "100%",
                            borderColor: MAIN_COLORS.PRIMARY_COLOR,
                            borderWidth:
                              property.id === selectedPropertyId ? 1 : 0,
                          }}
                          item={property}
                          onPress={() => {
                            this.handleSelectProperty(property.id);
                            this.setState({
                              item: property,
                              index,
                              currentComponent: "index",
                            });
                          }}
                        />
                      );
                    })}

                  {filteredResultProperties.length > filteredCount && (
                    <TouchableOpacity
                      style={styles.viewMoreButton}
                      onPress={() => this.handleViewMore("filteredCount")}>
                      <Text style={styles.viewMoreButtonTxT}>
                        View more{" "}
                        {filteredResultProperties.length - filteredCount}{" "}
                        apartments
                      </Text>
                      <Image
                        style={{ width: 24, height: 24 }}
                        source={require("../../assets/home/drop-down.png")}
                      />
                    </TouchableOpacity>
                  )}
                </>
              ) : (
                <>
                  {searchQuery && (
                    <View>
                      <Text style={styles.menuTitle}>
                        0 results for "
                        <Text style={{ color: MAIN_COLORS.PRIMARY_COLOR }}>
                          {searchQuery}
                        </Text>
                        "
                      </Text>
                    </View>
                  )}

                  {properties?.length > 0 && (
                    <>
                      <Text style={styles.menuTitle}>Purchases</Text>
                      {properties
                        .slice(0, propertiesCount)
                        .map((property, index) => {
                          const opp = this.props.TIA.opportunities.find(
                            (item) =>
                              item?.["Proposed Property"]?.[0]?.id ===
                              property.id
                          );

                          return (
                            <PropertyCard
                              key={index}
                              partner={opp?.co_labeling_logo}
                              style={{
                                width: "100%",
                                borderColor: MAIN_COLORS.PRIMARY_COLOR,
                                borderWidth:
                                  property.id === selectedPropertyId ? 1 : 0,
                              }}
                              item={property}
                              onPress={() => {
                                this.handleSelectProperty(property.id);
                                this.setState({
                                  item: property,
                                  index,
                                  currentComponent: "index",
                                });
                              }}
                            />
                          );
                        })}

                      {properties.length > propertiesCount && (
                        <TouchableOpacity
                          style={styles.viewMoreButton}
                          onPress={() =>
                            this.handleViewMore("propertiesCount")
                          }>
                          <Text style={styles.viewMoreButtonTxT}>
                            View more {properties.length - propertiesCount}{" "}
                            apartments
                          </Text>
                          <Image
                            style={{ width: 24, height: 24 }}
                            source={require("../../assets/home/drop-down.png")}
                          />
                        </TouchableOpacity>
                      )}
                    </>
                  )}

                  {listNonTIAProperties?.length > 0 && (
                    <>
                      <Text style={styles.menuTitle}>Imported Properties</Text>
                      {listNonTIAProperties
                        .slice(0, nonTIAPropertiesCount)
                        .map((nonTIA, index) => (
                          <PropertyCard
                            key={index}
                            style={{
                              width: "100%",
                              borderColor: MAIN_COLORS.PRIMARY_COLOR,
                              borderWidth:
                                nonTIA.id === selectedPropertyId ? 1 : 0,
                            }}
                            item={nonTIA}
                            close
                            onPress={() => {
                              this.handleSelectProperty(nonTIA.id);
                              this.setState({
                                item: nonTIA,
                                index,
                                currentComponent: "index",
                              });
                            }}
                            onRemove={() => {
                              this.setState({ visibleRemoveProperty: true });
                              this.setState({ propertyRemove: nonTIA });
                            }}
                          />
                        ))}

                      {listNonTIAProperties.length > nonTIAPropertiesCount && (
                        <TouchableOpacity
                          style={styles.viewMoreButton}
                          onPress={() =>
                            this.handleViewMore("nonTIAPropertiesCount")
                          }>
                          <Text style={styles.viewMoreButtonTxT}>
                            View more{" "}
                            {listNonTIAProperties.length -
                              nonTIAPropertiesCount}{" "}
                            apartments
                          </Text>
                          <Image
                            style={{ width: 24, height: 24 }}
                            source={require("../../assets/home/drop-down.png")}
                          />
                        </TouchableOpacity>
                      )}
                    </>
                  )}
                </>
              )}
            </View>
          }>
          <View
            style={{
              position: "fixed",
              right: 120,
              top: 0,
              width: 150,
              zIndex: 9999,
            }}>
            <Toast config={toastConfig} />
          </View>
          <ScrollView
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            contentContainerStyle={{
              width: "100%",
              height: itemSelected ? "auto" : "100%",
              paddingBottom: 75,
            }}>
            {currentComponent === "index" ? (
              itemSelected ? (
                <PortfolioItem
                  portfolio={itemSelected}
                  matchingOpportunity={matchingOpportunity}
                />
              ) : (
                <EmptyContent
                  title="Content"
                  subTitle="Please click on your assets under Purchases for details."
                />
              )
            ) : (
              <SearchProperty
                setIsLoading={this.setLoading}
                goBack={() => this.setState({ currentComponent: "index" })}
                setNotiAfterSave={this.setNotiAfterSave}
              />
            )}
          </ScrollView>

          {visibleRemoveProperty ? (
            <Modal
              size="small"
              btnCancel="Cancel"
              btnSave="Remove"
              disableSave={isLoading}
              open={visibleRemoveProperty}
              onClose={() => this.setState({ visibleRemoveProperty: false })}
              onSave={handleRemoveProperty}
              styleModal={{ width: 343 }}
              styleBtn={{ width: 150 }}>
              <View
                style={{
                  display: "flex",
                  alignItems: "center",
                }}>
                <Image
                  style={{ width: 86, height: 86 }}
                  source={require("../../assets/home/trash.svg")}
                />
                <View style={{ alignItems: "center" }}>
                  <Text
                    style={[
                      styles.menuTitle,
                      {
                        fontSize: 18,
                        marginBottom: 8,
                        marginTop: 6,
                        lineHeight: 27,
                      },
                    ]}>
                    Remove Property
                  </Text>
                  <Text
                    style={[
                      styles.text,
                      {
                        fontSize: FONT_SIZE.large,
                        textAlign: "center",
                        lineHeight: 24,
                      },
                    ]}>
                    Are you sure you want to remove "{propertyRemove?.Suburb}"?
                  </Text>
                </View>
              </View>
            </Modal>
          ) : null}

          {notiAfterSave && (
            <Modal
              open={notiAfterSave}
              btnSave="Got it!"
              onSave={() => {
                this.setNotiAfterSave(false);
              }}
              size="x-small"
              styleModal={{
                padding: 18,
                borderRadius: 12,
                zIndex: 2000,
              }}
              styleBtn={{ width: 150, height: 42 }}
              styleOverlay={{ zIndex: 1500 }}>
              <View
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 10,
                }}>
                <Image
                  style={{ width: 64, height: 64 }}
                  source={require("../../assets/home/due_primary.png")}
                />
                <Text style={styles.titleModalNoti}>Notification</Text>
                <Text style={styles.textModalNoti}>
                  Your property has been successfully added to your Portfolio.
                  Please wait around 15 minutes for this property to update the
                  growth values.
                </Text>
              </View>
            </Modal>
          )}
        </MainLayout>
        {isLoading ? <LOADER /> : null}
      </>
    );
  }
}

const styles = StyleSheet.create({
  headerTitle: {
    fontSize: 20,
    lineHeight: 30,
    fontWeight: FONT_WEIGHT.bold,
    color: MAIN_COLORS.BACKGROUND_WHITE,
    fontFamily: MAIN_FONT.semi,
  },

  containerMenu: {
    padding: 12,
    borderRadius: 10,
    backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
    gap: 14,
  },

  menuTitle: {
    fontSize: FONT_SIZE.large,
    lineHeight: 24,
    fontWeight: FONT_WEIGHT.bold,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.semi,
  },

  text: {
    fontSize: FONT_SIZE.small,
    fontWeight: FONT_WEIGHT.medium,
    lineHeight: 18,
    color: MAIN_COLORS.G600,
    fontFamily: MAIN_FONT.regular,
  },

  rowCenterBetween: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  btnAddProperty: {
    borderRadius: 6,
    borderWidth: 1,
    borderColor: MAIN_COLORS.PRIMARY_COLOR,
    paddingHorizontal: 12,
    paddingVertical: 4,
    flexDirection: "row",
    gap: 8,
    backgroundColor: "transperent",
  },

  titleModalNoti: {
    marginTop: 6,
    marginBottom: 8,
    fontWeight: "600",
    fontSize: 16,
    lineHeight: 24,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.semi,
  },
  textModalNoti: {
    fontWeight: "400",
    fontSize: 14,
    lineHeight: 21,
    color: MAIN_COLORS.G600,
    fontFamily: MAIN_FONT.regular,
    textAlign: "center",
    paddingHorizontal: 16,
  },
  inputSearch: {
    borderRadius: 6,
    marginLeft: 0,
    paddingHorizontal: 16,
    paddingRight: 86,
    height: "100%",
    height: 40,
    borderWidth: 1,
    borderColor: MAIN_COLORS.BORDER_COLOR,
  },
  focusedInput: {
    borderColor: MAIN_COLORS.PRIMARY_COLOR,
  },
  styleSuffix: {
    right: 16,
  },
  suffixIconSearch: {
    width: 24,
    height: 24,
  },
  clearButton: {
    position: "absolute",
    right: 52,
    top: "50%",
    transform: [{ translateY: "-50%" }],
    zIndex: 1,
  },
  clearIcon: {
    width: 18,
    height: 18,
  },
  searchButton: {
    position: "absolute",
    right: 16,
    top: "50%",
    transform: [{ translateY: "-50%" }],
    zIndex: 1,
  },
  searchIcon: {
    width: 24,
    height: 24,
  },
  searchSuggestionsBox: {
    position: "absolute",
    top: 45,
    borderRadius: 6,
    width: "100%",
    padding: 12,
    borderWidth: 1,
    borderColor: "#4B555D99",
    backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
    gap: 12,
  },
  viewMoreButton: {
    height: 42,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: MAIN_COLORS.PRIMARY_COLOR,
    backgroundColor: MAIN_COLORS.BACKGROUND_BLACK,
    textAlign: "center",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  viewMoreButtonTxT: {
    fontWeight: "600",
    fontSize: 14,
    lineHeight: 21,
    color: MAIN_COLORS.PRIMARY_COLOR,
    fontFamily: MAIN_FONT.semi,
    textAlign: "center",
    marginRight: 6,
  },
});

function mapStateToProps(state) {
  return {
    COLORS: state.COLORS,
    USER: state.USER,
    TIA: state.TIA,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setEquity,
      setClient,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
