import { MAIN_COLOR, MAIN_COLORS } from "../../Utility/Colors";
import { MAIN_FONT } from "../../Utility/Fonts/font";

import { StyleSheet } from "react-native";

var styles;

export default styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#161B1E",
    flexDirection: "row",
  },
  contentLeft: {
    width: "50%",
    height: "100%",
    display: "flex",
  },
  boxLogoTIA: {
    display: "flex",
    justifyContent: "center",
    height: 165,
    paddingLeft: 54,
  },
  logoTIA: {
    height: 57,
    width: 179,
  },
  backIcon: {
    width: 30,
    height: 30,
  },

  contentRight: {
    width: "50%",
    backgroundColor: "black",
  },

  sectionTitle: {
    fontWeight: 600,
    fontSize: 28,
    lineHeight: 42,
    color: MAIN_COLOR.BACKGROUND_COLOR,
    textAlign: "center",
    marginBottom: 8,
    fontFamily: MAIN_FONT.semi,
  },
  sectionSubTitle: {
    fontSize: 14,
    lineHeight: 21,
    textAlign: "center",
    fontWeight: 400,
    color: MAIN_COLOR.G600,
    fontFamily: MAIN_FONT.regular,
  },

  sectionCenterView: {
    alignSelf: "center",
    width: "60%",
    justifyContent: "flex-start",
  },

  sectionContent: {
    alignSelf: "center",
    display: "flex",
    width: "100%",
    flex: 1,
    gap: 56,
  },

  scrollViewContentView: {
    flex: 1,
    width: "100%",
  },

  sectionForm: {
    flex: 1,
    marginBottom: 22,
  },

  labelForm: {
    lineHeight: 21,
    fontSize: 14,
    fontFamily: MAIN_FONT.regular,
    color: "#272D37",
    marginBottom: 5,
    fontWeight: 600,
    color: MAIN_COLOR.BACKGROUND_COLOR,
    fontFamily: MAIN_FONT.semi,
  },

  inputForm: {
    height: 45,
    width: "100%",
    borderWidth: 1,
    outlineWidth: 0,
    borderRadius: 6,
    borderColor: "#34393D",
    paddingHorizontal: 10,
    paddingVertical: 12,
    color: MAIN_COLORS.TEXT_LIGHT,
    backgroundColor: MAIN_COLORS.BACKGROUND_BLACK,
    fontFamily: MAIN_FONT.regular,
  },

  btnSubmit: {
    height: 46,
    width: "60%",
    alignSelf: "center",
    paddingHorizontal: 24,
    paddingVertical: 10,
    marginTop: 0,
    marginHorizontal: "auto",
  },

  textButton: {
    fontFamily: MAIN_FONT.semi,
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 21,
    color: "#19232B",
    fontFamily: MAIN_FONT.semi,
  },

  textForgotPassword: {
    fontFamily: MAIN_FONT.regular,
    color: "#272D37",
    textAlign: "right",
    fontFamily: MAIN_FONT.regular,
  },

  rowCenter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  inputPassword: {
    flex: 1,
    color: MAIN_COLOR.BACKGROUND_COLOR,
    outlineWidth: 0,
    width: "100%",
  },

  inputOTP: {
    width: 56,
    height: 64,
    borderRadius: 8,
    textAlign: "center",
    fontSize: 24,
    fontWeight: "500",
    fontFamily: MAIN_FONT.semi,
    borderWidth: 1,
    marginHorizontal: 4,
  },
  inputOTPDefault: {
    borderColor: "#9D9EAB",
    color: "white",
    backgroundColor: MAIN_COLORS.BACKGROUND_BLACK,
  },
  inputOTPActive: {
    borderColor: MAIN_COLORS.PRIMARY_COLOR,
    color: MAIN_COLORS.PRIMARY_COLOR,
    backgroundColor: MAIN_COLORS.BACKGROUND_BLACK,
  },
});
