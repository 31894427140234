import AsyncStorage from '@react-native-async-storage/async-storage';

export const setDataToAsyncStorage = async (index_name, item) => {
  try {
    await AsyncStorage.setItem(`@commmon_tem_2020_${index_name}`, item);
  } catch (error) {
    // Error saving data
  }
};

export const getDataFromAsyncStorage = async index_name => {
  try {
    const value = (await AsyncStorage.getItem(`@commmon_tem_2020_${index_name}`)) || "none";
    if (value !== null) {
      // We have data!!
      return value;
    }
  } catch (error) {
    // Error retrieving data
  }
  return "none";
};

export const clearAsyncStorage = async item => {
  try {
      await AsyncStorage.clear();
      
    // await AsyncStorage.removeItem(item);
  } catch (error) {
    // Error retrieving data
    console.log(error.message);
  }
};

export const setAsyncStorage = async (key_name, item) => {
  try {
    await AsyncStorage.setItem(key_name, item);
  } catch (error) {
    // Error retrieving data
    console.log(error.message);
  }
};

export const clearExceptSpecifiedKeys = async (keysToKeep = []) => {
  try {
    const allKeys = await AsyncStorage.getAllKeys();

    const keysToRemove = allKeys.filter(key => !keysToKeep.includes(key));

    await AsyncStorage.multiRemove(keysToRemove);
  } catch (error) {
    console.log(error.message);
  }
};