import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TouchableOpacity } from "react-native";
import { createElement } from "react-native-web";
import { View, StyleSheet, Text, Image } from "react-native";

import fields from "../../../Utility/Fields";
import { MAIN_COLOR, MAIN_COLORS } from "../../../Utility/Colors";
import { FONT_SIZE, MAIN_FONT } from "../../../Utility/Fonts/font";

import ButtonStatus from "./ButtonStatus";
import Time from "../../../components/Time";
import ViewGallery from "../../../components/ViewGallery";

const statusTask = {
  complete: "Complete",
  progress: "In Progress",
  due: "Due",
};

const TaskItem = ({
  active,
  task,
  status,
  time,
  contacts,
  prevNotification,
  onPress,
}) => {
  const [assigns, setAssign] = useState([]);
  const [isShowIntro, setIsShowIntro] = useState(false);

  const web = (link, control) => {
    const Video = (props) => {
      return (
        <video
          src={props.source}
          width="100%"
          height="100%"
          controls={control}
          autoPlay={control ? true : false}
          frameBorder="0"
          webkitallowfullscreen={"true"}
          mozallowfullscreen={"true"}
          allowFullScreen
          style={{ objectFit: "cover" }}
        />
      );
    };

    return <Video source={link} />;
  };

  useEffect(() => {
    setAssign(contacts);
  }, [contacts]);

  return (
    <>
      <TouchableOpacity
        style={[styles.container, active && styles.containerActive]}
        onPress={onPress}
      >
        <View style={styles.rowCenterBetween}>
          <Text style={styles.title}>{task[fields.appStageName]}</Text>
          <ButtonStatus status={status} />
        </View>
        <Time
          date={
            status === statusTask.progress
              ? prevNotification?.dateUpdated?.seconds * 1000
              : time
          }
        />
        {(assigns.length > 0 ||
          (task["Link Video"] && status === statusTask.progress)) && (
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              gap: task["Link Video"] && status === statusTask.progress ? 8 : 0,
            }}
          >
            {task["Link Video"] && status === statusTask.progress && (
              <TouchableOpacity
                activeOpacity={1}
                style={styles.videoIntro}
                onPress={() => setIsShowIntro(true)}
              >
                {web(task["Link Video"])}
                <View style={styles.btnPlay}>
                  <Image
                    style={{ width: 24, height: 24 }}
                    source={require("../../../assets/pause-video-circle.svg")}
                  />
                </View>
              </TouchableOpacity>
            )}
            {assigns.length > 0 && (
              <View
                style={{
                  flexDirection:
                    task["Link Video"] && status === statusTask.progress
                      ? "column-reverse"
                      : "row",
                  gap: 8,
                  justifyContent: "space-between",
                  flex:
                    task["Link Video"] && status === statusTask.progress
                      ? "unset"
                      : 1,
                  alignItems:
                    task["Link Video"] && status === statusTask.progress
                      ? "flex-end"
                      : "unset",
                }}
              >
                <View>
                  {assigns.length > 1 && (
                    <View
                      style={[
                        styles.peopleAssignBox,
                        {
                          width: assigns.length * 28 - assigns.length * 8,
                        },
                      ]}
                    >
                      {assigns.length > 1 &&
                        assigns.slice(1).map((item, index) => {
                          return (
                            <View
                              key={index}
                              style={[
                                styles.assignItem,
                                {
                                  position: "absolute",
                                  left: index * 20 + 6,
                                  zIndex: assigns.length + index,
                                },
                              ]}
                            >
                              {item.avatar ? (
                                <Image
                                  style={{ width: "100%", height: "100%" }}
                                  source={item.avatar}
                                />
                              ) : (
                                <Image
                                  style={{ width: 19, height: 21 }}
                                  source={require("../../../assets/home/user_no_border.svg")}
                                />
                              )}
                            </View>
                          );
                        })}
                    </View>
                  )}
                </View>

                <View style={[styles.peopleAssignBox, { paddingLeft: 12 }]}>
                  <Text style={styles.textAssign}>{assigns[0].type}</Text>
                  <View>
                    {assigns[0].avatar ? (
                      <View style={styles.assignItem}>
                        <Image
                          style={{ width: "100%", height: "100%" }}
                          source={assigns[0].avatar}
                        />
                      </View>
                    ) : (
                      <Image
                        style={{ width: 28, height: 28 }}
                        source={require("../../../assets/home/user.svg")}
                      />
                    )}
                  </View>
                </View>
              </View>
            )}
          </View>
        )}
      </TouchableOpacity>

      {isShowIntro ? (
        <ViewGallery
          open={isShowIntro}
          onClose={() => setIsShowIntro(false)}
          styleBox={{ width: 900, height: 500 }}
        >
          {web(task?.["Link Video"], true)}
        </ViewGallery>
      ) : null}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR_MAIN,
    padding: 16,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "transparent",
    display: "flex",
    gap: 12,
  },

  containerActive: {
    borderColor: "#D3B10080",
  },

  rowCenterBetween: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 12,
  },

  title: {
    fontSize: 16,
    fontWeight: 600,
    lightHeight: 24,
    color: MAIN_COLOR.BACKGROUND_COLOR,
    fontFamily: MAIN_FONT.semi,
  },

  icon: { width: 18, height: 18 },

  videoIntro: {
    height: 80,
    width: 148,
    borderRadius: 6,
    overflow: "hidden",
  },

  btnPlay: {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: 24,
    height: 24,
    zIndex: 100,
    transform: "translate(-50%, -50%)",
  },

  text: {
    fontSize: 12,
    lineHeight: 16,
    fontWeight: 500,
    color: "#A3A3A3",
    fontFamily: MAIN_FONT.regular,
  },

  divider: {
    width: 2,
    height: 12,
    borderRadius: 10,
    backgroundColor: "#A3A3A3",
  },

  peopleAssignBox: {
    height: 36,
    borderRadius: 67,
    paddingHorizontal: 6,
    paddingVertical: 4,
    backgroundColor: MAIN_COLORS.BACKGROUND_BLACK,
    flexDirection: "row",
    alignItems: "center",
  },

  assignItem: {
    alignItems: "center",
    justifyContent: "flex-end",
    width: 28,
    height: 28,
    borderRadius: "50%",
    overflow: "hidden",
    backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
    borderWidth: 1,
    borderColor: MAIN_COLORS.BACKGROUND_WHITE,
  },

  textAssign: {
    fontWeight: 500,
    fontSize: FONT_SIZE.small,
    lightHeight: 16,
    color: MAIN_COLORS.TEXT_LIGHT,
    marginRight: 6,
    fontFamily: MAIN_FONT.regular,
  },
});

export default TaskItem;
