import React, { memo } from "react";
import moment from "moment";
import "chartjs-adapter-moment";
import { Line } from "react-chartjs-2";
import { View, StyleSheet, Text } from "react-native";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

//Functions
import { MAIN_COLORS } from "../../../../../Utility/Colors";
import {
  FONT_SIZE,
  FONT_WEIGHT,
  MAIN_FONT,
} from "../../../../../Utility/Fonts/font";
import {
  externalTooltipHandlerNew,
  formatDollar,
} from "../../../../../Utility/common";

const CashFlowChart = ({ result, idxSkipped }) => {
  const grossCFTable = result?.map((item, index) => {
    const { grossCFValue, date, isBought } = item;
    return {
      x:
        isBought || index === result.length - 1
          ? moment(date).format("YYYY-MM")
          : undefined,
      y: grossCFValue,
    };
  });

  const netCFTable = result?.map((item, index) => {
    const { netCFValue, date, isBought } = item;
    return {
      x:
        isBought || index === result.length - 1
          ? moment(date).format("YYYY-MM")
          : undefined,
      y: netCFValue,
    };
  });

  const min =
    grossCFTable &&
    netCFTable &&
    Math.min(
      ...[...grossCFTable.map(({ y }) => y), ...netCFTable.map(({ y }) => y)]
    );

  const max =
    grossCFTable &&
    netCFTable &&
    Math.max(
      ...[...grossCFTable.map(({ y }) => y), ...netCFTable.map(({ y }) => y)]
    );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 6,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    hover: { mode: "index", intersect: false },
    tooltips: { mode: "index", intersect: false },
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: false,
        position: "nearest",
        cornerRadius: 20,
        callbacks: {
          label: (value) => formatDollar(value.raw.y),
        },
        external: (context) =>
          externalTooltipHandlerNew(
            context,
            { top: 0, right: 4, bottom: 0, left: 4 },
            "46px"
          ),
      },
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: "month",
          stepSize: 1,
          displayFormats: { year: "yyyy" },
        },
        grid: { display: false },
        border: { color: "#3F4448" },
        ticks: {
          autoSkip: false,
          stepSize: 1,
          maxRotation: 90,
          minRotation: 90,
          color: MAIN_COLORS.TEXT_LIGHT,
          callback: function (value, index) {
            const isJanuary = moment(value).format("MM") === "01";
            if (isJanuary || index === 0) return moment(value).format("YYYY");
          },
        },
      },
      y: {
        ticks: {
          display: false,
          maxTicksLimit: 5,
          stepSize: (max + max * 0.1 - min) / 5,
        },
        grid: {
          drawTicks: false,
          color: function (context) {
            if (context.index === 3) return "rgba(0,0,0,0)";
            return "#34393D";
          },
        },
        border: { dash: [3, 3], color: "#3F4448" },
        min,
        max: max + max * 0.1,
      },
      y1: {
        position: "right",
        border: { color: "#3F4448" },
        grid: { display: false },
        ticks: { display: false },
      },
    },
  };

  const data = {
    datasets: [
      {
        data: grossCFTable,
        borderColor: MAIN_COLORS.PRIMARY_COLOR,
        backgroundColor: (context) => {
          const bgColor = [
            "rgba(250, 191, 1, 0.3)",
            "rgba(250, 191, 1, 0.2)",
            "rgba(250, 191, 1, 0.1)",
          ];
          if (!context.chart.chartArea) return;
          const {
            ctx,
            chartArea: { top, bottom },
          } = context.chart;
          const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
          gradientBg.addColorStop(0.1, bgColor[0]);
          gradientBg.addColorStop(0.5, bgColor[1]);
          gradientBg.addColorStop(1, bgColor[2]);
          return gradientBg;
        },
        lineTension: 0.5,
        spanGaps: true,
        borderWidth: 1.5,
        clip: false,
        fill: true,
        pointRadius: () => 0,
        segment: { borderDash: (ctx) => skipped(ctx, [3, 3]) },
        pointHoverBackgroundColor: MAIN_COLORS.PRIMARY_COLOR,
        pointHoverBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBorderColor: MAIN_COLORS.BACKGROUND_WHITE,
      },
      {
        data: netCFTable,
        borderColor: MAIN_COLORS.GREEN,
        backgroundColor: (context) => {
          const bgColor = [
            "rgba(51, 178, 30, 0.3)",
            "rgba(51, 178, 30, 0.2)",
            "rgba(51, 178, 30, 0.1)",
          ];
          if (!context.chart.chartArea) return;
          const {
            ctx,
            chartArea: { top, bottom },
          } = context.chart;
          const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
          gradientBg.addColorStop(0.1, bgColor[0]);
          gradientBg.addColorStop(0.5, bgColor[1]);
          gradientBg.addColorStop(1, bgColor[2]);
          return gradientBg;
        },
        lineTension: 0.5,
        spanGaps: true,
        borderWidth: 1.5,
        pointRadius: () => 0,
        clip: false,
        fill: true,
        segment: { borderDash: (ctx) => skipped(ctx, [3, 3]) },
        pointHoverBackgroundColor: MAIN_COLORS.PRIMARY_COLOR,
        pointHoverBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBorderColor: MAIN_COLORS.GREEN,
      },
    ],
  };

  const plugins = [
    {
      afterDraw: function (chart, easing) {
        if (chart?.tooltip?._active && chart?.tooltip?._active?.length) {
          const activePoint = chart.tooltip._active[0];
          const ctx = chart.ctx;
          const x = activePoint.element.x;
          const topY = chart.scales.y.chart.tooltip.caretY + 10;
          const bottomY = chart.scales.y.bottom;

          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x, topY);
          ctx.lineTo(x, bottomY);
          ctx.lineWidth = 1;
          ctx.strokeStyle = MAIN_COLORS.PRIMARY_COLOR;
          ctx.stroke();
          ctx.restore();
        }
      },
    },
  ];

  const skipped = (ctx, value) => {
    return ctx.p0DataIndex >= idxSkipped ? value : [6, 0];
  };

  return (
    <View style={styles.container}>
      <View style={{ gap: 2 }}>
        <Text style={styles.label}>Cash Flow</Text>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Text style={styles.textSection}>Current cash flow p/m: </Text>
          <Text style={styles.valueSection}>
            {formatDollar(Math.round(result?.[idxSkipped - 1]?.grossCFValue))}
          </Text>
        </View>
      </View>
      <View style={{ flex: 1, marginVertical: 12 }}>
        <Line
          options={options}
          data={data}
          plugins={plugins}
          height={150}
          width={367}
        />
      </View>
      <View style={{ flexDirection: "row", justifyContent: "space-around" }}>
        <View style={{ flexDirection: "row", gap: 6, alignItems: "center" }}>
          <View style={styles.statue} />
          <Text style={styles.stauteTitle}>Gross cash flow</Text>
        </View>
        <View style={{ flexDirection: "row", gap: 6, alignItems: "center" }}>
          <View style={[styles.statue, { borderColor: MAIN_COLORS.GREEN }]} />
          <Text style={styles.stauteTitle}>Net cash flow</Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: MAIN_COLORS.BACKGROUND_BLACK,
    borderWidth: 1,
    borderColor: "#4B555D99",
    borderRadius: 10,
    minWidth: 398,
    minHeight: 301,
    padding: 16,
    flex: 1,
  },

  textSection: {
    fontSize: FONT_SIZE.small,
    fontWeight: FONT_WEIGHT.medium,
    lineHeight: 18,
    color: MAIN_COLORS.G600,
    fontFamily: MAIN_FONT.regular,
  },

  valueSection: {
    fontWeight: 600,
    fontSize: FONT_SIZE.large,
    color: MAIN_COLORS.PRIMARY_COLOR,
    lineHeight: 24,
    fontFamily: MAIN_FONT.regular,
  },

  label: {
    fontSize: FONT_SIZE.medium,
    fontWeight: FONT_WEIGHT.bold,
    lineHeight: 20,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.semi,
  },

  statue: {
    width: 9,
    height: 9,
    backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
    borderRadius: "50%",
    borderWidth: 1,
    borderColor: MAIN_COLORS.BACKGROUND_WHITE,
  },

  stauteTitle: {
    fontWeight: FONT_WEIGHT.medium,
    fontSize: 10,
    lineHeight: 15,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.regular,
  },
});

export default memo(CashFlowChart);
