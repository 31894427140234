import "chart.js/auto";
import "chartjs-adapter-moment";
import { BlurView } from "expo-blur";
import { Line } from "react-chartjs-2";
import lodash, { uniqBy } from "lodash";
import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import {
  View,
  StyleSheet,
  ScrollView,
  Text,
  TextInput,
  ImageBackground,
  ActivityIndicator,
} from "react-native";

//Functions
import {
  FONT_SIZE,
  FONT_WEIGHT,
  MAIN_FONT,
} from "../../../Utility/Fonts/font.js";
import {
  formatDollar,
  externalTooltipHandlerNew,
} from "../../../Utility/common.js";
import { styles } from "../index.v2";
import { MAIN_COLORS } from "../../../Utility/Colors";
import {
  marketFourBedrooms,
  marketHistoricalData,
} from "../../../Clickup/api.js";
import {
  MARKET_FORMDATA_LABELS,
  MARKET_FORMDATA_FIELDS,
} from "../../../Utility/Fields/index.js";

import FlastList from "../../../components/FlatList.v2.js";
import moment from "moment";

ChartJS.register(ArcElement, Tooltip, Legend);

const PropertyFormatData = ({ body_market }) => {
  const [bedRooms, setBedRoom] = useState(null);
  const [historicals, setHistoricals] = useState([]);
  const [loadingHostorical, setLoadingHistorical] = useState(false);

  const calcDeposit = (sales_median_12mths = 0) => {
    return {
      buyingCost12: sales_median_12mths * 0.12 * 1.43 ?? 0,
      buyingCost20: sales_median_12mths * 0.2 * 1.25 ?? 0,
      repayment12: sales_median_12mths * 0.88 * 0.065 ?? 0,
      repayment20: sales_median_12mths * 0.8 * 0.065 ?? 0,
    };
  };

  const fetchFourBedrooms = async () => {

    const response = await marketFourBedrooms(body_market);

    if (response.status === 200) {
      const resultDeposit = calcDeposit(response.data?.sales_median_12mths);
      setBedRoom({
        ...resultDeposit,
        price: response.data?.sales_median_12mths,
        rent: response.data?.asking_rent_median_12mths,
        rate: response.data?.rental_vacancy_rate,
      });
    }
  };

  const fetchHistorical = async () => {
    try {
      setLoadingHistorical(true);

      const response = await marketHistoricalData(body_market);

      if (response.status === 200) {
        const data = MARKET_FORMDATA_FIELDS.map((one) => {
          return {
            key: one,
            data: response.data[one],
            ...MARKET_FORMDATA_LABELS[one],
          };
        });
        setHistoricals(data);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoadingHistorical(false);
    }
  };

  function getEvenlySpacedWithEnds(item) {
    const currentMonth = moment().month();
    const itemOfData = item?.data?.length;

    if (itemOfData <= 5) {
      return item?.data?.map(({ label, value }) => ({
        x: moment(label).format("MM/YY"),
        y: !value
          ? 0
          : item.key === "avg_hold_days_12mths"
          ? value / 365.25
          : value,
      }));
    } else {
      return uniqBy(
        item.data?.map(({ label, value }) => {
          if (moment(label).month() === currentMonth)
            return {
              x: moment(label).format("YYYY"),
              y: !value
                ? 0.4
                : item.key === "avg_hold_days_12mths"
                ? value / 365.25
                : value,
            };

          return null;
        }),
        "x"
      ).filter((item) => !!item && item);
    }
  }

  useEffect(() => {
    if (body_market?.bedrooms) {
      fetchFourBedrooms();
      fetchHistorical();
    }
  }, []);

  return (
    <View style={_styles.gap}>
      <View style={_styles.rowGap16}>
        <View style={{ flex: 2, borderRadius: 10, overflow: "hidden" }}>
          <ImageBackground
            style={_styles.backgroudImage}
            source={require("../../../assets/home/aeria_default.svg")}>
            <View>
              <Text style={styles.subTitleSection}>
                {body_market?.bedrooms ?? 0} Bed house medians
              </Text>
              <Text style={_styles.thumnailSubTitle}>
                The average costs associated.
              </Text>
            </View>
            <View style={{ flexDirection: "row", gap: 12 }}>
              <View style={{ gap: 8, flex: 1 }}>
                <Text style={_styles.thumbnailLabelInput}>
                  Median {body_market?.bedrooms ?? 0} Bed Price
                </Text>
                <TextInput
                  value={formatDollar(bedRooms?.price ?? 0)}
                  style={_styles.thumbnailInput}
                  disableFullscreenUI
                />
              </View>
              <View style={{ gap: 8, flex: 1 }}>
                <Text style={_styles.thumbnailLabelInput}>
                  Median {body_market?.bedrooms ?? 0} Bed Rent
                </Text>
                <TextInput
                  value={formatDollar(bedRooms?.rent ?? 0)}
                  style={_styles.thumbnailInput}
                  disableFullscreenUI
                />
              </View>
            </View>
            <BlurView
              tint="dark"
              intensity={3}
              style={{
                position: "absolute",
                height: "100%",
                width: "100%",
                zIndex: -1,
              }}
            />
          </ImageBackground>
        </View>
        <View style={{ flex: 1, gap: 22 }}>
          <View>
            <Text style={_styles.titleDeposit}>Buy cost at 12% deposit</Text>
            <Text
              style={[styles.textSection, { marginTop: 2, marginBottom: 8 }]}>
              Deposit + Buying cost
            </Text>
            <TextInput
              value={formatDollar(bedRooms?.buyingCost12?.toFixed(0) ?? 0)}
              style={_styles.inputDeposit}
            />
          </View>
          <View>
            <Text style={_styles.titleDeposit}>Buy cost at 20% deposit</Text>
            <Text
              style={[styles.textSection, { marginTop: 2, marginBottom: 8 }]}>
              Deposit + Buying cost
            </Text>
            <TextInput
              value={formatDollar(bedRooms?.buyingCost20?.toFixed(0) ?? 0)}
              style={_styles.inputDeposit}
            />
          </View>
        </View>
        <View style={{ flex: 1, gap: 22 }}>
          <View>
            <Text style={_styles.titleDeposit}>Hold cost at 12% deposit</Text>
            <Text
              style={[styles.textSection, { marginTop: 2, marginBottom: 8 }]}>
              Loan Repayments
            </Text>
            <TextInput
              value={`${formatDollar(
                bedRooms?.repayment12?.toFixed(0) ?? 0
              )} per year`}
              style={_styles.inputDeposit}
            />
          </View>
          <View>
            <Text style={_styles.titleDeposit}>Hold cost at 20% deposit</Text>
            <Text
              style={[styles.textSection, { marginTop: 2, marginBottom: 8 }]}>
              Loan Repayments
            </Text>
            <TextInput
              value={`${formatDollar(
                bedRooms?.repayment20?.toFixed(0) ?? 0
              )} per year`}
              style={_styles.inputDeposit}
            />
          </View>
        </View>
      </View>
      {loadingHostorical ? (
        <ActivityIndicator color={MAIN_COLORS.PRIMARY_COLOR} size="large" />
      ) : historicals.length > 0 ? (
        <FlastList
          title={"Property Format Historical Data"}
          widthItem={368}
          styleContainer={{ gap: 16 }}
          paginate={historicals?.length > 3}
          data={historicals}
          renderItem={({ item, index }) => {
            const result = {
              ...item,
              data: getEvenlySpacedWithEnds(item),
            };

            return (
              <View style={_styles.chartItem} key={index}>
                <View style={_styles.headerChart}>
                  <View style={_styles.headerDivider} />
                  <View style={_styles.headerCenter}>
                    <Text style={_styles.headerCenterText}>{item?.title}</Text>
                  </View>
                  <View style={_styles.headerDivider} />
                </View>
                <RenderChart width={"100%"} height={300} result={result} />
                <View style={_styles.footerChart}>
                  <View style={_styles.footerChartDot} />
                  <Text style={_styles.footerChartText}>
                    {body_market?.bedrooms ?? 0} Bedroom House
                  </Text>
                </View>
              </View>
            );
          }}
        />
      ) : null}
    </View>
  );
};

export const RenderChart = ({ width, height, result }) => {
  const chartRef = useRef(null);

  const isValueEqual = result?.data?.every(
    (item) => item?.y === result?.data?.[0]?.y && item?.y !== 0
  );

  const minY = isValueEqual
    ? Math.min(...result?.data?.map(({ y }) => y)) * 0.95
    : Math.min(...result?.data?.map(({ y }) => y));
  const maxY = Math.max(...result?.data?.map(({ y }) => y));

  const options = {
    layout: {
      padding: {
        left: 12,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,
    hover: { mode: "index", intersect: false },
    tooltips: { mode: "index", intersect: false },
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: false,
        position: "nearest",
        cornerRadius: 20,
        callbacks: {
          label: (value) => {
            const formattedValue = result.formatter(
              value.raw.y.toFixed(result?.unit)
            );
            return `${formattedValue}${result?.ySuffix || ""}`;
          },
        },
        external: (context) =>
          externalTooltipHandlerNew(
            context,
            { left: 8, right: 8, top: 0, bottom: 0 },
            "46px"
          ),
      },
    },
    scales: {
      x: {
        font: { family: MAIN_FONT.regular, size: 12 },
        grid: { display: false },
        border: { color: "#3F4448" },
      },
      y: {
        border: { dash: [4, 4], color: "#3F4448" },
        grid: {
          drawTicks: false,
          color: function (context) {
            if (context.index === 3) return "rgba(0,0,0,0)";
            return "#3F4448";
          },
        },
        beginAtZero: false,
        min: minY * 0.9,
        max: maxY + maxY * 0.1,
        ticks: {
          display: false,
          maxTicksLimit: 5,
          stepSize: (maxY + maxY * 0.1 - minY * 0.9) / 5,
        },
      },
      y1: {
        stacked: true,
        position: "right",
        grid: { display: false },
        ticks: { display: false },
        border: { color: "#3F4448" },
      },
    },
  };

  const data = {
    datasets: [
      {
        data: result.data || [],
        backgroundColor: (context) => {
          const bgColor = [
            "rgba(250, 191, 1, 0.3)",
            "rgba(250, 191, 1, 0.15)",
            "rgba(250, 191, 1, 0.05)",
          ];
          if (!context.chart.chartArea) return;
          const {
            ctx,
            chartArea: { top, bottom },
          } = context.chart;
          const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
          gradientBg.addColorStop(0.1, bgColor[0]);
          gradientBg.addColorStop(0.5, bgColor[1]);
          gradientBg.addColorStop(1, bgColor[2]);
          return gradientBg;
        },
        borderColor: MAIN_COLORS.PRIMARY_COLOR,
        pointBorderWidth: 5.5,
        pointBackgroundColor: "#D3B100",
        pointRadius: ({ index }) => 0,
        pointHoverRadius: 6,
        lineTension: 0.5,
        borderWidth: 1,
        fill: true,
        spanGaps: true,
        clip: {
          left: false,
          right: false,
          top: false,
          bottom: false,
        },
      },
    ],
  };

  const plugins = {
    afterDraw: function (chart, easing) {
      if (chart?.tooltip?._active && chart?.tooltip?._active?.length) {
        const activePoint = chart.tooltip._active[0];
        const ctx = chart.ctx;
        const x = activePoint.element.x;
        const topY = chart.scales.y.chart.tooltip.caretY + 10;
        const bottomY = chart.scales.y.bottom;

        ctx.save();
        ctx.beginPath();
        ctx.moveTo(x, topY);
        ctx.lineTo(x, bottomY);
        ctx.lineWidth = 1;
        ctx.strokeStyle = MAIN_COLORS.PRIMARY_COLOR;
        ctx.stroke();
        ctx.restore();
      }
    },
  };

  useEffect(() => {
    const chart = chartRef.current;
    chart?.tooltip?.setActiveElements([]);
    chart?.update();
  }, []);

  return (
    <View style={{ width: width, height: height }}>
      <Line
        options={options}
        style={{ marginTop: 12, marginBottom: 12 }}
        data={data}
        ref={chartRef}
        plugins={[plugins]}
      />
    </View>
  );
};

const _styles = StyleSheet.create({
  subTitleSection: {
    fontSize: FONT_SIZE.large,
    fontWeight: FONT_WEIGHT.bold,
    lineHeight: 24,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.semi,
    textTransform: "capitalize",
  },

  rowGap16: {
    flexDirection: "row",
    gap: 16,
  },
  gap: { gap: 16 },

  chartItem: {
    borderWidth: 1,
    borderColor: "#4B555D99",
    borderRadius: 10,
    width: 368,
    paddingTop: 12,
    paddingBottom: 22,
    paddingHorizontal: 4,
  },

  footerChart: {
    flexDirection: "row",
    gap: 6,
    alignItems: "center",
    justifyContent: "center",
  },

  footerChartDot: {
    width: 9,
    height: 9,
    backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
    borderRadius: 4.5,
  },

  footerChartText: {
    fontSize: 10,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontWeight: 400,
    lineHeight: 15,
    fontFamily: MAIN_FONT.regular,
  },

  headerChart: {
    flexDirection: "row",
    gap: 12,
    alignItems: "center",
    paddingHorizontal: 13,
  },

  headerDivider: {
    height: 1,
    flex: 1,
    backgroundColor: "#4B555D99",
  },

  headerCenter: {
    paddingHorizontal: 16,
    paddingVertical: 6,
    borderWidth: 1,
    backgroundColor: "#19232B80",
    borderColor: "#4B555D99",
    borderRadius: 36,
  },

  headerCenterText: {
    lineHeight: 21,
    fontSize: FONT_SIZE.medium,
    fontWeight: FONT_WEIGHT.bold,
    color: "#D3B100",
    fontFamily: MAIN_FONT.regular,
  },

  backgroudImage: {
    width: "100%",
    height: "100%",
    resizeMode: "cover",
    padding: 22,
    display: "flex",
    justifyContent: "space-between",
    position: "relative",
  },

  thumnailSubTitle: {
    fontSize: FONT_SIZE.medium,
    lineHeight: 21,
    fontWeight: FONT_WEIGHT.medium,
    color: "#CFCFCF",
    fontFamily: MAIN_FONT.regular,
  },

  thumbnailLabelInput: {
    fontSize: 12,
    fontWeight: FONT_WEIGHT.medium,
    lineHeight: 18,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.regular,
  },

  thumbnailInput: {
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: "#4B555D99",
    backgroundColor: "#19232BD9",
    color: MAIN_COLORS.TEXT_LIGHT,
    fontWeight: FONT_WEIGHT.bold,
    fontSize: FONT_SIZE.medium,
    fontFamily: MAIN_FONT.semi,
  },

  titleDeposit: {
    fontSize: FONT_SIZE.small,
    lineHeight: 18,
    fontWeight: FONT_WEIGHT.bold,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.semi,
  },

  inputDeposit: {
    width: "100%",
    borderWidth: 1,
    borderColor: "#4B555D99",
    paddingHorizontal: 12,
    paddingVertical: 8,
    borderRadius: 5,
    fontSize: FONT_SIZE.medium,
    fontWeight: FONT_WEIGHT.medium,
    lineHeight: 21,
    color: MAIN_COLORS.PRIMARY_COLOR,
    fontFamily: MAIN_FONT.regular,
  },
});

export default memo(PropertyFormatData);
