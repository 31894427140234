import moment from "moment";
import React, { memo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  View,
  StyleSheet,
  Image,
  Text,
  ScrollView,
  TouchableOpacity,
  Linking,
  TextInput,
} from "react-native";
import { withNavigation } from "react-navigation";
import OutsideClickHandler from "react-outside-click-handler";
import { toastConfig } from "../../configs/toastConfig";
import Toast from "react-native-toast-message";

//Function
import { MAIN_COLORS } from "../../Utility/Colors";
import { setCurrentScreen } from "../../Reducer/tia";
import { setShowCalendar } from "../../Reducer/portfolio-roadmap";
import { FONT_WEIGHT, FONT_SIZE, MAIN_FONT } from "../../Utility/Fonts/font";
import ModalBase from "../../components/Modal";
import ChangePassword from "./ChangePassword";
import UserProfile from "./UserProfile";
import { clearAsyncStorage, setAsyncStorage } from "../../Action/asyncCtrl";
import { changePasswordUserAccount, signOutAccount } from "../../Clickup/api";

const Menu = ({ children, navigation }) => {
  const dayOfWeek = moment().format("dddd");
  const today = moment().format("MMMM DD, YYYY");
  const [open, setOpen] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [isValidProfile, setIsValidProfile] = useState(false);
  const submitProfileRef = useRef(null);
  const [openPassword, setOpenPassword] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const [formPassword, setFormPassword] = useState({
    password: "",
    newPassword: "",
    confirmPassword: "",
  });

  const { currentScreen, client, tokens } = useSelector((state) => state.TIA);
  const { showCalendar } = useSelector((state) => state.PORTFOLIO_ROADMAP);

  const getInitial = (name) => (name ? name.charAt(0).toUpperCase() : "");

  const dispatch = useDispatch();

  const updateFormPassword = (newData) => {
    setFormPassword((prevState) => ({
      ...prevState,
      ...newData,
    }));
  };

  const handleSaveProfile = () => {
    if (submitProfileRef.current) {
      submitProfileRef.current.submit();
    }
  };

  const handleSavePassword = async () => {
    const { password: old_password, newPassword: new_password } = formPassword;

    const email = client?.email;

    if (!email) {
      console.error("Email not found");
      return;
    }

    try {
      const result = await changePasswordUserAccount(
        {
          email: email,
          old_password: old_password,
          new_password: new_password,
        },
        tokens?.access
      );

      if (result?.status === 200) {
        setOpenPassword(false);
        Toast.show({
          type: "success",
          text1: result?.message,
          position: "top",
          visibilityTime: 3000,
        });
      } else {
        setOpenPassword(false);
        Toast.show({
          type: "error",
          text1: result?.exception,
          position: "top",
          visibilityTime: 3000,
        });
      }
    } catch (error) {
      console.error("Error while changing password:", error);
    }
  };

  const handleLogout = async () => {
    try {
      const result = await signOutAccount();
      if (result?.status !== 200) {
        return showMessage({
          message: result?.exception?.detail?.detail || "Logout failed!",
          type: "info",
          color: "#FFF",
          backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
          duration: 4000,
        });
      }

      clearAsyncStorage();
      if (client?.isRememberPassword) {
        setAsyncStorage("emailAccount", client?.email);
      }
      navigation.navigate("LoginScreen");
      dispatch(setCurrentScreen("Home"));
    } catch (error) {
      console.log(error.message);
    } finally {
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <View>
          <Text style={styles.textDay}>{dayOfWeek}</Text>
          <Text style={styles.textMonthAndYear}>{today}</Text>
        </View>
        <View style={styles.headerRight}>
          {(currentScreen === "Home" || currentScreen === "Notifications") && (
            <>
              <TouchableOpacity
                onPress={() => dispatch(setShowCalendar(!showCalendar))}
              >
                <Image
                  style={styles.icon}
                  source={require("../../assets/home/calendar_dot.svg")}
                />
              </TouchableOpacity>
              <View style={styles.headerDivider} />
            </>
          )}

          <TouchableOpacity
            onPress={() => dispatch(setCurrentScreen("Notifications"))}
          >
            <Image
              style={styles.icon}
              source={require("../../assets/Light/Notification.svg")}
            />
          </TouchableOpacity>
          <View style={styles.headerDivider} />
          <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
            <TouchableOpacity
              onPress={() => setOpen(!open)}
              style={[styles.avatarCircle, { width: 42, height: 42 }]}
            >
              <Text style={[styles.textAvatarCircle, { fontSize: 18 }]}>
                {getInitial(client?.first_name)}
              </Text>
            </TouchableOpacity>
            <View style={[styles.boxPopup, !open && { display: "none" }]}>
              <View style={styles.avatarCircle}>
                <Text style={styles.textAvatarCircle}>
                  {getInitial(client?.first_name)}
                </Text>
              </View>
              <Text style={styles.textEmail}>{client?.email}</Text>
              <View style={styles.divider} />
              <TouchableOpacity
                style={styles.btnHelpCenter}
                onPress={() => setOpenProfile(true)}
              >
                <Image
                  style={{ width: 24, height: 24 }}
                  source={require("../../assets/home/user-profile.svg")}
                />
                <Text style={styles.textOption}>User Profile</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.btnHelpCenter}
                onPress={() => setOpenPassword(true)}
              >
                <Image
                  style={{ width: 24, height: 24 }}
                  source={require("../../assets/home/password-profile.svg")}
                />
                <Text style={styles.textOption}>Change Password</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.btnHelpCenter}
                onPress={() => {
                  Linking.openURL("mailto:hello@theinvestorsagency.com.au");
                }}
              >
                <Image
                  style={{ width: 24, height: 24 }}
                  source={require("../../assets/home/mail_white.png")}
                />
                <Text style={styles.textOption}>Support</Text>
              </TouchableOpacity>
              <TouchableOpacity style={styles.btnLogout} onPress={handleLogout}>
                <Image
                  style={{ width: 24, height: 24 }}
                  source={require("../../assets/home/logout.svg")}
                />
                <Text style={styles.textLogout}>Logout</Text>
              </TouchableOpacity>
            </View>
          </OutsideClickHandler>
        </View>
      </View>
      <View
        style={{
          position: "fixed",
          right: 120,
          top: 0,
          width: 150,
          zIndex: 9999,
        }}
      >
        <Toast config={toastConfig} />
      </View>
      <ScrollView showsVerticalScrollIndicator={false} style={{ zIndex: -1 }}>
        <View style={styles.content}>{children}</View>
      </ScrollView>

      {/* Modal user profile */}
      <UserProfile open={openProfile} onClose={() => setOpenProfile(false)} />

      {/* Modal change password */}
      <ModalBase
        open={openPassword}
        btnSave="Save"
        btnCancel="Cancel"
        disableSave={!isValid}
        title={
          <View>
            <Text
              style={{
                fontWeight: 600,
                fontSize: 20,
                fontFamily: MAIN_FONT.semi,
                color: MAIN_COLORS.TEXT_LIGHT,
                flex: 1,
              }}
            >
              Change Password
            </Text>
            <Text
              style={{
                fontWeight: 400,
                fontSize: 14,
                fontFamily: MAIN_FONT.regular,
                color: MAIN_COLORS.G600,
                flex: 1,
                marginTop: 4,
              }}
            >
              Password must be 8 to 30 characters and include at least one
              capital letter, one number and one special character.
            </Text>
          </View>
        }
        onSave={handleSavePassword}
        onClose={() => setOpenPassword(false)}
        size="m-large"
        styleModal={{ padding: 18, borderRadius: 12 }}
        styleBtnClose={{
          width: 28,
          height: 28,
          borderRadius: 5,
          padding: 4,
          backgroundColor: "#7D888F26",
        }}
        sizeBtnClose={{ width: 10, height: 10 }}
        closePrimary={true}
      >
        <ChangePassword
          formPassword={formPassword}
          updateFormPassword={updateFormPassword}
          setIsFormValid={setIsValid}
        />
      </ModalBase>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 423,
    borderLeftWidth: 1,
    borderLeftColor: MAIN_COLORS.BORDER_COLOR,
    backgroundColor: MAIN_COLORS.BACKGROUND_BLACK,
  },
  header: {
    height: 95,
    paddingHorizontal: 32,
    paddingVertical: 25,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  headerRight: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: 18,
  },
  content: { paddingHorizontal: 32, paddingVertical: 22 },
  textDay: {
    fontSize: FONT_SIZE.medium,
    fontWeight: FONT_WEIGHT.medium,
    lightHeight: 21,
    color: MAIN_COLORS.G600,
    fontFamily: MAIN_FONT.regular,
  },
  textMonthAndYear: {
    fontSize: FONT_SIZE.large,
    fontWeight: FONT_WEIGHT.medium,
    lightHeight: 24,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.regular,
  },
  icon: {
    width: 30,
    height: 30,
  },

  headerAvatar: {
    width: 42,
    height: 42,
    borderRadius: "50%",
    overflow: "hidden",
  },

  headerDivider: {
    width: 1,
    height: 32,
    backgroundColor: MAIN_COLORS.BORDER_COLOR,
  },

  boxPopup: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 16,
    paddingHorizontal: 16,
    backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
    borderRadius: 10,
    position: "absolute",
    top: 60,
    right: 0,
    width: 358,
    shadowColor: "#000",
    shadowOffset: { width: 2, height: 2 },
    shadowOpacity: 0.7,
    shadowRadius: 15,
    elevation: 10,
  },

  avatarCircle: {
    justifyContent: "center",
    alignItems: "center",
    width: 64,
    height: 64,
    borderRadius: "50%",
    backgroundColor: "#D3B100",
  },

  textAvatarCircle: {
    fontSize: 27.43,
    color: MAIN_COLORS.BACKGROUND_MAIN,
    fontWeight: 600,
    fontFamily: MAIN_FONT.semi,
  },

  textEmail: {
    fontSize: 16,
    fontWeight: 600,
    color: MAIN_COLORS.TEXT_LIGHT,
    marginTop: 12,
    fontFamily: MAIN_FONT.semi,
  },

  divider: {
    height: 1,
    backgroundColor: MAIN_COLORS.BORDER_COLOR,
    width: "100%",
    marginVertical: 16,
  },

  btnHelpCenter: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    paddingVertical: 11,
    paddingHorizontal: 11,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: "#FFFFFF",
    backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
    marginBottom: 16,
  },

  btnLogout: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    paddingVertical: 11,
    paddingHorizontal: 11,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: "#FD4438",
    backgroundColor: "#FD4438",
  },

  textOption: {
    fontSize: 14,
    fontWeight: 400,
    color: "#FFFFFF",
    marginLeft: 8,
    fontFamily: MAIN_FONT.regular,
  },

  textLogout: {
    fontSize: 14,
    fontWeight: 600,
    color: "#FFFFFF",
    marginLeft: 2,
    fontFamily: MAIN_FONT.semi,
  },
});

export default memo(withNavigation(Menu));
