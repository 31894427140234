import { BlurView } from "expo-blur";
import React, { memo, useState } from "react";
import {
  TouchableOpacity,
  StyleSheet,
  View,
  Image,
  Text,
  Pressable,
} from "react-native";

//Functions
import { MAIN_COLORS } from "../../Colors";
import { FONT_SIZE, FONT_WEIGHT, MAIN_FONT } from "../../Fonts/font";

//Components
import Time from "../../../components/Time";

const SearchSuggestCard = ({
  item,
  style,
  close,
  partner,
  onPress,
  onRemove,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Pressable
      onHoverIn={() => setIsHovered(true)}
      onHoverOut={() => setIsHovered(false)}>
      <TouchableOpacity style={[styles.container, style]} onPress={onPress}>
        {isHovered && close && (
          <TouchableOpacity style={styles.btnRemove} onPress={onRemove}>
            <BlurView intensity={10} style={styles.btnRemoveBlur}>
              <Image
                style={{ width: 10, height: 10 }}
                source={require("../../../assets/home/close.png")}
              />
            </BlurView>
          </TouchableOpacity>
        )}
        <View
          style={{
            flexDirection: "row",
            alignItems: "flex-start",
            gap: 12,
            padding: 12,
          }}>
          <View style={{ borderRadius: 6, overflow: "hidden" }}>
            <Image
              style={{ width: 46, height: 46, transform: [{ scale: 2.5 }] }}
              resizeMode="cover"
              source={
                item.image ||
                require("../../../assets/home/portfolio_image_default.png")
              }
            />
          </View>
          <View style={{ gap: 4, flex: 1 }}>
            <Text style={styles.portfolioName}>
              {`${item?.Suburb}, ${item?.state} ${item?.post_code}`}
            </Text>
            <View style={styles.rowCenterBetween}>
              <Time date={item?.date_updated * 1} />
            </View>
          </View>
        </View>
      </TouchableOpacity>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "relative",
    borderRadius: 10,
    backgroundColor: "transparent",
    overflow: "hidden",
    borderWidth: 1,
    borderColor: "#4B555D99",
    backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
  },
  portfolioName: {
    fontSize: FONT_SIZE.large,
    lineHeight: 24,
    fontWeight: FONT_WEIGHT.bold,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.semi,
  },
  rowCenterBetween: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 6,
  },
  btnRemove: {
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 2,
    backgroundColor: "#333D4473",
    width: 36,
    height: 36,
    borderBottomLeftRadius: 10.24,
    overflow: "hidden",
  },
  btnRemoveBlur: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottomLeftRadius: 10.24,
  },
});

export default memo(SearchSuggestCard);
