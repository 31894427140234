import moment, { min } from "moment";

export const titles = [
  "Buying Profile",

  "Search Process",

  "Market Selection",

  "Property Selection",

  "Property Walk-through",

  "Contract Exchanged",

  "Building & Pest Booked",

  "Your Property Manager",

  "Building & Pest Report",

  "Unconditional",

  "Settlment",

  // "Market Updates",
];

export const getDateTime = (time, minutes, flag) => {
  if (minutes >= 2) {
    time = new Date(time).getTime() + 2 * 60000;
  }

  return (
    new Date(time).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    }) +
    "  -  " +
    moment(new Date(time)).format("DD/MM/YYYY")
  );
};

export const format = (n, decimals = true, fixedDecimals = null) => {
  let val = Math.round(Number(n) * 100) / 100;

  if (fixedDecimals !== null) {
    val = Number(val).toFixed(fixedDecimals);
  }

  let parts = val.toString().split(".");
  let result = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  if (decimals && parts[1]) {
    result += "." + parts[1];
  } else if (fixedDecimals !== null && !decimals) {
    result = result.split(".")[0];
  }

  return result;
};

export const zohoTitles = [
  "Client Profile Call Summary",

  "Begin Property Search",

  "Collecting Property Data",

  "Property Preso Sent to Client",

  "Walk through Preso Sent to Client",

  "Introduce Associates",

  "Order Building & Pest",

  "Property Manager Intro",

  "Negotiate Building & Pest",

  "Unconditional",

  "Settled",
];
export const reports = (property, index) => {
  const reports = [
    [
      {
        title: "Target Price",
        value: property["TARGET PRICE"],
      },
      {
        title: "Deposit @" + property["Deposit Percent"] + "%",
        value:
          Number(property["TARGET PRICE"]) *
          0.01 *
          Number(property["Deposit Percent"]),
      },
      {
        title: "Loan Amount",
        value:
          Number(property["TARGET PRICE"]) -
          Number(property["TARGET PRICE"]) *
          0.01 *
          Number(property["Deposit Percent"]),
      },
      {
        title: "Stamp duty",
        value: property["Stamp Duty"],
      },

      {
        title: "Pest & building",
        value: property["Building & Pest"],
      },

      {
        title: "Solicitor",
        value: property["Solicitors Fees"],
      },

      {
        title: "Bank App.Fees",
        value: property["Bank APP. Fee"],
      },
      {
        title: "Cosmetic uplift",
        value: property["Property Improvement Costs"],
      },
      {
        title: "LMI",
        value: property["LMI"],
      },

      {
        title: "Total purchase costs",
        value:
          Number(property["TARGET PRICE"]) *
          Number(property["Deposit Percent"]) *
          0.01 +
          Number(property["Stamp Duty"]) +
          Number(property["Building & Pest"]) +
          Number(property["Solicitors Fees"]) +
          Number(property["Bank APP. Fee"]) +
          Number(property["LMI"]),
      },
    ],
    [
      {
        title: "Council rates p/a",
        value: property["Council Rates p/a $"],
      },
      {
        title: "Insurance cost p/a",
        value: property["Insurance Costs p/a $"],
      },
      {
        title: "Maintenance p/a",
        value: property["Maintenance"],
      },
      {
        title: "Utilities p/a",
        value: property["Utilities"],
      },
      {
        title: `${property["Interest Only Loan Percent"] ? "I/O" : "P&I"
          } Rate @${property["Interest Only Loan Percent"] || property["P&I Loan Percent"]
          }% p/m`,
        value:
          (((100 - Number(property["Deposit Percent"])) / 100) *
            Number(property["TARGET PRICE"]) *
            Number(property["Interest Only Loan Percent"])) /
          100 /
          12 || property["P&I Repayment Amount"],
      },
      {
        title: "Approx.Bank Rate  p/a",
        value: property["Approx. Bank Charges"],
      },
      {
        title: "Management fee at " + property["Management %"] + "%  p/a",
        value:
          property["APPRAISED RENTAL RATE P/W $"] *
          52 *
          property["Management %"] *
          0.01,
      },
      {
        title: "Approx. annual expense",
        value:
          (property["Interest Only Loan Percent"]
            ? (100 - Number(property["Deposit Percent"])) *
            0.01 *
            Number(property["TARGET PRICE"]) *
            Number(property["Interest Only Loan Percent"]) *
            0.01
            : 0) +
          (property["P&I Loan Percent"]
            ? Number(property["P&I Repayment Amount"]) * 12
            : 0) +
          Number(property["Council Rates p/a $"]) +
          Number(property["Insurance Costs p/a $"]) +
          Number(property["Maintenance"]) +
          Number(property["Utilities"]) +
          Number(property["Approx. Bank Charges"]) +
          Number(property["APPRAISED RENTAL RATE P/W $"]) *
          52 *
          Number(property["Management %"]) *
          0.01,
      },
    ],
    [
      {
        title: "Rental rates per week",
        value: property["APPRAISED RENTAL RATE P/W $"],
      },
      {
        title: "Annual gross income",
        value: Number(property["APPRAISED RENTAL RATE P/W $"]) * 52,
      },
      {
        title: "Annual gross yield",
        value:
          ((property["APPRAISED RENTAL RATE P/W $"] * 52) /
            property["TARGET PRICE"]) *
          100,
      },

      {
        title: "Annual net yield",
        value:
          ((Number(property["APPRAISED RENTAL RATE P/W $"]) * 52 -
            (Number(property["Interest Only Loan Percent"])
              ? (((100 - Number(property["Deposit Percent"])) / 100) *
                Number(property["TARGET PRICE"]) *
                Number(property["Interest Only Loan Percent"])) /
              100
              : 0) -
            (Number(property["P&I Loan Percent"])
              ? Number(property["P&I Repayment Amount"]) * 12
              : 0) -
            Number(property["Council Rates p/a $"]) -
            Number(property["Insurance Costs p/a $"]) -
            Number(property["Maintenance"]) -
            Number(property["Utilities"]) -
            Number(property["Approx. Bank Charges"]) -
            Number(property["APPRAISED RENTAL RATE P/W $"]) *
            52 *
            (Number(property["Management %"]) / 100)) /
            Number(property["TARGET PRICE"])) *
          100,
      },
      {
        bold: true,
        divider: true,
        title: "Depreciation potential",
        value: property["Depreciation Potential"],
      },
      {
        bold: true,
        highlight: true,
        title: "Potential Growth at 8% p/a",
        value: Number(property["TARGET PRICE"]) * 0.08,
      },
      {
        bold: true,
        highlight: true,
        title: "Potential Growth at 12% p/a",
        value: Number(property["TARGET PRICE"]) * 0.12,
      },
      {
        bold: true,
        highlight: true,
        title: "Potential Growth at 16% p/a",
        value: Number(property["TARGET PRICE"]) * 0.16,
      },
      {
        title: "Annual net cash flow",
        value:
          Number(property["APPRAISED RENTAL RATE P/W $"]) * 52 -
          (Number(property["Interest Only Loan Percent"])
            ? (((100 - Number(property["Deposit Percent"])) / 100) *
              Number(property["TARGET PRICE"]) *
              Number(property["Interest Only Loan Percent"])) /
            100
            : 0) -
          (Number(property["P&I Loan Percent"])
            ? Number(property["P&I Repayment Amount"]) * 12
            : 0) -
          Number(property["Council Rates p/a $"]) -
          Number(property["Insurance Costs p/a $"]) -
          Number(property["Maintenance"]) -
          Number(property["Utilities"]) -
          Number(property["Approx. Bank Charges"]) -
          (Number(property["APPRAISED RENTAL RATE P/W $"]) *
            52 *
            Number(property["Management %"])) /
          100,
      },
    ],
  ];

  return reports;
};

export const assesments = (property) => {
  const assements = [
    {
      title: "Are there easements on the property?",
      description: property["EASEMENTS"] === 0 ? "Yes" : "No",
      url: property["EASEMENT URL"],
      explanation:
        "Easements are areas which need to be accessible by council or anyone with a right to access a portion or part of land. An easement could be related to sewage piping, gas lines or electrical works.",
    },
    {
      title: "Property Boundary Map",
      description: property["Boundary Map"] === 0 ? "Yes" : "No",
      url: property["Boundary Map URL"],
      explanation:
        "Property and land boundary maps give the outline of where the borders of different states, regions and properties exist. These land boundaries define who owns what property. Land boundary maps can extend past regions and into states, outlining the land ownership and where the land's boundary lines extend.",
    },
    {
      title: "Is this property connected to council sewerage?",
      description:
        property["Council Sewer To Property?"] === 0
          ? "Yes"
          : property["Council Sewer To Property?"] === 1
            ? "No"
            : "Unconfirmed",
      url: property["Council Sewer Map URL"],
      explanation:
        "The council issues drainage diagrams in its role as the local sewerage authority. Drainage Diagrams will state whether the property is, or is not connected to Council's sewer infrastructure. If connected, a diagram will be provided indicating the location of the sewer in relation to the land.",
    },
    {
      title: "Water Mains to Property?",
      description:
        property["Water Mains to Property?"] === 0
          ? "Yes"
          : property["Water Mains to Property?"] === 1
            ? "No"
            : "Unconfirmed",
      url: property["Water Mains Map URL"],
      explanation:
        "Water Mains Maps show how the water supply system serves a particular property within a building development. The information shows the location of water pipes over a large area and highlights the water supply zone larger water mains within the supply system.",
    },
    {
      title: "Electricity transmission line map",
      description:
        property["Electricity Transmission Lines?"] === 0
          ? "Acceptable"
          : "Not acceptable",
      url: property["Electricity Transmission Lines Map URL"],
      explanation:
        "Electric power transmission is the bulk movement of electrical energy from a generating site, such as a power plant, to an electrical substation. The interconnected lines that facilitate this movement form a transmission network. The electricity Transmission line map above shows the location of Electricity Transmission lines near the presented property.",
    },
    {
      title: "State/Public Housing Radius Check?",
      description:
        property["State / Public Housing Radius Check?"] === 0
          ? "Acceptable"
          : "At Client Discretion",
      url: property["State / Public Housing Map"],
      explanation:
        "Public housing is a form of housing tenure in which the property is usually owned by a government authority, either central or local. Although the common goal of public housing is to provide affordable housing, the details, terminology, definitions of poverty, and other criteria for allocation vary within different contexts. The map above will show you any State/ Public housing close to the proposed property.",
    },
    {
      title: "Is the property located in a flood zone?",
      url: property["FLOOD ZONE URL"],
      description: property["PROPERTY IN FLOOD ZONE?"] === 0 ? "Yes" : "No",
      explanation:
        "Flood zones can be determined from river flood lines. In areas where the terrain is fairly flat and river banks overflow, properties which fall within these catchments may warrant higher insurance costs.",
    },
    {
      title: "How is this property zoned?",
      description: "",
      explanation: `Building and council regulation states that the dwelling on top of the land needs to be classified correctly for the type of structure that it is. This can also affect the permission for the usage of the land if looking to develop.`,
      detail: `This property's zoning is ${property["How is this property zoned?"]}`,
    },
    {
      title: "What date was this property put on market?",
      description: `${property["DATE ON MARKET"]}`,
      explanation: `There are 3 types of listing stages:\n\nOn-market, this is found on major portals\n\nOff-market, this is for sale, but with the listing agency only\n\nPre-market, this has been presented to us outside of the public`,
    },
    {
      title: "When was the property Built?",
      description: `PROPERTY WAS BUILT IN ${property["BUILD YEAR"]}`,
      explanation:
        "The build year should be considered when purchasing properties of different structure types. The price of the property can be reflective of the build year vs the structure type. Some structure types age better than others. At a certain price point, it is worth remembering that land is the appreciating asset, while the dwelling is the depreciating asset.",
    },
    {
      title: "Is the property leased, if so until when?",
      description: property["LEASE END DATE (IF APPLICABLE)"],
      explanation:
        "Tenure renewals dates need to be considered in rising markets. If the market is rising, your ability to increase rental rates will only be possible at the time the tenants lease period renews.",
    },
    {
      title: "What is the current rental rate?",
      description: `CURRENTLY RENTED FOR $${property["CURRENT RENTAL RATE P/W $"]}`,
      explanation:
        "Lease renewal rates cannot be changed inside of lease periods, for the benefit of the tenant.",
    },
  ];

  return assements;
};

const tasks = (deal) => {
  const tasks = [
    {
      title: "Buying Profile",
      videos: [],
      links: null,
      fileNames: ["App_Client_Profile", "App_TIA_Agreement"],
      files: [deal.Client_Profile, deal.Email_Agreement_URL],
      zohoTitle: "Client Profile Call Summary",
      description:
        "Welcome to the team! Your meeting notes and client profile form will be added soon, if not already yet.",
      message: `Hi ${deal.Client_App_Email}, thanks for completing your profile form. Your meeting notes from ${deal.Owner?.name} have been added below.\n\n${deal.Discovery_Meeting} \n\nYou can also view your profile form and The Investors Agency agreement on the link below.`,
    },
    {
      title: "Search Process",
      videos: [],
      links: null,
      files: [],
      zohoTitle: "Begin Property Search",
      description:
        "We’re about to kick things off and contact out associates and partners in markets that work best for you!",
      update:
        "Based on the discussions we've had, we're rounding things up to begin your search. This can take as little as a couple of days or as much as a couple of weeks. Each of our clients’ briefs is very specific to their requirements, matching this with the correct asset for maximum performance is our objective, which sadly sometimes cannot be rushed. Keep an eye out for more details to come soon.",
      message: `Hi ${deal.Client_App_Email}, this is just a quick note to let you know that the team have begun the search process and contacted associates within the markets which we are searching in for potential off markets and pre-market opportunities that will fit your buying profile.\n\nNo action from here is needed from you at this stage, keep an eye out on your notifications and we'll update you as your project progresses.\n\nTo streamline communications if you have any queries or concerns, your best point of contact will be our operations manager, Taliesha whoes contact details can be found below.\n\nOffice: 02-8385-5923\nEmail: taliesha@theinvestorsagency.com.au\n\nYour project manager is ${deal.Owner?.name} who can be reached at ${deal.Property_Manager_Phone} or over email at ${deal.Owner?.email}.\n\nFrom here, the search process may take as quick as a couple of days, up to a couple of weeks, which depends on what stock is available to support your portfolio.\n\nWe'll be in touch soon with an update, at which stage you will receive a market presentation and shortly after that, the property information and presentation.\n\nWelcome to the team and we look forward to servicing you.\n\nTIA`,
    },
    {
      title: "Market Selection",
      videos: [deal.App_Market_Video],
      links: deal.App_Market,
      linkNames: ["Market Presentation"],
      fileNames: [],
      files: [],
      zohoTitle: "Collecting Property Data",
      description:
        "The correct market selection is critical to the performance of your property. This task will explain exactly why that is.",
      update:
        "The right market for you may not be the right market for others. Making sure that future performance will support your cash or equity requirements, or a combination of the two is carefully considered by understanding the data behind the market. Vacancy rates, demographic demand, population movement, supporting economies, growth-driving projects, increasing yield indicators and many other factors are scrutinised before we carefully select our markets. Your market will be shown to you over a live link with a video explaining why we love it.",
      message: `Hi ${deal.Client_App_Email}, great news, we have found a property inside a market which suits your requirements. Below you will see a link to the market profile which will explain it's fundamental health profile. You will also see a link to a video presentation explaining these fundamentals.\n\nThis data and information will help you to understand where the market health has come from, it's current health status, and most importantly understanding what will be growing it into the future and the supporting factors around it.\n\nFollowing this, you will receive the information about the property which we feel is a great opportunity for you. Keep an eye on your notifications for the suggested property.`,
    },
    {
      title: "Property Selection",
      videos: [deal.App_BA_Video_Explainer],
      links: deal.Preso_Link,
      linkNames: ["Presentation Link"],
      files: [],
      zohoTitle: "Property Preso Sent to Client",
      description:
        "This is where you will find the information regarding the property which we feel is best suited for your investment needs.",
      update:
        "By the time you receive the next update, in most instances, we have spoken to the selling agent and potential property manager, run our initial due diligence, and calculated approximate costs associated to purchasing the property, holding the property and it’s potential profits. Keep in mind that as we are in many markets around the country, the suggested market in the task before may possibly change depending on the available stock.",
      message: `Hi ${deal.Client_App_Email}, here it is, your proposed property.\n\nBelow you will find all the property information, initial diligence and costs associated to purchasing the property, holding it and it's projected outcomes considering the costs involved and potential market growth.\n\nAs always time is of the essence and your soonest response is greatly appreciated. Once you've gone through this information, please click through to book your follow up call with ${deal.Owner?.name}.\n\nIf the calendar looks full, don't worry we'll see the booking come through and will prioritise a callback.`,
    },
    {
      title: "Property Walk-through",
      videos: deal.App_Videos?.split("\n"), //deal.App_BA_Video_Explainer.split(),
      fileNames: ["Rent_Appraisal_PDF_Link"],
      files: [deal.Rent_Appraisal_PDF_Link],
      links: null,
      zohoTitle: "Walk through Preso Sent to Client",
      description:
        "Our team on the ground will send you narrated walkthrough videos showcasing the property’s features and condition.",
      update:
        "We’re excited to show you around the property! On most occasions, the walkthrough videos are done by independent inspectors or agents who are not generally associated with the sale of the property. We do this to minimise Any conflict of interest, however in some instances when we do have strong relationships with the selling agents, and trust their management team, we may use one of the agency's property managers to help us through the inspection. This is not taken lightly and is only done when a strong trustworthy relationship is formed between our business and theirs. This also can be a benefit to the buyer, as it allows us to negotiate the management into the same company, which increases their rent-roll and potentially helps us to secure the property.",
      message: `Hi ${deal.Client_App_Email},\n\nThe below videos are of the property’s walkthrough with our agent on the ground.\n\nThe idea behind these videos is to give you a good understanding of the condition that the property is in so that we can decide on how to proceed with the purchase. We have also received the rent appraisal which we will be using to finance the purchase with the bank which will be inside this task.`,
    },
    {
      title: "Contract Exchanged",
      videos: [], //deal.App_Videos.split('\n'),
      links: null,
      fileNames: [
        // "Rent_Appraisal_PDF_Link",
        "Contract_Exchanged1",
      ],
      files: [
        // deal.Rent_Appraisal_PDF_Link
        deal.Contract_Exchanged1,
      ],
      zohoTitle: "Introduce Associates",
      description:
        "Depending on the state/territory and format of the purchase, this step may come sooner or later. Regardless, this means we’ve managed to secure the contract - Woohoo!",
      update:
        "The next update that you get in this task will be due to us winning the contract. Pending the state or territory and the construction of the contract, different rules apply which means that we may need to run further due diligence within a certain set time period, generally, this is the case and we would still need to go unconditional to secure the property. Right after the contract is exchanged, we will introduce all the required associates (eg solicitors, brokers, managers…etc) and begin the necessary diligence, like the building and pest inspections.",
      message: `Hi ${deal.Client_App_Email
        },\n\nGreat news, we've managed to secure the contract with our offer price and terms! Click the link to view the signed and dated contract.\n\nIMPORTANT: From here please could you make the deposit payment stated on the contract to the noted bank account, this will secure the purchase and allow us to move forward. We also advise Taking out building insurance on the property from this point on. Terry Sheer is a well-respected provider: https://www.terrischeer.com.au/landlord-insurance/ \n\nWe will take care of the rest, which includes introducing solicitors, brokers, property managers, building and pest inspectors and all other necessary associates. You will see this in your inbox shortly.\n\nBelow is a breakdown of the information inside the contract to take note of.\n\nPurchase price of $${deal.Offer_Amount_AUD
        } AUD  \nFinance clause expires on ${deal.Finance_Unconditional_Date &&
        moment(new Date(deal.Finance_Unconditional_Date)).format("DD/MM/YYYY")
        }\nBuilding & pest clause expires on ${deal.Unconditional_Date &&
        moment(new Date(deal.Unconditional_Date)).format("DD/MM/YYYY")
        }\nThe property is due to settle on ${deal.Settlement_Date &&
        moment(new Date(deal.Settlement_Date)).format("DD/MM/YYYY")
        }\n${deal.Special_Conditions
        }\n\nPlease stand by while we progress to the next steps, we will let you know as soon as further action is needed from you.`,
    },
    {
      title: "Building & Pest Booked",
      videos: [],
      links: null,
      files: [],
      zohoTitle: "Order Building & Pest",
      description:
        "Again, depending on the state/territory and format of the purchase, this step may come sooner or later. This step means we’ve booked in the B&P inspection is underway.",
      update: `Great news on exchanging the contract team, but we’re not yet done! As you can see in the task above, there is a date night in your building and pest inspection clause. We are booking in the inspector shortly so that they can go through the property and highlight any concerns.\n\nIMPORTANT! Please be aware that it is the inspector’s job to highlight any and all blemishes, issues and abnormalities about the property no matter how big or small they are. This means that once you receive the building and pest report there will most likely be an overwhelming amount of information of which some will appear defective. These reports can (with reason) be used in the negotiation to correct any issues or potentially be compensated for them, but this is not always the case and different states legislate laws differently. We have a lot of experience in what is considered a fair request and will be happy to guide down the path for the best outcome, when the time comes!`,
      message: `Hi ${deal.Client_App_Email
        },\n\nWe have booked in your building and pest inspection for ${deal.Suburb
        } on ${deal.B_P_Inspection_Date &&
        moment(new Date(deal.B_P_Inspection_Date)).format("DD/MM/YYYY")
        } with ${deal.Corporate_Partner_Contact_Referred_To?.name
        }. Once we have received the report, we'll assess it on our end and send it to you to review with our comments to consider.\n\nIMPORTANT: if you have never seen a building in pest report before, please be aware that it is the inspector’s job to point out every single issue about the property, no matter how big or small. Often these reports can seem concerning. We always advise contacting the inspector once you receive the report for clarity if you have any major concerns and to gauge the overall condition of the property.\n\nYou will be sent an invoice by the inspection company to make payment for. Please ensure this is made before the inspection date noted above.`,
    },
    {
      title: "Your Property Manager",
      videos: [],
      links: null,
      files: [],
      zohoTitle: "Property Manager Intro",
      description:
        "We have incredible associates all over the country and will line you up with an amazing property manager.",
      update:
        "As part of our service, we take care of introducing you to all the correct associates to not only purchase the property but manage it afterwards too. Soon we will send you information about your proposed property manager. Please be aware that there are no financial benefits for us in this relationship, we choose our partners because they are the best at what they do.",
      message: `Hi ${deal.Client_App_Email},\n\nWe've formed some strong relationships with some fantastic property managers in all the areas where we buy. The management of your property is equally as important as the purchase.\n\nWe’d like to introduce you to ${deal.Property_Manager?.name} who you can reach on ${deal.Property_Manager_Phone} or via email on ${deal.Property_Manager_Email}.\n\nWe are not affiliated to this agency financially, our recommendation is based off the confidence we have in their ability to manage your property.\n\nWe have sent an email to introduce you both. You can expect a reply soon from ${deal.Property_Manager?.name} Where all fees and onboarding processes will be explained to you.`,
    },
    {
      title: "Building & Pest Report", //"Building & Pest (Pt2)",
      videos: [],
      links: null,

      fileNames: ["Building_Report", "P_B_Report"],
      files: [deal.Building_Report, deal.P_B_Report],
      zohoTitle: "Negotiate Building & Pest",
      description:
        "Your building and pest inspection report will be reviewed, and recommendations given by our team.",
      update:
        "In the coming days we are expecting to receive your building and pest inspection report as promised and we will revise and advise soon after 😊",
      message: `Hi ${deal.Client_App_Email},\n\nWe have received the building and pest report after the inspection which you can open below.\n\nPlease find  ${deal.Owner?.name}’s notes below. Once you've gone through these, please book a time to have a chat about these with ${deal.Owner?.name}.\n\n"${deal.Building_Pest_Notes}"\n\nYou can also find the inspector's contact details on the PDF, please reach out directly to them if you have any immediate concerns.`,
    },
    // {
    //   title: "Unconditional",
    //   videos: [],
    //   links: null,
    //   files: [],
    //   // fileNames: ["Building_Report", "P_B_Report"],
    //   // files: [deal.Building_Report, deal.P_B_Report],
    //   zohoTitle: "Negotiate Building & Pest",
    //   description: "At this point the purchase and negotiation is unconditional and all prior due diligence has passes or been approved.",
    //   update: "Following the review of your building and pest report and pending the outcome of any requests, if any, we’ll hopefully proceed to the unconditional date shown on the contract. During this time, we also need to make sure that the finances are correct if we have any conditions relating to that on the contract. Generally, not much is required from you at this stage. We are waiting to hear back from the selling agents, or we are waiting out the remainder of the time for the unconditional period. ",
    //   message: `Hi ${deal.Client_App_Email},\n\nYour purchase has just gone unconditional, congratulations it's all downhill from here.\n\nFrom here on further finance admin will be going on in the background and your broker or bank partner may be in contact with you.\n\nAt this stage, our team is on standby until settlement, or until further help is requested from one of the associates or yourself. Please feel free to reach out during this period with any questions or concerns`
    // },
    {
      title: "Unconditional",
      videos: [],
      links: null,
      files: [],
      zohoTitle: "Unconditional",
      description:
        "CONGRATULATIONS! That’s a wrap… but its not the end just yet! 😊",
      message:
        "From here on, we are on standby to support the solicitors and mortgage brokers or bankers who are managing the purchase’s finance movement so that we can settle on the agreed contract date. we'll be in touch if we need any further information from you, but keep an eye out because more than likely the solicitors and bankers will be reaching out if they have any questions.",
      update: null,
      // message: `Hi ${deal.Client_App_Email}, CONGRATULATIONS!\n\nThanks for trusting in our service, it's been an absolute pleasure securing your high performing investment property.\n\nPlease keep an eye out for the delivery man, there should be something arriving at your door soon, if it hasn't arrived yet.\n\nFrom here on, you will receive equity updates in your portfolio section. This will give you some understanding to the market’s growth in the area, giving you somewhat of an understanding of the properties potential growth.\n\nFrom the team at The Investors Agency, thank you for choosing our service, we can't wait to have you back for the next property\n\nOh, one last thing. Your feedback is very important to us! if you would like to leave a review, we would really appreciate that. You can do so on the links below.\n\nGoogle Review\n\nFacebook Review`
    },
    {
      title: "Settlement",
      videos: [],
      links: null,
      files: [],
      zohoTitle: "Settled",
      description:
        "Every third month you will receive a quarterly update notifying you of the market position and your potential equity growth.",
      update: null, //`Right, from here on, will be sending you updates every quarter until you tell us to stop. We do this because we want to show you what your property is doing in terms of the growth of the equity so that we can use that to go into the next purchase and grow your portfolio as quickly and as affordably as possible. If you have any questions about your property, management, investment structuring or any other related topics, please don't hesitate to reach out, we're just a phone call or email away.\n\nThanks for being awesome, we've all enjoyed working with you throughout the period and look forward to doing it all again when you're ready.\n\nSincerely, The Investors Agency`,
      message: `Hi ${deal.Client_App_Email}, CONGRATULATIONS!\n\nThanks for trusting in our service, it's been an absolute pleasure securing your high performing investment property.\n\nPlease keep an eye out for the delivery man, there should be something arriving at your door soon, if it hasn't arrived yet.\n\nFrom here on, you will receive equity updates in your portfolio section. This will give you some understanding to the market’s growth in the area, giving you somewhat of an understanding of the properties potential growth.\n\nFrom the team at The Investors Agency, thank you for choosing our service, we can't wait to have you back for the next property\n\nOh, one last thing. Your feedback is very important to us! if you would like to leave a review, we would really appreciate that. You can do so on the links below.\n\nGoogle Review\n\nFacebook Review`, //`Hi ${deal.Client_App_Email}, please find your market report attached and adjusted equity within your portfolio`
    },
  ];

  return tasks;
};

export const getTime = (time) => {
  return new Date(time).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  }); //.substring(0,5)
};

export const walkThrough = (property) => {
  return [
    {
      title: "Property Condition Notes",
      description: "",
      explanation: property["Property Condition Notes"],
    },
  ];
};

export default tasks;
