import React from "react";
import { Image, StyleSheet, Text, TextInput, View } from "react-native";
import { MAIN_COLORS } from "../Utility/Colors";
import { FONT_SIZE, FONT_WEIGHT, MAIN_FONT } from "../Utility/Fonts/font";

const Input = ({
  label,
  required,
  editable,
  prefixIcon,
  suffixIcon,
  styleLabel,
  styleInput,
  styleSuffix,
  stylePrefix,
  styleWrapInput,
  styleContainer,
  stylePrefixIcon,
  styleSuffixIcon,
  size = 46,
  borderColor,
  ...props
}) => {
  const height = size || 46;

  return (
    <View style={[styles.container, styleContainer]}>
      {label && (
        <Text style={[styles.label, styleLabel]}>
          {label} {required && <Text style={{ color: "#FD4438" }}>*</Text>}
        </Text>
      )}
      <View style={[styles.inputWrap, styleWrapInput]}>
        {prefixIcon && (
          <View style={[styles.prefix, stylePrefix]}>
            <Image
              resizeMode="contain"
              style={[
                { height: 20, width: 20, margin: "auto" },
                stylePrefixIcon,
              ]}
              source={prefixIcon}
            />
          </View>
        )}
        <TextInput
          style={[
            styles.input,
            prefixIcon && { marginLeft: 32 },
            { height },
            suffixIcon && { paddingRight: 36, zIndex: 1 },
            styleInput,
          ]}
          editable={editable}
          {...props}
        />
        {suffixIcon && (
          <View style={[styles.suffix, styleSuffix]}>
            <Image
              resizeMode="contain"
              style={[
                {
                  height: 20,
                  width: 20,
                  margin: "auto",
                },
                styleSuffixIcon,
              ]}
              source={suffixIcon}
            />
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    padding: 0,
    flex: 1,
    maxHeight: 76,
  },
  label: {
    fontWeight: FONT_WEIGHT.medium,
    fontSize: FONT_SIZE.medium,
    fontFamily: MAIN_FONT.regular,
    lineHeight: 20,
    color: MAIN_COLORS.TEXT_LIGHT,
  },
  inputWrap: {
    overflow: "hidden",
    borderWidth: 1,
    borderColor: "rgba(173, 185, 199, 0.3)",
    borderRadius: 5,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  prefix: {
    position: "absolute",
    backgroundColor: "#7D888F1A",
    height: "100%",
    width: 32,
    borderRightWidth: 1,
    borderRightColor: "rgba(173, 185, 199, 0.3)",
  },
  suffix: {
    position: "absolute",
    right: 14,
  },
  input: {
    paddingVertical: 8,
    paddingHorizontal: 14,
    fontSize: FONT_SIZE.large,
    fontWeight: FONT_WEIGHT.medium,
    fontFamily: MAIN_FONT.regular,
    backgroundColor: "transparent",
    width: "100%",
    color: MAIN_COLORS.TEXT_LIGHT,
    outlineStyle: "none",
  },
});

export default Input;
