import {
  View,
  Text,
  Image,
  Linking,
  Keyboard,
  Platform,
  TextInput,
  Dimensions,
  BackHandler,
  SafeAreaView,
  TouchableOpacity,
} from "react-native";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showMessage } from "react-native-flash-message";
import { RFValue } from "react-native-responsive-fontsize";
import { MaterialIndicator } from "react-native-indicators";
import AsyncStorage from "@react-native-async-storage/async-storage";

//Function
import styles from "../Auth/style";
import { MAIN_COLORS } from "../../Utility/Colors";
import { signUpAccount } from "../../Clickup/api";
import { reset, setClient } from "../../Reducer/tia";

//Component
import AuthBanner from "../Auth/AuthBanner";
import LOADER from "../../Utility/ComponentCard/Loader";
import stylesCommonComponents from "../../Utility/ComponentStyle/style";
import { MAIN_FONT } from "../../Utility/Fonts/font";

class RegisterScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      firstName: "",
      lastName: "",
      isLoading: false,
      signing: false,
      popupTypeId: 0,
      responseToken: null,
      showPassword: false,
      isFocus: null,
      token: { data: "" },
      window: Dimensions.get("window"),
      isPC: Dimensions.get("window").width > 1024 ? true : false,
      isSmall: Dimensions.get("window").height < 900 ? true : false,
      signing: false,
      type: props.navigation.state.params?.type || "",
    };
  }

  _onBlurr = () => {
    BackHandler.removeEventListener(
      "hardwareBackPress",
      this._handleBackButtonClick
    );
  };

  _onFocus = () => {
    BackHandler.addEventListener(
      "hardwareBackPress",
      this._handleBackButtonClick
    );
  };

  _handleBackButtonClick = () => {
    BackHandler.exitApp();
    return true;
  };

  componentDidMount() {
    Dimensions.addEventListener("change", ({ window }) => {
      this.setState({
        isPC: window.width > 1000 ? true : false,
        isSmall: window.height < 900 ? true : false,
      });
    });

    if (this.state.type) {
      AsyncStorage.setItem("type", this.state.type)
        .then(() => console.log("Type saved to AsyncStorage"))
        .catch((error) =>
          console.error("Failed to save type to AsyncStorage:", error)
        );
    }

    AsyncStorage.getItem("formData").then((formData) => {
      if (formData) {
        const { email } = JSON.parse(formData);
        this.setState({
          email: email || "",
        });
      }
    });

    this.props.reset({});

    Linking.getInitialURL()
      .then((url) => {
        if (
          url.includes(
            "https://theinvestorsagency.com.au/client_portal/?resetPassword?email="
          )
        ) {
          url = url.replace(
            "https://theinvestorsagency.com.au/client_portal/?resetPassword?email=",
            ""
          );
          this.props.navigation.navigate("ResetPasswordScreen", { email: url });
        }
      })
      .catch(console.warn);

    const search = window.location.search;
    const params = new URLSearchParams(search);
    if (window.location.pathname === "/verify-email" && params.get("email"))
      this.props.navigation.navigate("SignUpScreen");
  }

  onPressSignup = async () => {
    const { email } = this.state;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email.trim()) {
      showMessage({
        message: "Email can't be empty.",
        type: "info",
        color: "#FFF",
        backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
        duration: 4000,
      });
      return;
    }

    if (!emailRegex.test(email)) {
      showMessage({
        message: "Invalid email format.",
        type: "info",
        color: "#FFF",
        backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
        duration: 4000,
      });
      return;
    }

    try {
      this.setState({ isLoading: true });

      const formData = { email };

      const result = await signUpAccount(formData);

      if (result.status === 201) {
        showMessage({
          message: result.message || `OTP has been sent to ${email}`,
          type: "info",
          color: "#FFF",
          backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
          duration: 4000,
        });

        await AsyncStorage.setItem("formData", JSON.stringify(formData));

        this.props.navigation.navigate("OTPScreen", { type: "create" });
      } else if (result.email) {
        showMessage({
          message: "This is email has been registered. Please try another email." || result?.exception?.detail,
          type: "info",
          color: "#FFF",
          backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
          duration: 4000,
        });
      } else {
        showMessage({
          message: "This is email has been registered. Please try another email." || result?.exception?.detail,
          type: "info",
          color: "#FFF",
          backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
          duration: 4000,
        });
      }
    } catch (error) {
      console.error("Error:", error);

      showMessage({
        message: "An error occurred, please try again.",
        type: "info",
        color: "#FFF",
        backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
        duration: 4000,
      });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { isPC, isFocus, signing, responseToken } = this.state;
    return (
      <SafeAreaView
        style={styles.container}
        keyboardShouldPersistTaps={true}
        behavior={Platform.OS === "ios" ? "padding" : "padding"}
        keyboardVerticalOffset={Platform.OS === "ios" ? -200 : 900}>
        <AuthBanner />
        <View
          onPress={Keyboard.dismiss}
          accessible={false}
          style={{
            width: isPC ? "50%" : "100%",
            backgroundColor: MAIN_COLORS.BACKGROUND_BLACK,
          }}>
          <View style={styles.boxLogoTIA}>
            <TouchableOpacity
              onPress={() =>
                this.props.navigation.goBack()
              }>
              <Image
                source={require("../../assets/home/back.svg")}
                style={styles.backIcon}
              />
            </TouchableOpacity>
          </View>
          <View style={[styles.scrollViewContentView, styles.sectionContent]}>
            <View>
              <Text style={styles.sectionTitle}>Create an Account</Text>
              <Text style={styles.sectionSubTitle}>
                Create an account and enjoy our services.
              </Text>
            </View>

            <View style={styles.sectionCenterView}>
              <View style={styles.sectionForm}>
                <Text style={styles.labelForm}>Email</Text>
                <div id="input_email">
                  <TextInput
                    ref={(input) => (this.emailTextInput = input)}
                    keyboardType="web-search"
                    autoCorrect={false}
                    style={[
                      styles.inputForm,
                      { borderColor: isFocus === "email" ? "#fff" : "#34393D" },
                    ]}
                    placeholderTextColor="#ADB9C7"
                    value={this.state.email}
                    underlineColorAndroid="transparent"
                    placeholder="Enter Your Email"
                    returnKeyType={"next"}
                    blurOnSubmit={false}
                    autoCapitalize="none"
                    onSubmitEditing={this.onPressSignup}
                    onFocus={() => this.setState({ isFocus: "email" })}
                    onBlur={() => this.setState({ isFocus: null })}
                    onChangeText={(email) => {
                      !signing &&
                        this.setState({ email, emailRequired: false });
                    }}
                  />
                </div>
              </View>
            </View>

            <View>
              {/* TouchableOpacity Sign Up */}
              <div id="btn_sign_up">
                <TouchableOpacity
                  style={[stylesCommonComponents.button, styles.btnSubmit]}
                  onPress={() => this.onPressSignup()}>
                  {signing && responseToken ? (
                    <MaterialIndicator
                      size={RFValue(16.75)}
                      color={MAIN_COLORS.BACKGROUND_WHITE}
                    />
                  ) : (
                    <Text style={styles.textButton}>Create account</Text>
                  )}
                </TouchableOpacity>
              </div>
            </View>

            {/* Signin */}
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                alignSelf: "center",
              }}>
              <Text style={styles.sectionSubTitle}>
                Already have an account?{" "}
              </Text>
              <TouchableOpacity
                onPress={() => this.props.navigation.navigate("LoginScreen")}>
                <Text
                  style={{
                    fontSize: 14,
                    fontFamily: MAIN_FONT.regular,
                    fontWeight: "400",
                    color: MAIN_COLORS.PRIMARY_COLOR,
                  }}>
                  Sign In
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
        {this.state.isLoading ? <LOADER /> : null}
      </SafeAreaView>
    );
  }
}

function mapStateToProps(state) {
  return {
    COLORS: state.COLORS,
    USER: state.USER,
    CART: state.CART,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setClient,
      reset,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterScreen);
