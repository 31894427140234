import React, { memo, useState, useEffect } from "react";
import { View, Text, ScrollView } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import "chart.js/auto";
import "chartjs-adapter-moment";
import { Radar } from "react-chartjs-2";
import { TouchableOpacity, ActivityIndicator } from "react-native";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import RenderHtml from "react-native-render-html";

//Functions
import { styles } from "../index.v2";
import { MAIN_COLORS } from "../../../Utility/Colors";
import { FONT_SIZE, MAIN_FONT } from "../../../Utility/Fonts/font";
import {
  externalTooltipHandlerNew,
  formatDollar,
  formatUnit,
} from "../../../Utility/common";
import {
  marketSurbStatistics,
  marketInvestment,
  getReportClause,
} from "../../../Clickup/api";

import Loader from "../../../Utility/ComponentCard/Loader";
import ViewGallery from "../../../components/ViewGallery";

ChartJS.register(ArcElement, Tooltip, Legend);

const SuburbInsight = ({ body_market, deal }) => {
  const MAX_HEIGHT = 250;
  const [textHeight, setTextHeight] = useState(0);
  const [isExpandedDetail, setIsExpandedDetail] = useState(false);
  const [clauseActive, setClauseActive] = useState("building");
  const [suburbStatistics, setSuburbStatistics] = useState(null);
  const [dataRadar, setDataRadar] = useState([]);
  const [reportClause, setReportClause] = useState([]);
  const [loadingInvestment, setLoadingInvestment] = useState(false);
  const [loadingStatistic, setLoadingStatistic] = useState(false);
  const [loadingReportClause, setLoadingReportClause] = useState(false);

  const buildingClauseDes = reportClause.find(
    (task) => task.name === "Building & Pest Clause"
  )?.description;

  const financeClauseDes = reportClause.find(
    (task) => task.name === "Finance Clause"
  )?.description;

  const radarData = {
    labels: [
      "Affordability",
      ["Rental", "Demand"],
      ["Rental", "Yield"],
      ["Purchase", "Demand"],
      ["Rent Growth", "Potential"],
    ],
    datasets: [
      {
        data: dataRadar,
        backgroundColor: (context) => {
          const bgColor = [
            "rgba(250, 191, 1, 0.3)",
            "rgba(250, 191, 1, 0.15)",
            "rgba(250, 191, 1, 0.05)",
          ];
          if (!context.chart.chartArea) return;
          const {
            ctx,
            chartArea: { top, bottom },
          } = context.chart;
          const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
          gradientBg.addColorStop(0.1, bgColor[0]);
          gradientBg.addColorStop(0.5, bgColor[1]);
          gradientBg.addColorStop(1, bgColor[2]);
          return gradientBg;
        },
        borderColor: MAIN_COLORS.PRIMARY_COLOR,
        pointBorderWidth: 5.5,
        pointBackgroundColor: "#D3B100",
        pointRadius: ({ index }) => 0,
        pointHoverRadius: 0,
        borderWidth: 1,
      },
    ],
  };

  const max = Math.max(...dataRadar);

  const radarOptions = {
    hover: { mode: "index", intersect: false },
    tooltips: { mode: "index", intersect: false },
    aspectRatio: 5 / 4,
    scales: {
      r: {
        border: {
          dash: (context) => {
            if (context.index === 6) return [0, 0]; // context.index === 5 --
            return [3, 3];
          },
          color: "#34393D",
        },
        beginAtZero: false,
        ticks: {
          display: false,
          // stepSize: max / 10, // --
          // maxTicksLimit: 10, // --
          count: 7, //to do
        },
        grid: { color: "#34393D" },
        pointLabels: {
          font: { size: FONT_SIZE.small, weight: 500 },
          color: MAIN_COLORS.G600,
        },
        max: max,
        min: -100 * (100 / 12 - 8) + 12, //to do
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        display: false,
        enabled: false,
      },
    },
  };

  useEffect(() => {
    const fetSuburbStatistic = async () => {
      try {
        setLoadingStatistic(true);
        const response = await marketSurbStatistics(body_market);
        if (response.status === 200) setSuburbStatistics(response.data);
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoadingStatistic(false);
      }
    };
    const fetInvestment = async () => {
      try {
        setLoadingInvestment(true);
        const response = await marketInvestment(body_market);

        if (response.status === 200)
          setDataRadar(Object.values(response.data).map((val) => val ?? 0));
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoadingInvestment(false);
      }
    };
    const fetchReportClause = async () => {
      try {
        setLoadingReportClause(true);
        const response = await getReportClause();
        const filteredIds = Array.isArray(response?.tasks)
          ? response.tasks
              .filter((item) => item.name === body_market?.state)
              .map((item) => item.id)
          : [];
        const tasks = Array.isArray(response?.tasks)
          ? response.tasks.filter((item) => filteredIds.includes(item.parent))
          : [];

        setReportClause(tasks);
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoadingReportClause(false);
      }
    };
    fetSuburbStatistic();
    fetInvestment();
    fetchReportClause();
  }, []);

  return (
    <View style={{ flexDirection: "row", gap: 16 }}>
      <View style={{ flex: 2 }}>
        <Text style={styles.subTitle}>Suburbs insights</Text>
        <View style={{ flexDirection: "row", gap: 16, flex: 1 }}>
          <View
            style={[
              styles.boxSection,
              { display: "flex", gap: 16, justifyContent: "space-between" },
            ]}
          >
            <Text style={[styles.subTitle, { marginBottom: 0 }]}>
              Suburb Statistics
            </Text>
            <View style={styles.rowCenterBetween}>
              <Text style={styles.textSection}>Vacancy rate</Text>
              <Text style={styles.valueSection}>
                {loadingStatistic ? (
                  <ActivityIndicator color={MAIN_COLORS.PRIMARY_COLOR} />
                ) : (
                  `${(
                    Number(suburbStatistics?.rental_vacancy_rate ?? 0) * 100
                  )?.toFixed(2)}%`
                )}
              </Text>
            </View>
            <View style={styles.rowCenterBetween}>
              <Text style={styles.textSection}>Population in 2021</Text>
              <Text style={styles.valueSection}>
                {loadingStatistic ? (
                  <ActivityIndicator color={MAIN_COLORS.PRIMARY_COLOR} />
                ) : (
                  formatUnit(suburbStatistics?.population_2021?.toFixed(0) ?? 0)
                )}
              </Text>
            </View>
            <View style={styles.rowCenterBetween}>
              <Text style={styles.textSection}>Population today</Text>
              <Text style={styles.valueSection}>
                {loadingStatistic ? (
                  <ActivityIndicator color={MAIN_COLORS.PRIMARY_COLOR} />
                ) : (
                  formatUnit(
                    suburbStatistics?.population_today?.toFixed(0) ?? 0
                  )
                )}
              </Text>
            </View>
            <View style={styles.rowCenterBetween}>
              <Text style={styles.textSection}>No. of houses</Text>
              <Text style={styles.valueSection}>
                {loadingStatistic ? (
                  <ActivityIndicator color={MAIN_COLORS.PRIMARY_COLOR} />
                ) : (
                  `${(
                    Number(suburbStatistics?.no_of_houses ?? 0) * 100
                  ).toFixed(2)}%`
                )}
              </Text>
            </View>
            <View style={styles.rowCenterBetween}>
              <Text style={styles.textSection}>No. of units</Text>
              <Text style={styles.valueSection}>
                {loadingStatistic ? (
                  <ActivityIndicator color={MAIN_COLORS.PRIMARY_COLOR} />
                ) : (
                  `${(Number(suburbStatistics?.no_of_units ?? 0) * 100).toFixed(
                    2
                  )}%`
                )}
              </Text>
            </View>
            <View style={styles.rowCenterBetween}>
              <Text style={styles.textSection}>Personal income</Text>
              <Text style={styles.valueSection}>
                {loadingStatistic ? (
                  <ActivityIndicator color={MAIN_COLORS.PRIMARY_COLOR} />
                ) : (
                  `${formatDollar(
                    suburbStatistics?.personal_income?.toFixed(0) ?? 0
                  )} p/w`
                )}
              </Text>
            </View>
            <View style={styles.rowCenterBetween}>
              <Text style={styles.textSection}>Household income</Text>
              <Text style={styles.valueSection}>
                {loadingStatistic ? (
                  <ActivityIndicator color={MAIN_COLORS.PRIMARY_COLOR} />
                ) : (
                  `${formatDollar(
                    suburbStatistics?.household_income?.toFixed(0) ?? 0
                  )} p/w`
                )}
              </Text>
            </View>
          </View>
          <View style={styles.boxSection}>
            <Text style={styles.subTitle}>Investment Profile</Text>
            <View
              style={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                overflow: "hidden",
              }}
            >
              {!loadingInvestment ? (
                dataRadar.length > 0 ? (
                  <Radar
                    data={radarData}
                    options={radarOptions}
                    style={{ width: "100%", height: "100%" }}
                  />
                ) : null
              ) : (
                <Loader />
              )}
            </View>
          </View>
        </View>
      </View>

      <View style={{ flex: 1 }}>
        <Text style={styles.subTitle}>
          Building & Pest Clause and Finance Clause
        </Text>
        <View style={styles.boxSection}>
          <View style={{ flexDirection: "row", gap: 8, marginBottom: 12 }}>
            <TouchableOpacity
              style={[
                styles.btnClause,
                clauseActive === "building" && styles.btnClauseAvtive,
              ]}
              onPress={() => setClauseActive("building")}
            >
              <Text
                style={[
                  styles.textSection,
                  {
                    color:
                      clauseActive === "building"
                        ? MAIN_COLORS.PRIMARY_COLOR
                        : MAIN_COLORS.G600,
                  },
                ]}
              >
                Building & Pest Clause
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[
                styles.btnClause,
                clauseActive === "pest" && styles.btnClauseAvtive,
              ]}
              onPress={() => setClauseActive("pest")}
            >
              <Text
                style={[
                  styles.textSection,
                  {
                    color:
                      clauseActive === "pest"
                        ? MAIN_COLORS.PRIMARY_COLOR
                        : MAIN_COLORS.G600,
                  },
                ]}
              >
                Finance Clause
              </Text>
            </TouchableOpacity>
          </View>
          <View
            style={{ gap: 8 }}
            onLayout={(event) => {
              const { height } = event.nativeEvent.layout;
              setTextHeight(height);
            }}
          >
            {clauseActive === "building" ? (
              <>
                {buildingClauseDes ? (
                  <>
                    <Text style={styles.subTitleSection}>
                      Building & Pest Clause
                    </Text>
                    <div className="renderHtml">
                      <RenderHtml
                        source={{ html: buildingClauseDes }}
                        baseStyle={{
                          fontWeight: "400",
                          fontSize: FONT_SIZE.medium,
                          fontFamily: MAIN_FONT.regular,
                          font: MAIN_FONT.regular,
                          lineHeight: 21,
                          color: MAIN_COLORS.G600,
                          textDecorationLine: "none",
                        }}
                        tagsStyles={{
                          div: {
                            fontFamily: MAIN_FONT.regular,
                            font: MAIN_FONT.regular,
                          },
                          p: {
                            marginVertical: 0,
                            paddingVertical: 0,
                            fontFamily: MAIN_FONT.regular,
                          },
                          b: {
                            fontFamily: MAIN_FONT.regular,
                          },
                          ul: {
                            marginVertical: 0,
                            paddingVertical: 0,
                          },
                          ol: {
                            marginVertical: 0,
                            paddingVertical: 0,
                          },
                          li: {
                            marginVertical: 0,
                            paddingVertical: 0,
                            marginBottom: 5,
                          },
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <Loader height={195} />
                )}
              </>
            ) : (
              <>
                {financeClauseDes ? (
                  <>
                    <Text style={styles.subTitleSection}>Finance Clause</Text>
                    <div className="renderHtml">
                      <RenderHtml
                        source={{ html: financeClauseDes }}
                        baseStyle={{
                          fontWeight: "400",
                          fontSize: FONT_SIZE.medium,
                          fontFamily: MAIN_FONT.regular,
                          lineHeight: 21,
                          color: MAIN_COLORS.G600,
                          textDecorationLine: "none",
                        }}
                        tagsStyles={{
                          div: {
                            fontFamily: MAIN_FONT.regular,
                            font: MAIN_FONT.regular,
                          },
                          p: {
                            marginVertical: 0,
                            paddingVertical: 0,
                            fontFamily: MAIN_FONT.regular,
                          },
                          b: {
                            fontFamily: MAIN_FONT.regular,
                          },
                          ul: {
                            marginVertical: 0,
                            paddingVertical: 0,
                          },
                          ol: {
                            marginVertical: 0,
                            paddingVertical: 0,
                          },
                          li: {
                            marginVertical: 0,
                            paddingVertical: 0,
                            marginBottom: 5,
                          },
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <Loader height={195} />
                )}
              </>
            )}
          </View>
          {textHeight > MAX_HEIGHT && (
            <View style={styles.footer}>
              <LinearGradient
                colors={["rgba(18, 15, 14, 0)", "#000000"]}
                locations={[0.13, 0.745]}
                style={{
                  width: "100%",
                  flex: 1,
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              ></LinearGradient>
            </View>
          )}
          {textHeight > MAX_HEIGHT && (
            <TouchableOpacity
              activeOpacity={1}
              onPress={() => setIsExpandedDetail(true)}
              style={styles.seeMoreContainer}
            >
              <Text style={styles.seeMoreText}>See more</Text>
            </TouchableOpacity>
          )}
        </View>
      </View>
      <ViewGallery
        open={isExpandedDetail}
        styleBox={styles.modelDetail}
        onClose={() => setIsExpandedDetail(false)}
      >
        <View style={{ gap: 8 }}>
          {clauseActive === "building" ? (
            <>
              {buildingClauseDes ? (
                <>
                  <Text style={styles.subTitleSection}>
                    Building & Pest Clause
                  </Text>
                  <div className="renderHtml">
                    <RenderHtml
                      source={{ html: buildingClauseDes }}
                      baseStyle={{
                        fontWeight: "400",
                        fontSize: FONT_SIZE.medium,
                        fontFamily: MAIN_FONT.regular,
                        font: MAIN_FONT.regular,
                        lineHeight: 21,
                        color: MAIN_COLORS.G600,
                        textDecorationLine: "none",
                      }}
                      tagsStyles={{
                        div: {
                          fontFamily: MAIN_FONT.regular,
                          font: MAIN_FONT.regular,
                        },
                        p: {
                          marginVertical: 0,
                          paddingVertical: 0,
                          fontFamily: MAIN_FONT.regular,
                        },
                        b: {
                          fontFamily: MAIN_FONT.regular,
                        },
                        ul: {
                          marginVertical: 0,
                          paddingVertical: 0,
                        },
                        ol: {
                          marginVertical: 0,
                          paddingVertical: 0,
                        },
                        li: {
                          marginVertical: 0,
                          paddingVertical: 0,
                          marginBottom: 5,
                        },
                      }}
                    />
                  </div>
                </>
              ) : (
                <Loader height={195} />
              )}
            </>
          ) : (
            <>
              {financeClauseDes ? (
                <>
                  <Text style={styles.subTitleSection}>Finance Clause</Text>
                  <div className="renderHtml">
                    <RenderHtml
                      source={{ html: financeClauseDes }}
                      baseStyle={{
                        fontWeight: "400",
                        fontSize: FONT_SIZE.medium,
                        fontFamily: MAIN_FONT.regular,
                        font: MAIN_FONT.regular,
                        lineHeight: 21,
                        color: MAIN_COLORS.G600,
                        textDecorationLine: "none",
                      }}
                      tagsStyles={{
                        div: {
                          fontFamily: MAIN_FONT.regular,
                          font: MAIN_FONT.regular,
                        },
                        p: {
                          marginVertical: 0,
                          paddingVertical: 0,
                          fontFamily: MAIN_FONT.regular,
                        },
                        b: {
                          fontFamily: MAIN_FONT.regular,
                        },
                        ul: {
                          marginVertical: 0,
                          paddingVertical: 0,
                        },
                        ol: {
                          marginVertical: 0,
                          paddingVertical: 0,
                        },
                        li: {
                          marginVertical: 0,
                          paddingVertical: 0,
                          marginBottom: 5,
                        },
                      }}
                    />
                  </div>
                </>
              ) : (
                <Loader height={195} />
              )}
            </>
          )}
        </View>
      </ViewGallery>
    </View>
  );
};

export default memo(SuburbInsight);
