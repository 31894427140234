import {
  View,
  Text,
  TouchableOpacity,
  Image,
  StyleSheet,
  Linking,
} from "react-native";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { FONT_SIZE, FONT_WEIGHT, MAIN_FONT } from "../../../Utility/Fonts/font";
import { MAIN_COLORS } from "../../../Utility/Colors";

import defaultImage from "../../../assets/home/Darren&Mike.png";
import { getContactByEmail } from "../../../Clickup/api";
import Fields from "../../../Utility/Fields";

const BookACall = ({ task, booking, assignee }) => {
  const { teamTIAData } = useSelector((state) => state.TIA);
  const [buyingTeamPhoto, setBuyingTeamPhoto] = useState(null);
  const [exchangeManagerName, setExchangeManagerName] = useState(null);
  const assigneeUsername = assignee?.username;
  const isInTeamTIA = teamTIAData?.some(
    (member) =>
      member.name === assigneeUsername && member.type === "Property Analyst"
  );

  const openUrl = (url) => {
    const reg =
      /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[‌​\+~%\/\.\w-_]*)?\??(?:[-\+=&;%@\.\w_]*)#?(?:[\w]*))?)/;

    if (reg.test(url)) {
      Linking.openURL(url);
    }
  };

  useEffect(() => {
    if (task[Fields.appStageName] === "Building & Pest Report Results") {
      const exchangeManager = teamTIAData?.find(
        (member) => member.type === "Exchange Manager"
      );
      if (exchangeManager) {
        setBuyingTeamPhoto(exchangeManager.contact_photo);
        setExchangeManagerName(exchangeManager.name);
        return;
      }
    }

    if (assignee) {
      const isAssigneeValid = teamTIAData?.some(
        (member) =>
          member.name === assignee.username &&
          member.type === "Property Analyst"
      );

      if (!isAssigneeValid) {
        setBuyingTeamPhoto(null);
      } else {
        getContactByEmail(assignee.email).then((result) => {
          const tasks = result?.tasks;
          if (tasks.length) {
            const custom_fields = tasks[0].custom_fields;
            const contactPhoto = custom_fields.find(
              (field) => field.name === "Buying Team Photo"
            )?.value[0].url;
            setBuyingTeamPhoto(contactPhoto);
          }
        });
      }
    }
  }, [task, assignee, teamTIAData]);

  return (
    <View style={styles.footer}>
      <View
        style={{
          alignSelf: "center",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          maxWidth: 444,
        }}>
        <Text style={styles.footerTitle}>
          {task[Fields.appStageName] === "Building & Pest Report Results" &&
          exchangeManagerName
            ? `Discuss with ${exchangeManagerName}`
            : `Discuss with ${isInTeamTIA ? `${assigneeUsername} ` : "Mike"}`}
        </Text>
        <Text style={styles.footerDescription}>
          Once you’ve reviewed the info above, find a time to chat with your
          property analyst.
        </Text>
        <TouchableOpacity
          style={styles.btnBooking}
          onPress={() => openUrl(booking)}>
          <Text style={styles.textBtnBooking}>Book a Quick Call</Text>
        </TouchableOpacity>
      </View>
      <Image
        style={[
          styles.footerImage,
          task[Fields.appStageName] === "Building & Pest Report Results" && {
            aspectRatio: 1 / 1,
            maxHeight: 250,
          },
        ]}
        source={buyingTeamPhoto ? { uri: buyingTeamPhoto } : defaultImage}
      />
      <Image
        style={styles.calendarImage}
        source={require("../../../assets/calendarBooking.png")}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  footer: {
    position: "relative",
    backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
    borderRadius: 10,
    height: "auto",
    paddingVertical: 26,
    marginTop: 24,
  },

  footerTitle: {
    fontSize: 34,
    fontWeight: FONT_WEIGHT.bold,
    fontFamily: MAIN_FONT.semi,
    lineHeight: 51,
    color: MAIN_COLORS.TEXT_LIGHT,
    textAlign: "center",
  },

  footerDescription: {
    fontSize: FONT_SIZE.large,
    fontWeight: FONT_WEIGHT.medium,
    fontFamily: MAIN_FONT.regular,
    lineHeight: 24,
    color: MAIN_COLORS.TEXT_LIGHT,
    textAlign: "center",
  },

  btnBooking: {
    backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 6,
    height: 38,
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },

  footerImage: {
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "32%",
    maxHeight: 196,
    aspectRatio: 2 / 1,
    resizeMode: "contain",
  },

  calendarImage: {
    position: "absolute",
    right: 22,
    bottom: 0,
    width: 316,
    height: 192,
  },

  textBtnBooking: {
    fontWeight: FONT_WEIGHT.bold,
    lineHeight: 21,
    fontSize: FONT_SIZE.medium,
    color: "#19232B",
    fontFamily: MAIN_FONT.semi,
  },
});

export default BookACall;
